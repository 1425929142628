import {useState, useCallback, default as React} from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Trans } from 'react-i18next';
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from '../../app/hooks';
import { selectFeedback } from './leatherSlice';
import { AddForm } from './components/AddForm';
import { createLeather } from './actions';
import { Feedback } from '../../components/Feedback';

interface Props {
  backToUrl?: string,
}

// export const AddLeather: React.FC<Props> = ({ backToUrl }: Props) => {
export const AddLeather: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const feedback = useSelector(selectFeedback);

  const [localLeather, setLocalLeather] = useState<PendingLeather>({
    code: '',
    article: '',
    color: '',
  });

  const onChange = useCallback(({ target }) => {
    setLocalLeather({...localLeather, [target.name]: target.value });
  }, [localLeather]);

  const onSubmit = useCallback(() => {
    if (!localLeather) { return false; }
    dispatch(createLeather({ leather: localLeather }))
    .then(unwrapResult)
    .then(() => {
      history('/leathers');
    })
    .catch((err) => {
      console.error(err);
    });
  }, [dispatch, localLeather, history]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to="/leathers" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">
              <Trans i18nKey="add leather" />
            </span>
          </div>
        </div>
      </div>
      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
          <AddForm
            leather={localLeather}
            onChange={onChange}
            onSubmit={onSubmit}
          />
          <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default AddLeather;
