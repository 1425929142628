import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButtonInput } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
}

const values:ValueItem[] = ([
  {
    value: 'Normaal',
  },
  {
    value: 'Bestaande contrefort verwerken',
  },
  {
    value: 'Maken als op leest dik',
    textEntry: true,
  },
  {
    value: 'Maken als op leest dun',
    textEntry: true,
  },
  {
    value: 'Dik med lat',
    textEntry: true,
  },
  {
    value: 'Dik med',
    textEntry: true,
  },
  {
    value: 'Dik lat',
    textEntry: true,
  },
  {
    value: 'Dun med lat',
    textEntry: true,
  },
  {
    value: 'Dun med',
    textEntry: true,
  },
  {
    value: 'Dun lat',
    textEntry: true,
  },
]);

const sectionName = 'Contrefort';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionD: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid grid-cols-3 mt-4 font-bold  print:mt-0">
        <span></span>
        <span>{t('Links')}</span>
        <span>{t('Rechts')}</span>
      </div>
      <div className="mt-6 print:mt-0">
        {/** INPUT */}
        { values.map((c, idx) => (
          <div className={`grid grid-cols-3 mt-4 o-selection ${properties['contrefort-links'] === c.value || properties['contrefort-rechts'] === c.value ? 'o-selection--checked' : '  '}`} key={c.value} >
            <div>
              {t(c.value)}
            </div>
            <RadioButtonInput
              name="contrefort-links"
              checked={properties['contrefort-links'] === c.value}
              onChange={onChange}
              value={c.value}
              properties={properties}
              textEntry={c.textEntry ? 'contrefort-links-cm' : false}
              suffix="cm"
              id={`${sectionId}-${idx}-links`}
            />
            <RadioButtonInput
              name="contrefort-rechts"
              checked={properties['contrefort-rechts'] === c.value}
              onChange={onChange}
              value={c.value}
              properties={properties}
              textEntry={c.textEntry ? 'contrefort-rechts-cm' : false}
              suffix="cm"
              id={`${sectionId}-${idx}-rechts`}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionD;
