import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { selectFeedback, selectProduct, displayName } from './productsSlice';
import { Feedback } from '../../components/Feedback';
import { removeProduct, getProduct } from './actions';
import { Button } from '../../components/Button';

export const RemoveProduct: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const feedback = useSelector(selectFeedback);
  const product = useSelector(selectProduct);

  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getProduct(id));
  }, [dispatch, id]);

  return (
    <>
      <div>
        <Link to="/products">{t('back')}</Link>
        <h2>Product {displayName(product)} verwijderen?</h2>
      </div>
      <div>
        <Link to="/products">Terug</Link>
        { typeof id === 'string' && <Button
        onClick={() => {dispatch(removeProduct(id))}}
        className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
          Verwijderen
        </Button>}
        <Feedback feedback={feedback} />
      </div>
    </>
  )
};

export default RemoveProduct;
