import * as React from 'react';

export interface ProjectContext {
  baseUrl?: string;
  selectedProduct?: Product;
}

export const ProjectCtx = React.createContext<ProjectContext>({});
export const initialProjectContext: ProjectContext = {};

