import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Button } from '../../../components/Button';

export interface FormProps {
  leather: PendingLeather,
  onChange: React.FormEventHandler,
  onSubmit: Function,
};


export const AddForm: React.FC<FormProps> = (props:FormProps) => {
  const { leather, onChange } = props;

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit();
  }

  return (
    <div className={"min-w-min mx-auto bg-white py-8 px-12"}>
      {/* FORM */}
      <h2>Gegevens van het leder</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="mr-2">
            <Trans i18nKey="m-code" />
          </label>
          <input
            name="code"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={leather.code}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">
            <Trans i18nKey="article" />
            </label>
          <input
            name="article"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={leather.article}
            required={true}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">
            <Trans i18nKey="color" />
          </label>
          <input
            name="color"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={leather.color}
            required={true}
          />
        </div>
        <Link to="/leathers">
          <Trans i18nKey="back" />
        </Link>
        <Button className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
          <Trans i18nKey="submit" />
        </Button>
      </form>
    </div>
  )
};

export default AddForm;
