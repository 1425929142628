import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from "react-router-dom";
import uniq from "lodash/uniq";
import { searchProject, sendToSupplier } from './actions';
import { Row, Checkbox } from './components/Row';
import { SupplierRow } from './components/SupplierRow';
import {
  selectProjects,
  selectPaging,
  selectSearchFeedback,
  defaultSearchOptions,
} from './projectSlice';
import { Pagination } from '../../components/Pagination';
import { Filter } from './components/Filter';
import { Feedback } from '../../components/Feedback';

let searchOptions:ProjectSearchOptions = defaultSearchOptions;

export const Send: React.FC = () => {
  const projects = useSelector(selectProjects);
  const paging = useSelector(selectPaging);
  const searchFeedback = useSelector(selectSearchFeedback);
  const dispatch = useDispatch();

  const [selection, setSelection] = React.useState<number[]>([]); // selection as a list of project id's
  const [selectAll, setSelectAll] = React.useState(false); //
  const [selectionCount, setSelectionCount] = React.useState<number>(0); //
  const [sendFeedback, setSendFeedback] = React.useState<Feedback>();
  const [sendInProgress, setSendInProgress] = React.useState(false); //React.useState<Feedback>();

  const onPaging = (page:number) => {
    dispatch(searchProject({...searchOptions, page }));
  }

  const onSelectAll = React.useCallback((evt) => {
    setSelectAll(evt.target.checked);
  }, [])

  const onSelect = React.useCallback((evt) => {
    const id = parseInt(evt.target.name);
    if (isNaN(id)) { return; }
    const selected = evt.target.checked;

    if (selected) {
      const newSelection = [...selection];
      newSelection.push(id);
      return setSelection(uniq(newSelection));
    }
    setSelection(selection.filter(c => c !== id));
  }, [selection]);


  const onSend = () => {
    if (!projects) { return; }
    setSendFeedback({ message: 'sending project to vendors', isWaiting: true, error: false });
    setSendInProgress(true);
    dispatch(sendToSupplier({
      projects: selectAll ? projects.map(c => c.id) : selection,
      cb: (_result:any) => {
        setSendFeedback({ message: 'projects send to vendor', isWaiting: false, error: false });
        setSendInProgress(false);
      },
    }));
  };

  let feedbackMessage = '';
  if (searchFeedback && !searchFeedback.isWaiting && searchFeedback.isSearchResult === true) {
    feedbackMessage = searchFeedback.count === 0 ? 'no search result' : 'search result count';
  }

  React.useEffect(() => {
    setSelectionCount(selectAll ? (projects && projects.length) || 0 : selection.length);
  }, [selection, selectAll, projects]) ;

  const onClear = React.useCallback(() => {
    setSelection([]);
    setSelectAll(false);
  }, []) ;

  const onSearchFilterChange = React.useCallback((filters:ProjectSearchOptions) => {
    dispatch(searchProject(filters));
    searchOptions = filters;
  }, [dispatch]);

  return (
    <>
      {/* HEAD */}
       <div className="o-page__header">
           <div className="o-container">
               <div>
                   <Link to="/projects" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
                   <span className="ml-4">
                      <Trans i18nKey="send projects" />
                   </span>
               </div>
              <div>
                <button
                    className={`o-button ml-4 ${selectionCount === 0 ? 'bg-color-red': ''}`}
                    type="button"
                    onClick={onSend}
                    disabled={selectionCount === 0 || sendInProgress}
                >
                  <Trans i18nKey="send" />
                </button>
              </div>
           </div>
      </div>
      {/* MAIN */}
      <div className="o-page__main">
        <div className="o-container">
          {sendFeedback && (
            <Feedback feedback={sendFeedback} showProgress={sendInProgress} />
          )}
          {/* FILTERS */}
          <div className="o-page__actions">
            <Filter onFilterChange={onSearchFilterChange} />
          </div>
          {/* SEARCH FEEDBACK */}
          {feedbackMessage && (
            <div className="p-4 mb-4 font-bold">
              <Trans i18nKey={feedbackMessage} values={{ count: (searchFeedback && searchFeedback.count) || 0}} />
            </div>
          )}
          {/* SELECTION FEEDBACK */}
          <div className="p-4 mb-4 font-bold flex">
            {projects &&
              <span>
                <Trans i18nKey="project selection count" values={{ count: selectionCount }} />
              </span>
            }
            {selectionCount > 0 &&
              <button
                className="px-2 text-gray-0 hover:text-gray-400 mr-1 ml-1"
                type="button" onClick={onClear}>
                <i className="fas fa-times"></i>
              </button>
            }
          </div>
        <div className="o-table">
          <table>
            <thead>
              {/* TABLE HEADER */}
              <tr className="o-table__header grid-cols-7">
                {/* Checkbox menu col */}
                <th>
                  {projects && projects.length > 0 &&
                  <Checkbox
                    onChange={onSelectAll}
                    checked={selectAll}
                  />}
                </th>
                <th><Trans i18nKey="klant" /></th>
                <th><Trans i18nKey="leestnr" /></th>
                <th><Trans i18nKey="model" /></th>
                <th><Trans i18nKey="datum" /></th>
                <th><Trans i18nKey="medewerker" /></th>
                <th><Trans i18nKey="status" /></th>
              </tr>
            </thead>
            <tbody>

          {/* TABLE ROWS */}
          {projects && projects.map(c => (
            <Row
              key={c.id}
              project={c}
              inputProps={{
                onChange:onSelect,
                name:`${c.id}`,
                checked: selection.indexOf(c.id) !== -1 || selectAll,
                disabled: selectAll,
              }}
            >
              <SupplierRow project={c} />
            </Row>
          ))}
            </tbody>
          </table>
          {/* PAGER */}
          <Pagination meta={paging} onChange={onPaging}/>
        </div>
        </div>
      </div>
    </>
  )
};

export default Send;
