import { createSlice } from '@reduxjs/toolkit';
import { 
  getProducts, 
  getProduct, 
  updateProduct, 
  createProduct, 
  removeProduct, 
  searchProduct,
} from './actions';
import { RootState } from '../../app/store';
import { getResponseError } from '../../api';

interface productsState {
  error: boolean | null,
  isWaiting: boolean,
  items: Product[] | null,
  current: Product | null,
  message: string;
  paging: PageMeta | null,
  isSearchResult: boolean, // 'items' is result of search ?
}

const initialState: productsState = {
  error: false, 
  isWaiting: false,
  items: null,
  current: null,
  message: '',
  paging: null,
  isSearchResult: false,
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL PRODUCTS 
    .addCase(getProducts.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = false;
    })
    .addCase(getProducts.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.paging = action.payload.meta;
      state.message = '';
    })
    .addCase(getProducts.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = '';
      state.message = getResponseError(action); 
    })
    // GET SINGLE PRODUCT
    .addCase(getProduct.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(getProduct.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = '';
    })
    .addCase(getProduct.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action); 
    })
    // UPDATE PRODUCT
    .addCase(updateProduct.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De aanpassingen worden opgeslagen...';
    })
    .addCase(updateProduct.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De aanpassingen zijn opgeslagen';
    })
    .addCase(updateProduct.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action); 
    })
    // CREATE PRODUCT 
    .addCase(createProduct.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden aangemaakt...';
    })
    .addCase(createProduct.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn aangemaakt...';
    })
    .addCase(createProduct.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action); 
    })
    // REMOVE PRODUCT
    .addCase(removeProduct.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden verwijderd';
    })
    .addCase(removeProduct.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn verwijderd';
    })
    .addCase(removeProduct.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action); 
    })
    // SEARCH PRODUCT
    .addCase(searchProduct.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchProduct.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchProduct.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action); 
      state.isSearchResult = true;
    })
    .addDefaultCase(() => {}),
});

// SELECTORS
export const selectProducts = (state: RootState) => state.products.items;
export const selectProduct = (state: RootState) => state.products.current;
export const selectPaging = (state: RootState) => {
  if (state.products.isSearchResult) { return null; }
  return state.products.paging;
}
export const selectFeedback = (state: RootState):Feedback => ({
  isWaiting: state.products.isWaiting,
  message: state.products.message,
  error: state.products.error,
});
export const selectSearchFeedback = (state: RootState):SearchFeedback => ({
  isSearchResult: state.products.isSearchResult,
  isWaiting: state.products.isWaiting,
  count: state.products.isSearchResult && Array.isArray(state.products.items) ? state.products.items?.length : null,
});

// UTIL 
export const displayName = (product: Product|null) => {
  return product ? product.name : '';
}

export default productsSlice.reducer;
