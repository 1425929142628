import { request } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/supplier`;

const service = () => {
  return {
    findAll: (pageInfo:PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    getSupplier: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    updateSupplier: (supplier:Supplier) => request(`${baseUrl}/${supplier.id}`, 'patch', supplier),
    createSupplier: (supplier:PendingSupplier) => request(`${baseUrl}`, 'post', supplier),
    removeSupplier: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'delete'),
  };
};

export default service;
