import {useState, useCallback, default as React} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { selectFeedback } from './swatchSlice';
import { AddForm } from './components/AddForm';
import { createSwatch } from './actions';
import { Feedback } from '../../components/Feedback';

interface Props {
  backToUrl?: string,
}

export const AddSwatch: React.FC<Props> = ({ backToUrl }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const feedback = useSelector(selectFeedback);

  const [swatch, setSwatch] = useState<PendingSwatch>({
    name: '',
    category: '',
    imageUrl: '',
  });

  const onChange = useCallback(({ target }) => {
    setSwatch({...swatch, [target.name]: target.value });
  }, [swatch]);

  const onSubmit = useCallback((file:File|null) => {
    dispatch(createSwatch({ swatch, image: file }));
  }, [dispatch, swatch]);

  const onRemoveImage = useCallback(() => {
    if (!swatch) { return; }
    setSwatch({...swatch, imageUrl: '' });
  }, [swatch]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to="/swatches" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">{t('add swatch')}</span>
          </div>
        </div>
      </div>
      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
        <AddForm
          swatch={swatch}
          onChange={onChange}
          onSubmit={onSubmit}
          onRemoveImage={onRemoveImage}
        />
        <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default AddSwatch;
