import * as React from 'react';
import CreatableSelect from 'react-select/creatable';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLeathersOptions } from '../leatherSlice';
import { getLeathers } from '../actions';
import { useAppDispatch } from '../../../app/hooks';

interface Props {
  onChange: Function;
  value: Option | string | null;
  name: string;
  id: string;
}
export const Selector: React.FC<Props> = (props:Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const options = useSelector(selectLeathersOptions);

  React.useEffect(() => {
    dispatch(getLeathers({ page: 0, limit: 999 }));
  }, [dispatch]);

  const handleChange = (newValue: any, actionMeta: any) => {
    if (!options) { return; }
    switch(actionMeta.action) {
      case 'clear':
        props.onChange(props.name, null);
        break;
      case 'create-option':
        props.onChange(props.name, { value: newValue.value, label: newValue.label });
        break;
      case 'select-option':
        props.onChange(props.name, options.find(c => c.id === newValue.value));
    }
  };

  if (!options) {
    return null;
  }
  return (
    <CreatableSelect
      isClearable
      onChange={handleChange}
      options={options}
      defaultValue={typeof props.value === 'string' ? { value: props.value, label: props.value } : props.value}
      placeholder={t("select a color")}
      className={"o-select"}
      inputId={props.id}
    />
  );
}

export default Selector
