import * as React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { Navbar } from '../../components/NavBar';
import { AddLeather } from "./Add";
import { EditLeather } from "./Edit";
import { RemoveLeather } from "./Remove";
import { Leathers } from "./Leathers";

export const LeatherRoutes: React.FC = () =>  {
  return (
    <Routes>
      <Route
        path="/add"
        element={ <AddLeather /> }
      />
      <Route
        path="/edit/:id"
        element={ <EditLeather /> }
      />
      <Route
        path="/remove/:id"
        element={ <RemoveLeather /> }
      />
      <Route
        path="/"
        element={
          <>
          <Navbar />
          <Leathers />
          </>
        }
      />
    </Routes>
  );
}

export default LeatherRoutes;
