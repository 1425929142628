import { createAsyncThunk } from '@reduxjs/toolkit';
import authenticationService from './authenticationService';
import { SignInCredentials } from './authenticationSlice';
import { setAuthHeader, clearAuthHeader } from '../../api';
import { errorToast, successToast } from '../toast/toastSlice';

export const login = createAsyncThunk<
  // Return type of the payload creator
  any,
  // First argument to the payload creator
  SignInCredentials,
  // Types for ThunkAPI
  { rejectValue: string }
  >(
  '/login',
  async (payload:SignInCredentials, { rejectWithValue }) => {
    try {
      const result:any = await authenticationService().login(payload);
      if (result?.accessToken) {
        setAuthHeader(result.accessToken);
      }
      return result;
    } catch (err:any) {
      console.log(err);
      const message = err && err.message ? err.message : 'nok';
      return rejectWithValue(message);
    }
  },
);

export const logout = createAsyncThunk<any, void, { rejectValue: string }>(
  '/logout',
  async (_payload, { rejectWithValue }) => {
    try {
      return await authenticationService().logout();
    } catch (err:any) {
      return rejectWithValue(err);
    }
  },
);


export const getProfiles = createAsyncThunk<any, void, { rejectValue: string }>(
  '/profiles',
  async (_payload, { rejectWithValue }) => {
    try {
      return await authenticationService().profiles();
    } catch (err:any) {
      return rejectWithValue(err);
    }
  },
);

// The 'me' action will set the axios Authorization header to the accessToken in localStorage.
// If 'me' fails the axios Authorization header is removed and the login is shown
// If 'me' is valid the accessToken is re-used
export const me = createAsyncThunk<any, void, { rejectValue: string }>(
  '/me',
  async (_payload, { rejectWithValue }) => {
    const accessToken = authenticationService().getAccessToken();
    try {
      if (accessToken) {
        setAuthHeader(accessToken);
        const data = await authenticationService().me();
        return {
          user: data,
          accessToken,
        }
      } else {
        clearAuthHeader();
      }
      return rejectWithValue('');
    } catch (err:any) {
      if (accessToken) {
        authenticationService().removeAccessToken(accessToken);
        clearAuthHeader();
      }
      return rejectWithValue(err);
    }
  },
);

export const updateUserPassword = createAsyncThunk<any, {id: string, payload: UpdateUserPassword }, { rejectValue:
  string, dispatch: any }>(
  '/userPasswordUpdate',
  async ({ id, payload }, { rejectWithValue, dispatch }) => {
    try {
      const result = await authenticationService().updateUserPassword(id, payload);
      dispatch(successToast('api.updated'));
      return result;
    } catch (err:any) {
      if (err.message) {
        if (Array.isArray(err.message)) {
          err.message.map((c:string) => dispatch(errorToast(c)));
        } else if (typeof err.message === 'string') {
          dispatch(errorToast(err.message));
       }
      }
      console.log(err);
      return rejectWithValue(err);
    }
  },
);

export const resetUserPassword = createAsyncThunk<any, {id: string, payload: ResetUserPassword }, { rejectValue:
  string, dispatch: any }>(
  '/userPasswordReset',
  async ({ id, payload }, { rejectWithValue, dispatch }) => {
    try {
      const result = await authenticationService().resetUserPassword(id, payload);
      dispatch(successToast('api.updated'));
      return result;
    } catch (err:any) {
      if (err.message) {
        if (Array.isArray(err.message)) {
          err.message.map((c:string) => dispatch(errorToast(c)));
        } else if (typeof err.message === 'string') {
          dispatch(errorToast(err.message));
       }
      }
      console.log(err);
      return rejectWithValue(err);
    }
  },
);
