import {default as React, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getLeathers } from './actions';
import { Row } from './Row';
import { selectLeathers, selectPaging, selectSearchFeedback } from './leatherSlice';
import { Pagination } from '../../components/Pagination';

export const List: React.FC = () => {
  const leathers = useSelector(selectLeathers);
  const feedback = useSelector(selectSearchFeedback);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paging = useSelector(selectPaging);

  useEffect(() => {
    dispatch(getLeathers({ page: 1 }));
  }, [dispatch]);

  const onPaging = (page:number) => {
    dispatch(getLeathers({ page }));
  }

  let feedbackMessage = '';
  if (feedback && !feedback.isWaiting && feedback.isSearchResult === true) {
    feedbackMessage = feedback.count === 0 ? 'no search result' : 'search result count';
  }

  return (
    <>
      {feedbackMessage && (
        <div className="p-4 mb-4 font-bold">
        {t(feedbackMessage, { count: (feedback && feedback.count) || 0})}
        </div>
      )}
      {/* ITEMS */}
      <div className="o-table">
        <table>
          {/* TABLE HEADER */}
          <thead>
            <tr className="o-table__header">
              <th>{t('m-code')}</th>
              <th>{t('article')}</th>
              <th>{t('color')}</th>
              <th>Datum</th>
              <th>  </th>
            </tr>
          </thead>
          <tbody>
            {/* TABLE ROWS */}
          {leathers && leathers.map(c => <Row key={c.id} leather={c} />)}
          </tbody>
        </table>
      </div>
      {/* PAGER */}
      <Pagination meta={paging} onChange={onPaging}/>
    </>
  );


}

export default List;
