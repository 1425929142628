import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { searchProduct, getProducts } from './actions';
import { selectSearchFeedback } from './productsSlice';

export const Search: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const feedback = useSelector(selectSearchFeedback);
  const [searchText, setSearchText] = React.useState('');
  const [showInput, setShowInput] = React.useState(false);
  const searchInput = React.useRef<HTMLInputElement>(null);

  const onSubmit = React.useCallback((evt) => {
    if (evt) {
      evt.preventDefault();
    }
    if (!showInput) {
      setShowInput(true);
      // focus search input element
      window.setTimeout(() => {
        if (searchInput?.current) {
          searchInput.current.focus();
        }
      });
      return;
    }
    if (!searchText || !evt) { return; }
    setShowInput(true);
    dispatch(searchProduct(searchText));
  }, [dispatch, searchText, showInput, searchInput]);

  const onChange = React.useCallback((evt) => {
    switch (evt.key) {
      case 'Esc': 
      case 'Escape':
        setShowInput(false);
        setSearchText('');
        dispatch(getProducts({ page: 1 }));
        break;
      default:
        setSearchText(evt.target.value); 
    }
  }, [dispatch]);

  const onClose = React.useCallback(() => {
    setShowInput(false);
    setSearchText('');
    dispatch(getProducts({ page: 1 }));
  }, [dispatch]);

  return (
    <form onSubmit={onSubmit}>
      <div className="flex items-center">
        <div className="flex-1 ">
          {showInput && 
            <div className="relative">
              <input 
                 className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm shadow focus:outline-none focus:ring mr-4"
                 type="search"
                 onKeyDown={onChange}
                 ref={searchInput}
              />
              {(feedback && feedback.isWaiting) && (
                <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-3 pr-3 py-3">
                  <i className="fas fa-circle-notch animate-spin"></i>
                </span>
              )}
            </div>
          }
          <button className="hidden" type="submit">
            {t('search')}
          </button>
        </div>
        <div className="flex-1 ">
          <button 
            className="bg-gray-200 text-gray-800 px-3 py-2 rounded-full hover:bg-gray-600 hover:text-gray-100 mr-1 ml-1" 
            type="submit">
            <i className="fas fa-search"></i>
          </button>
        </div>
        <div className="flex-1 ">
          {showInput && (<button 
            className="bg-gray-200 text-gray-800 px-4 py-2 rounded-full hover:bg-gray-600 hover:text-gray-100 mr-1 ml-1" 
            type="button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>)}
        </div>
      </div>
    </form>
  )
}

export default Search;
