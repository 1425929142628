import React from "react";
import { config } from '../../config';

export interface AvatarProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name?: string,
  url?: string,
  size?: number | string,
  className?: string,
};

export const Avatar: React.FC<AvatarProps> = ({ url, name, size, className, ...rest }: AvatarProps) =>
  <img
    src={url || config.placeholderImage}
    alt={name || ""}
    title={name || ""}
    className={`border-white border-8 rounded-full align-middle inline-block w-full ${className || ''}`}
    style={{ maxWidth: `${size ? size : 256 }px` }}
    {...rest}
  />
