import * as React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { Navbar } from '../../components/NavBar';
import { Products } from './Products';
import { EditProduct } from './Edit';
import { AddProduct } from './Add';
import { RemoveProduct  } from './Remove';

export const ProductRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/add"
        element={ <AddProduct /> }
      />
      <Route
        path="/edit/:id"
        element={ <EditProduct /> }
      />
      <Route
        path="/remove/:id"
        element={ <RemoveProduct /> }
      />
      <Route
        path="/"
        element={
          <>
            <Navbar />
            <Products />
          </>
        }
      />
    </Routes>
  );

}

export default ProductRoutes;
