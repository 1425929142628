import * as React from 'react';
import { Trans } from 'react-i18next';

interface Props {
  properties: ProjectProperties | undefined,
  onChange: React.FormEventHandler,
}

const sectionName = 'schachthoogte';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();
const schachthoogteLinks: keyof ProjectProperties = 'schachthoogte-links';
const schachthoogteRechts: keyof ProjectProperties = 'schachthoogte-rechts';

export const ProductDetailsSectionA: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>
        <Trans i18nKey="Schachthoogte" />
      </h2>
      <div className="md:flex flex-nowrap o-group">
        {/** INPUT */}
        <div className={`flex-1 o-input__wrapper ${properties['schachthoogte-links'] === "" ? 'print:hidden' : ''}`}>
          <label>
            <Trans i18nKey="Links" />
          </label>
          <input
            name={schachthoogteLinks}
            type="number"
            className="o-input__text"
            onChange={onChange}
            value={properties['schachthoogte-links']}
          />
          <span>
            mm
          </span>
        </div>
        {/** INPUT */}
        <div className={`flex-1 o-input__wrapper md:mx-8 ${properties['schachthoogte-links-en-rechts'] === "" ? 'print:hidden' : ''}`}>
          <label
            htmlFor={`${sectionId}-0`}
          >
            <Trans i18nKey="Links & Rechts" />
          </label>
          <input
            name="schachthoogte-links-en-rechts"
            type="number"
            className="o-input__text"
            onChange={onChange}
            value={properties['schachthoogte-links-en-rechts']}
            id={`${sectionId}-0`}
          />
          <span>
            mm
          </span>
        </div>
        {/** INPUT */}
        <div className={`flex-1 o-input__wrapper ${properties['schachthoogte-rechts'] === "" ? 'print:hidden' : ''}`}>
          <label
            htmlFor={`${sectionId}-1`}
          >
            <Trans i18nKey="Rechts" />
          </label>
          <input
            name={schachthoogteRechts}
            type="number"
            className="o-input__text"
            onChange={onChange}
            value={properties['schachthoogte-rechts']}
            id={`${sectionId}-1`}
          />
          <span>
            mm
          </span>
        </div>
      </div>
    </div>
  );
});

export default ProductDetailsSectionA;
