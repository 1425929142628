import * as React from 'react';
import { Trans } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { Button } from '../../../components/Button';
import { updateUserPassword } from '../../authentication/actions';
import { useAppDispatch } from '../../../app/hooks';

export interface FormProps {
  user: User,
};

export const PasswordChange: React.FC<FormProps> = (props:FormProps) => {
  const dispatch = useAppDispatch();
  const { user } = props;
  const [localPassword, setLocalPassword] = React.useState<UpdateUserPassword>({
    email: user.email,
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
  });

  const onChange = React.useCallback((evt) => {
    const target = evt.target;
    setLocalPassword({...localPassword, [target.name]: target.value });
  }, [localPassword]);

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    dispatch(updateUserPassword({
      id: user.id,
      payload: localPassword,
    }))
    .then(unwrapResult)
    .then(() => {
      // Clear input after successfull api update
      setLocalPassword({
        ...localPassword,
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
      });
    })
    .catch(() => {});
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="mb-3">
        <label className="mr-2">
           <Trans i18nKey="old password" />
        </label>
        <input
          name="oldPassword"
          type="password"
          className="o-input__text"
          value={localPassword.oldPassword}
          required={true}
          onChange={onChange}
          autoComplete="current-password"
        />
      </div>
      <div className="mb-3">
        <label className="mr-2">
           <Trans i18nKey="password" />
        </label>
        <input
          name="password"
          type="password"
          className="o-input__text"
          value={localPassword.password}
          required={true}
          onChange={onChange}
          autoComplete="new-password"
        />
      </div>
      <div className="mb-3">
        <label className="mr-2">
           <Trans i18nKey="confirm password" />
        </label>
        <input
          name="passwordConfirmation"
          type="password"
          className="o-input__text"
          value={localPassword.passwordConfirmation}
          required={true}
          onChange={onChange}
          autoComplete="new-password"
        />
      </div>
      <Button className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
         <Trans i18nKey="update password" />
      </Button>
    </form>
  )
};

export default PasswordChange;
