import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import {
  getLeathers,
  getLeather,
  updateLeather,
  createLeather,
  removeLeather,
  searchLeather,
} from './actions';
import { RootState } from '../../app/store';
import { getResponseError } from '../../api';

interface leathersState {
  error: boolean | null,
  isWaiting: boolean,
  items: Leather[] | null,
  current: Leather | null,
  message: string;
  paging: PageMeta | null,
  isSearchResult: boolean, // 'items' is result of search ?
}

const initialState: leathersState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  message: '',
  paging: null,
  isSearchResult: false,
}

export const leathersSlice = createSlice({
  name: 'leathers',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL leatherS
    .addCase(getLeathers.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = false;
    })
    .addCase(getLeathers.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.paging = action.payload.meta;
      state.message = '';
    })
    .addCase(getLeathers.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = '';
      state.message = getResponseError(action);
    })
    // GET SINGLE leather
    .addCase(getLeather.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(getLeather.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = '';
    })
    .addCase(getLeather.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // UPDATE leather
    .addCase(updateLeather.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De aanpassingen worden opgeslagen...';
    })
    .addCase(updateLeather.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De aanpassingen zijn opgeslagen';
    })
    .addCase(updateLeather.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // CREATE leather
    .addCase(createLeather.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden aangemaakt...';
    })
    .addCase(createLeather.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn aangemaakt...';
    })
    .addCase(createLeather.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // REMOVE leather
    .addCase(removeLeather.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden verwijderd';
    })
    .addCase(removeLeather.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn verwijderd';
    })
    .addCase(removeLeather.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // SEARCH leather
    .addCase(searchLeather.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchLeather.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchLeather.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action);
      state.isSearchResult = true;
    })
    .addDefaultCase(() => {}),
});

// SELECTORS
export const selectLeathers = (state: RootState) => state.leathers.items;
// list of leather items for use in autocomplete components
export const selectLeathersOptions = (state: RootState) => (state.leathers.items || []).map(c => ({
  value: c.id,
  label: `${c.color} ${c.code} ${c.article}`,
  ...omit(c, ['archivedAt', 'createdAt', 'updatedAt']),
}));

export const selectLeather = (state: RootState) => state.leathers.current;
export const selectPaging = (state: RootState) => {
  if (state.leathers.isSearchResult) { return null; }
  return state.leathers.paging;
}
export const selectFeedback = (state: RootState):Feedback => ({
  isWaiting: state.leathers.isWaiting,
  message: state.leathers.message,
  error: state.leathers.error,
});
export const selectSearchFeedback = (state: RootState):SearchFeedback => ({
  isSearchResult: state.leathers.isSearchResult,
  isWaiting: state.leathers.isWaiting,
  count: state.leathers.isSearchResult && Array.isArray(state.leathers.items) ? state.leathers.items?.length : null,
});

// UTIL
export const displayName = (leather: Leather|null) => {
  return leather ? `${leather.article} ${leather.color}` : '';
}

export default leathersSlice.reducer;
