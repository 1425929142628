import React from 'react';
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Button } from '../../../components/Button';
import { Avatar } from '../../../components/Avatar';

export interface FormProps {
  user: PendingUser,
  onChange: React.FormEventHandler,
  onSubmit: Function,
  onRemoveImage: Function,
};

export const AddUserForm: React.FC<FormProps> = (props:FormProps) => {
  const { user, onChange } = props;
  const { t } = useTranslation();
  const [images, setImages] = React.useState<ImageListType>([]);

  const onImageChange = (
    imageList: ImageListType,
  ) => {
    setImages(imageList);
  };

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit((images[0] && images[0].file) || null);
  }

  const imageUrl = images[0] && images[0].data_url ? images[0].data_url : user.imageUrl;

  return (
    <div className={"w-full bg-white py-8 px-12"}>
      {/* FORM */}
      <h2>Gegevens van de medewerker</h2>
      <form onSubmit={onSubmit} className="m-4">
        <div className="mb-3">
          <ImageUploading
            value={images}
            onChange={onImageChange}
            dataURLKey="data_url"
          >
            {({
              onImageUpload,
              onImageRemoveAll,
              isDragging,
              dragProps,
            }) => (
              // Make sure buttons have type="buttons" (the default type is "submit")
              <div className="upload__image-wrapper">
                <Avatar
                  url={imageUrl}
                  name={user.email}
                  onClick={onImageUpload}
                  className="cursor-pointer"
                  size="256"
                />
                <button
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800"
                  type="button"
                  {...dragProps}
                >
                  Foto selecteren
                </button>
                &nbsp;
                <button
                  onClick={() => { onImageRemoveAll(); props.onRemoveImage() }}
                  type="button"
                  className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300">
                  Foto verwijderen
                </button>
              </div>
            )}
          </ImageUploading>
        </div>
        <div className="mb-3">
          <label className="mr-2">Voornaam</label>
          <input
            name="firstName"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={user.firstName}
            required={true}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">Naam</label>
          <input
            name="lastName"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={user.lastName}
            required={true}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">Email</label>
          <input
            name="email"
            type="email"
            className="o-input__text"
            onChange={onChange}
            value={user.email}
            required={true}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">
             <Trans i18nKey="password" />
          </label>
          <input
            name="password"
            type="password"
            className="o-input__text"
            onChange={onChange}
            value={user.password}
            required={true}
            autoComplete="new-password"
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">
             <Trans i18nKey="confirm password" />
          </label>
          <input
            name="passwordConfirmation"
            type="password"
            className="o-input__text"
            onChange={onChange}
            value={user.passwordConfirmation}
            required={true}
            autoComplete="new-password"
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">Rol</label>
          <select value={user.role} onChange={onChange} name="role">
            <option value="ADMIN">{t('ROLE.ADMIN')}</option>
            <option value="USER">{t('ROLE.USER')}</option>
          </select>
        </div>
        <Button className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
          <Trans i18nKey="submit" />
        </Button>
      </form>
    </div>
  )
};

export default AddUserForm;
