import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation} from "react-router-dom";
import { Trans } from 'react-i18next';
import { getProfiles, login } from './actions';
import { selectUserProfiles, UserProfile } from './userProfileSlice';
import { selectAuthenticated, selectFeedback, selectReload, reset } from './authenticationSlice';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { Alert } from '../../components/Alert';

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as {
    from: { pathname: string }
  };

  const [userProfile, setUserProfile] = React.useState<UserProfile | null>(null);
  const [password, setPassword] = React.useState('');

  const profiles = useSelector(selectUserProfiles);
  const authenticated = useSelector(selectAuthenticated);
  const reload = useSelector(selectReload);
  const feedback = useSelector(selectFeedback);
  const dispatch = useDispatch();

  React.useEffect(() => {
    // On logout the accessToken will be reset and 'reload' will be set to true
    // to force an app reload to clear the store.
    if (reload === true) {
      window.location.reload();
    }
  }, [reload]);

  React.useEffect(() => {
    if (profiles === null) {
      dispatch(getProfiles());
    }
  }, [dispatch, profiles]);

  React.useEffect(() => {
    if (authenticated === true) {
      // Redirect to where we come from before a redirect to the login or use
      // 'projects' as default route.
      navigate(locationState && locationState?.from?.pathname !== '/logout' ?
        locationState.from.pathname : '/projects', { replace: true })
    }
  }, [navigate, authenticated, locationState]);

  function selectProfile(userProfile:UserProfile) {
    setUserProfile(userProfile);
  }

  function resetProfile() {
    setUserProfile(null);
    setPassword('');
    dispatch(reset()); // Reset the failed login message
  }

  const onChangePassword = React.useCallback((event) => {
    setPassword(event.currentTarget.value);
  }, []);

  const handleOnSubmit = React.useCallback((event) => {
    event.preventDefault();
    if (!userProfile) { return; }
    dispatch(login({ email: userProfile.email, password }));
  }, [dispatch, password, userProfile]);

  return (
    <div className={"o-page__main"}>
      { !userProfile && (
        <>
          <div className={"text-center p-6"}>
            <h1>Hey, wie wil er aanmelden?</h1>
          </div>
            <div className={"max-w-screen-sm container p-3 gap-3 mx-auto grid grid-cols-2 md:grid-cols-3"}>
              {profiles && profiles.map(c => (
                <div key={c.key} onClick={() => selectProfile(c)}>
                  <Avatar url={c.imageUrl} />
                </div>
              ))}
            </div>
        </>
      )}
      { userProfile && (
        <>
            <div className="py-8">
              <Button
                onClick={resetProfile}
                className="o-action__icon bg-white border-roti-100"
                type="button">
                  <i className="fa fa-arrow-left"></i>
              </Button>
            </div>
            <div className={"max-w-screen-sm mx-auto items-center flex flex-col h-full"}>
              <div className={"text-center"}>
                  <h1>Welkom {userProfile.name},<br />wat is jou wachtwoord?</h1>
              </div>
                <div className={"sm:flex flex-grow items-center w-full"}>
                    <div className={"sm:w-1/3 text-center mx-auto my-4"}>
                        <Avatar url={userProfile.imageUrl} name={userProfile.email} size="128" />
                    </div>
                  <form onSubmit={handleOnSubmit} className={"sm:w-2/3"}>
                    <div className="relative w-full mb-3">
                      <label
                        htmlFor="login-password"
                      >
                        Wachtwoord
                      </label>
                      <input
                        id="login-password"
                        type="password"
                        className="o-input__text w-full"
                        autoComplete="current-password"
                        onChange={onChangePassword}
                        value={password}
                      />
                    </div>
                    {(feedback && feedback.message) && <Alert color="red">
                      <Trans i18nKey={feedback.message} />
                    </Alert>}
                    <Button className="o-button w-full" type="submit">
                       Aanmelden
                    </Button>
                  </form>
                </div>
            </div>
        </>
      )}
    </div>
  );
}

export default Login;
