import React from "react";

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {children, ...rest} = props;
  return (
    <button {...rest}>
      {children} 
    </button>
  )
};
