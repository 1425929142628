import { Trans } from 'react-i18next';

export interface Props {
  project: PendingProject|Project|null,
}

export interface RowProps {
  validation: ValidationResult|null,
}

export const Row: React.FC<RowProps> = ({ validation }: RowProps) => {
  if (!validation || !validation.contraints) {
    return null;
  }
  const lines = Object.keys(validation.contraints).map(c => {
    const line = validation.contraints[c].split(validation.property);
    return {
      property: validation.property,
      message: line.length === 2 ? line[1].trim() : validation.contraints[c],
    }
  });

  return (
    <div className="mt-4">
      {lines && lines.map((c, idx) => (
        <div key={idx}>
          <span className="font-bold mr-2" ><Trans i18nKey={c.property} /></span>
          <Trans i18nKey={c.message} />.
        </div>
      ))}
    </div>
  );
}

export const ValidationSummary: React.FC<Props> = ({ project }: Props) => {
  if (!project || !project.validation || !Array.isArray(project.validation)) {
    return null;
  }
  if (project.validation.length === 0) {
    return null;
  }
  return (
    <div className="min-w-min mx-auto bg-white py-8 px-12 mt-4">
      <span className="text-xl">
        <Trans i18nKey="project notifications" />
      </span>
      {project.validation && project.validation.map((c, idx) => (
        <Row
          key={idx}
          validation={c}
        />
      ))}
    </div>
  );
};
