import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButtonInput } from './FormElements';
import ProductDetailComment from './ProductDetailComment';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
}

const values:ValueItem[] = ([
  {
    value: 'Polstering',
  },
  {
    value: 'Extra polster',
    textEntry: true,
  },
  {
    value: 'Totale polstering',
    textEntry: true,
  },
  {
    value: 'Geen',
  },
]);

const sectionName = 'Enkel Polster';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionE: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  const { t } = useTranslation();
  const [showComment, setShowComment] = React.useState<boolean|null>(null);

  React.useEffect(() => {
    if (properties && showComment === null) {
      setShowComment(typeof properties["enkel-polster-comment"] === 'string' && properties["enkel-polster-comment"].length > 0);
    }
  }, [properties, showComment]);

  if (!properties) { return null; }

  const isDisabled = properties['contrefort-links']==='Normaal'&&properties['contrefort-rechts']==='Normaal';
  return (
    <div className={`o-section ${isDisabled ? 'o-section__disabled print:hidden': ''}`} id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid md:grid-cols-3 mt-4 font-bold">
        <span></span>
        <span>{t('Links')}</span>
        <span>{t('Rechts')}</span>
      </div>
      <div className="mt-6 print:mt-0">
        {/** INPUT */}
        { values.map((c, idx) => (
          <div className={`grid grid-cols-3 mt-4 print:mt-0 o-selection ${properties['enkel-polster-links'] === c.value || properties['enkel-polster-rechts'] === c.value ? 'o-selection--checked' : '  '}`} key={c.value} >
            <div>
              {t(c.value)}
            </div>
            <RadioButtonInput
              name="enkel-polster-links"
              checked={properties['enkel-polster-links'] === c.value}
              onChange={onChange}
              value={c.value}
              properties={properties}
              textEntry={c.textEntry ?  'enkel-polster-links-mm' : false}
              suffix="mm"
              id={`${sectionName}-${idx}-links`}
              disabled={isDisabled}
            />
            <RadioButtonInput
              name="enkel-polster-rechts"
              checked={properties['enkel-polster-rechts'] === c.value}
              onChange={onChange}
              value={c.value}
              properties={properties}
              textEntry={c.textEntry ?  'enkel-polster-rechts-mm' : false}
              suffix="mm"
              id={`${sectionName}-${idx}-rechts`}
              disabled={isDisabled}
            />
          </div>
        ))}
      </div>

      {/* INPUT COMMENT */}
      <ProductDetailComment name="enkel-polster-comment" value={properties["enkel-polster-comment"]} onChange={onChange} />

    </div>
  );
});

export default ProductDetailsSectionE;
