import {useEffect, useState, useCallback, default as React} from 'react';
import { useParams } from "react-router-dom";
import { Trans } from 'react-i18next';
import { selectSupplier, selectFeedback} from './supplierSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getSupplier, updateSupplier } from './actions';
import { EditForm } from './components/EditForm';
import { Feedback } from '../../components/Feedback';

export const EditSupplier: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [localSupplier, setLocalSupplier] = useState<Supplier|null>(null);
  const supplier = useSelector(selectSupplier);
  const feedback = useSelector(selectFeedback);

  // Fetch supplier
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getSupplier(id));
  }, [dispatch, id]);

  // Copy supplier into local data for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    if (supplier !== null && localSupplier === null && id === `${supplier.id}`) {
      setLocalSupplier({...supplier});
    }
  }, [localSupplier, supplier, id]);

  // Form edits to local data
  const onChange = useCallback((evt) => {
    if (!localSupplier) { return; }
    const target = evt.target;
    setLocalSupplier({...localSupplier, [target.name]: target.value });
  }, [localSupplier]);

  const onSubmit = useCallback(() => {
    if (!localSupplier || localSupplier.id === null) { return false; }
    dispatch(updateSupplier({ supplier: localSupplier }));
  }, [dispatch, localSupplier]);

  const onListItemChange = useCallback((name: keyof Supplier, value: any, index:number) => {
    if (!localSupplier) { return; }
    const newList:string[] = Object.assign([], localSupplier[name]);
    newList[index] = value;
    setLocalSupplier({...localSupplier, [name]: newList });
  }, [localSupplier]);

  const onAddListItem = useCallback((name: keyof Supplier) => {
    if (!localSupplier) { return; }

    // Add item to array
    const newList:string[] = Object.assign([], localSupplier[name]);
    newList.push('');

    // Update local supplier
    setLocalSupplier({
      ...localSupplier,
      [name]: newList,
    });
  }, [localSupplier]);

  const onRemoveListItem = useCallback((name: keyof Supplier, index:number) => {
    if (!localSupplier) { return; }

    // Add item to array
    const newList:string[] = Object.assign([], localSupplier[name]);
    newList.splice(index, 1);

    // Update local supplier
    setLocalSupplier({
      ...localSupplier,
      [name]: newList,
    });
  }, [localSupplier]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to={"/suppliers"} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4"><Trans i18nKey="update supplier" /></span>
          </div>
        </div>
      </div>

      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
        {localSupplier && (
          <EditForm
            supplier={localSupplier}
            onChange={onChange}
            onAdd={onAddListItem}
            onRemove={onRemoveListItem}
            onListItemChange={onListItemChange}
            onSubmit={onSubmit}
          />
        )}
        <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default EditSupplier;
