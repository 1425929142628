import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
  defaultImageUrl: string;
  resetFn: Function;
}

const values:ValueItem[] = ([
  { value: 'Beige' },
  { value: 'Bijpassend' },
  { value: 'Blauw' },
  { value: 'Zwart' },
  {
    value: 'Als foto',
    selectProductImage: 'kleur-onderwerk-imageUrl',
  },
  {
    value: 'other',
    label: '...',
    textEntry: 'kleur-onderwerk-other',
  },
]);

const sectionName = 'Kleur onderwerk';
const sectionId:keyof ProjectProperties = 'kleur-onderwerk';

export const ProductDetailsSectionJ: React.FC<Props> = React.memo(({onChange, properties, defaultImageUrl, resetFn}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid grid-cols-2">
        {/** INPUT */}
        { values.map((c, idx) => (
          <div className="grid md:grid-cols-3" key={c.value} >
            <RadioButton
              name={sectionId}
              id={`${sectionId}-${idx}`}
              checked={properties['kleur-onderwerk'] === c.value}
              onChange={onChange}
              properties={properties}
              defaultImageUrl={defaultImageUrl}
              resetFn={resetFn}
              {...c}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionJ;
