import { Trans } from 'react-i18next';
import { Date } from '../../../components/Date';

export interface Props {
  project: Project;
}
export const SupplierRow: React.FC<Props> = (props: Props) => {
  const { project } = props;

  return (
    <div className={`o-table__row`}>
      <div className="grid grid-cols-3 gap-4 mb-6">
        {/* SUPPLIER */}
        <div className="ml-8 text-right">
          <Trans i18nKey="supplier" />
        </div>
        <div className="font-bold col-span-2">{project.supplier && project.supplier.name}</div>

        {/* SUPPLIER SENDON*/}
        <div className="ml-8 text-right">
          <Trans i18nKey="send on" />
        </div>
        <div className="font-bold col-span-2">
          <Date value={project.sendToSupplier} showTime={true} />
        </div>

        {/* SUPPLIER DEADLINE */}
        <div className="ml-8 text-right">
          <Trans i18nKey="Deadline" />
        </div>
        <div className="col-span-2">
          <Date value={(project.properties || {})['leverancier-deadline']} />
        </div>

      </div>
    </div>
  )
};

export default SupplierRow;
