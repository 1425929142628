import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { List } from './List';
import { Search } from './Search';
import * as React from "react";

export function Leathers() {
  const { t } = useTranslation();
  return (
    <div className={"o-page__main"}>
      <div className="o-container">
        {/* HEADER */}
        <div className="o-page__actions">
          <h1>{t('leather')}</h1>
          <div className="flex items-center">
            <Search />
            <Link to={'/leathers/add'} className="o-button">
              <i className="fa fa-plus"></i>
              <span>{t('add leather')}</span>
            </Link>
          </div>
        </div>
        {/* ITEMS */}
        <List />
      </div>

    </div>
  );
}

export default Leathers;
