import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
}

const sectionName = 'Schoring';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionL: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }

  const hasSchoringProperties = properties['schoring-med-links'] === "true" ||  properties['schoring-med-rechts'] === "true" || properties['schoring-lat-rechts'] === "true" || properties['schoring-lat-rechts'] === "true";
  return (
    <div className={`o-section ${!hasSchoringProperties ? 'print:hidden' : ''}`} id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid grid-cols-2 mt-6 print:mt-0">
        <div>
          <span>{t("Links")}</span>
        </div>
        <div>
          <span>{t("Rechts")}</span>
        </div>
        {/** INPUT */}
        <div className="mt-4">
          <Checkbox
            name="schoring-med-links"
            id={`${sectionId}-schoring-med-links`}
            checked={properties['schoring-med-links'] === "true"}
            numberEntry="schoring-med-links-mm"
            value="true"
            onChange={onChange}
            properties={properties}
            label={t('Med')}
            suffix="mm"
          />
        </div>
        {/** INPUT */}
        <div className="mt-4">
          <Checkbox
            name="schoring-med-rechts"
            id={`${sectionId}-schoring-med-rechts`}
            checked={properties['schoring-med-rechts'] === "true"}
            numberEntry="schoring-med-rechts-mm"
            value="true"
            onChange={onChange}
            properties={properties}
            label={t('Med')}
            suffix="mm"
          />
        </div>
        {/** INPUT */}
        <div className="mt-4 o-input__wrapper">
          <Checkbox
            name="schoring-lat-links"
            id={`${sectionId}-schoring-lat-links`}
            checked={properties['schoring-lat-links'] === "true"}
            numberEntry="schoring-lat-links-mm"
            value="true"
            onChange={onChange}
            properties={properties}
            label={t('Lat')}
            suffix="mm"
          />
        </div>
        {/** INPUT */}
        <div className="mt-4">
          <Checkbox
            name="schoring-lat-rechts"
            id={`${sectionId}-schoring-lat-rechts`}
            checked={properties['schoring-lat-rechts'] === "true"}
            numberEntry="schoring-lat-rechts-mm"
            value="true"
            onChange={onChange}
            properties={properties}
            label={t('Lat')}
            suffix="mm"
          />
        </div>
      </div>
    </div>
  );
});

export default ProductDetailsSectionL;
