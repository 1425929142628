import Toast from './Toast';
import { useSelector } from 'react-redux';
import { selectToasts } from './toastSlice';

export function ToastContainer() {
  const toasts = useSelector(selectToasts);

  if (!toasts) {
    return null;
  }
  return (
    <div className="absolute bottom-0 w-full z-50">
      <div className="max-w-xl mx-auto">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            {...toast}
          />
        ))}
      </div>
    </div>
  );
}

export default ToastContainer;
