import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from "react-router-dom";
import { unwrapResult } from '@reduxjs/toolkit';
import { selectProject, displayName } from './projectSlice';
import { removeProject, getProject } from './actions';
import { Button } from '../../components/Button';
import { useAppDispatch } from '../../app/hooks';

export const RemoveProject: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const project = useSelector(selectProject);

  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getProject(id));
  }, [dispatch, id]);

  const onRemove = () => {
    if (typeof id !== 'string') { return; }
    dispatch(removeProject(id))
    .then(unwrapResult)
    .then(() => {
      navigate('/projects');
    })
    .catch(() => {});
  }

  return (
    <>
      <div>
        <Link to="/projects">{t('back')}</Link>
        <h2>Project {displayName(project)} verwijderen?</h2>
      </div>
      <div>
        <Link to="/projects">Terug</Link>
        <Button
        onClick={onRemove}
        className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
          Verwijderen
        </Button>
      </div>
    </>
  )
};

export default RemoveProject;
