import {useEffect, useState, useCallback, default as React} from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectProduct, selectFeedback } from './productsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getProduct, updateProduct} from './actions';
import { EditProductForm } from './components/EditProductForm';
import { Feedback } from '../../components/Feedback';

export const EditProduct: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [localProduct, setLocalProduct] = useState<Product|null>(null);
  const product = useSelector(selectProduct);
  const feedback = useSelector(selectFeedback);

  // Fetch product
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getProduct(id));
  }, [dispatch, id]);

  // Copy product into local data for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    // Copy backend product into local product
    if (product && product !== null && localProduct === null && id === `${product.id}`) {
      const prod = {...product};
      // remove null values from product
      prod.name = product.name || '';
      prod.category = product.category || '';
      setLocalProduct({...prod});
    }
  }, [localProduct, product, id]);


  // Form edits to local data
  const onChange = useCallback(({ target }) => {
    if (!localProduct) { return; }
    setLocalProduct({...localProduct, [target.name]: target.value });
  }, [localProduct]);

  // Submit
  const onSubmit = useCallback((file:File|null) => {
    if (!localProduct || localProduct.id === null) { return false; }
    dispatch(updateProduct({product: {...localProduct}, image: file}));
  }, [dispatch, localProduct]);

  const onRemoveImage = useCallback(() => {
    if (!localProduct) { return; }
    setLocalProduct({...localProduct, imageUrl: '' });
  }, [localProduct]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to="/products" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">{t('edit product')}</span>
          </div>
        </div>
      </div>
      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
          {localProduct && (<EditProductForm
            product={localProduct}
            onChange={onChange}
            onSubmit={onSubmit}
            onRemoveImage={onRemoveImage}
          />)}
          <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default EditProduct;
