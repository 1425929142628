import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
  defaultImageUrl?: string;
  resetFn?: Function;
}

const values:ValueItem[] = [
  {
    value: 'Als foto',
    selectProductImage: 'sluiting-imageUrl',
  },
  {
    value: 'Ringen',
  },
  {
    value: 'Ringen en haken',
  },
  {
    value: 'Velcro met passant',
  },
  {
    value: 'Velcro als foto',
    selectProductImage: 'sluiting-velcro-imageUrl',
  },
  {
    value: 'Ritssluiting',
  },
  {
    value: 'Blinde ringen',
  },
  {
    value: 'Ringen + 2 ritsen langs klep m/l',
  },
  {
    value: 'other',
    label: '...',
    textEntry: 'sluiting-other',
  },
];

const valuesB:ProjectDetailEntry[] = [
  {
    entry: 'sluiting-ringen-aantal',
  },
  {
    entry: 'sluiting-haken-aantal',
  },
];

const valuesC:ProjectDetailEntry[] = [
  {
    entry: 'sluiting-velcro-aantal',
  },
  {
    entry: 'sluiting-velcro-mm',
  },
];

const options = [
  { value: 10, label: '10 mm' },
  { value: 15, label: '15 mm' },
  { value: 20, label: '20 mm' },
  { value: 25, label: '25 mm' },
  { value: 30, label: '30 mm' },
];

const sectionName = 'sluiting';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

const RingenEnHaken: React.FC<Props> = ({onChange, properties}:Props) => {
  if (!properties) { return null; }
  return (
    <div className="md:flex flex-nowrap o-group">
      { valuesB.map(c => (
      <div className="flex-1 o-input__wrapper" key={c.entry}>
        <label
          htmlFor={`${sectionId}-${c.entry}`}
        >
          <Trans i18nKey={c.entry} />
        </label>
        <input
          name={c.entry}
          type="number"
          min="0"
          className="o-input__text"
          onChange={onChange}
          value={properties[c.entry]}
          id={`${sectionId}-${c.entry}`}
        />
      </div>
      ))}
    </div>
  );
}

const Velcro: React.FC<Props> = ({onChange, properties}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="md:flex flex-nowrap o-group">
      <div className="flex-1 o-input__wrapper">
        <label
          htmlFor={valuesC[0].entry}
        >
          <Trans i18nKey={valuesC[0].entry} />
        </label>
        <input
          id={valuesC[0].entry}
          name={valuesC[0].entry}
          type="number"
          min="0"
          className="o-input__text"
          onChange={onChange}
          value={properties[valuesC[0].entry]}
        />
      </div>
      <div className="flex-1 o-input__wrapper">
        <label
          htmlFor={valuesC[1].entry}
        >
          <Trans i18nKey={valuesC[1].entry} />
        </label>
        <select
          value={properties[valuesC[1].entry]}
          name={valuesC[1].entry}
          onChange={onChange}
          className="o-input__text"
        >
          <option disabled={typeof properties[valuesC[1].entry]==='string'}>
            {t('select a width')}
          </option>
          {options.map(c => (
            <option key={c.value} value={c.value}>
              {t(c.label)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export const ProductDetailsSectionB: React.FC<Props> = React.memo(({onChange, properties, defaultImageUrl, resetFn}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t('Sluiting')}</h2>
      <div className="grid md:grid-cols-2">
        {/** INPUT */}
        { values.map((c, idx) => (
        <RadioButton
          key={c.value}
          name={sectionId}
          checked={properties['sluiting'] === c.value}
          onChange={onChange}
          properties={properties}
          id={`${sectionId}-${idx}`}
          defaultImageUrl={defaultImageUrl}
          resetFn={resetFn}
          {...c}
        >
        <>
          {(c.value === 'Ringen en haken' && properties['sluiting'] === c.value) && (
            <RingenEnHaken onChange={onChange} properties={properties} />
          )}
          {(c.value === 'Velcro met passant' && properties['sluiting'] === c.value) && (
            <Velcro onChange={onChange} properties={properties} />
          )}
        </>
        </RadioButton>
        ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionB;
