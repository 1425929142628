import * as React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { Navbar } from '../../components/NavBar';
import { AddSupplier  } from "./Add";
import { EditSupplier } from "./Edit";
import { RemoveSupplier } from "./Remove";
import { List } from "./List";

export const SupplierRoutes: React.FC = () =>  {
  return (
    <Routes>
      <Route
        path="/add"
        element={ <AddSupplier /> }
      />
      <Route
        path="/edit/:id"
        element={ <EditSupplier /> }
      />
      <Route
        path="/remove/:id"
        element={ <RemoveSupplier /> }
      />
      <Route
        path="/"
        element={
          <>
            <Navbar />
            <List />
          </>
        }
      />
    </Routes>
  );
}

