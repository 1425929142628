import * as React from 'react';
import flattenDeep from 'lodash/flattenDeep';

interface GroupItem {
  label: string,
  id?: string,
  matches: string[], // keys in ProjectProperties that start with a string in this array belong to this group
}

// A grouping of ProjectProperties, keys of ProjectProperties starting with items in the array belong to that group
const items: GroupItem[] = [
  {
    label: 'Model & kleuren',
    id: 'model-en-kleuren',
    matches: [
      'combi-kleur',
      'kleur-stiksel',
      'model-type',
    ],
  },
  {
    label:
      'Schachthoogte',
    matches: [
      'schachthoogte-',
    ],
  },
  {
    label: 'Sluiting', matches: [
      'sluiting',
    ]
  },
  {
    label: 'Voering', matches: [
      'voering-',
    ]
  },
  {
    label: 'Contrefort', matches: [
      'contrefort-'
    ]
  },
  {
    label: 'Tongbevestiging', matches: [
      'tongbevestiging',
    ]
  },
  {
    label: 'Tongpolster', matches: [
      'tongpolster',
    ]
  },
  {
    label: 'Boordpolster', matches: [
      'boordpolster',
    ]
  },
  {
    label: 'Enkel Polster', matches: [
      'enkel-polster-',
    ]
  },
  {
    label: 'Hakovertrek', matches: [
      'hakovertrek',
    ]
  },
  {
    label: 'Onderwerk maakwijze', matches: [
      'onderwerk-maakwijze',
    ]
  },
  {
    label: 'Randtype', matches: [
      'randtype',
    ]
  },
  {
    label: 'Hak', matches: [
      'hak',
    ]
  },
  {
    label: 'Kleur onderwerk',
    id: 'kleur-onderwerk',
    matches: [
      'kleur-onderwerk',
    ]
  },
  {
    label: 'Neus', matches: [
      'neus',
    ]
  },
  {
    label: 'Schoring', matches: [
      'schoring-',
    ]
  },
  {
    label: 'Zoolmateriaal', matches: [
      'zoolmateriaal',
    ]
  },
  {
    label: 'Leverancier', matches: [
      'leverancier',
      'supplier',
    ]
  },
];


interface ItemProps {
  item: GroupItem;
  validation: ValidationResult[];
}

interface Props {
  validation: ValidationResult[];
}

export const Item: React.FC<ItemProps> = ({ item, validation }: ItemProps) => {
  const sectionId = item.id || item.label.replace(/\s/g, '').toLowerCase();
  const validationErrorCount = flattenDeep(item.matches
    .map(k =>
      validation
        .filter(c => c.property.indexOf(k) === 0)
        .map(c => Object.keys(c.contraints))
    )).length;
  return (
    <li className="items-center">
      <div className="py-2 block cursor-pointer" >
        <a href={`#${sectionId}`}>{item.label}</a>
        {validationErrorCount > 0 &&
          <i className="ml-2 fas fa-exclamation-circle"></i>
        }
      </div>
    </li>
  );
};

export const Sidebar: React.FC<Props> = ({ validation }: Props) =>
  <nav className="c-product__sidebar md:left-0 md:float-left md:block md:sticky md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6 print:hidden">
    <ul className="md:flex-col md:min-w-full flex flex-col list-none">
      {items.map(c => <Item key={c.label} item={c} validation={validation} />)}
    </ul>
  </nav>

export default Sidebar;
