import { config } from './config';

export function thumbnailUrl(url:string|undefined) {
  if (typeof url !== 'string') {
    return url;
  }
  const parts = url
    .trim()
    .split('/');
  if (parts.length === 0) { return config.placeholderImage; }
  parts.splice(-1, 0, 'thumbnails','256');
  return parts.join('/');
}
