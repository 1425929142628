import * as React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { selectSuppliers, selectPaging } from './supplierSlice';
import { Row } from './Row';
import { getSuppliers } from './actions';
import { Pagination } from '../../components/Pagination';

export function List() {
  const suppliers = useSelector(selectSuppliers);
  const paging = useSelector(selectPaging);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(getSuppliers({ page: 0 }));
  }, [dispatch]);

  const onPaging = (page:number) => {
    dispatch(getSuppliers({ page }));
  }

  return (
    <div className="o-page__main">
      <div className="o-container">
      <div className="o-page__actions">
        <h1>{t('suppliers')}</h1>
        <div className="flex items-center">
          <Link to={'/suppliers/add'} className="o-button">
            <i className="fa fa-plus"></i>
            <span>{t('create supplier')}</span>
          </Link>
        </div>
      </div>
      <>

        <div className="o-table">
          <table>
            {/* TABLE HEADER */}
            <thead>
              <tr className="o-table__header">
                <th>
                  <Trans i18nKey="name" />
                </th>
                <th>
                  <Trans i18nKey="email" />
                </th>
                <th>
                  <Trans i18nKey="date" />
                </th>
                <th></th>
              </tr>
            </thead>
            {/* TABLE ROWS */}
            <tbody>
              {suppliers && suppliers.map(c => <Row key={c.id} supplier={c} />)}
            </tbody>
          </table>
        </div>

        {/* PAGER */}
        <Pagination meta={paging} onChange={onPaging}/>
      </>
      </div>
    </div>
  );
}
