import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Trans} from 'react-i18next';
import {Link, useNavigate, useParams, useNavigationType, useLocation} from "react-router-dom";
import {selectPending, setPendingProjectProperty} from './projectSlice';
import {List as SwatchList} from '../swatch/List';
import {Search as SwatchSearch} from '../swatch/Search';
import {ProjectCtx} from './ProjectContext';
import {generatePath} from './utils';

interface Props {
  view: string;
}

export const SelectSwatch: React.FC<Props> = ({view}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id } = useParams();
  const navigationType = useNavigationType();
  const projectContext = React.useContext(ProjectCtx);
  const project = useSelector(selectPending);
  const baseUrl = projectContext.baseUrl || generatePath(view, id);
  const location = useLocation();
  const locationState = location.state as {
    backTo: string | null,
    // Optional key in Project.properties to set the selected Swatch to
    // Otherwise the Swatch wil be set on Project.swatch
    setProperty?: keyof ProjectProperties,
    itemKey?: keyof NamedItem,
  };
  const backUrl = locationState && locationState.backTo ? `${baseUrl}${locationState.backTo}` : baseUrl;

  // Go to the start of the add project 'wizard' if we do not come from another
  // route (eg: after browser refresh)
  // @TODO.high check after upgrade
  if (navigationType === "REPLACE") {
    navigate(baseUrl, {replace: true});
  }

  const onSelectSwatch = (swatch: Swatch) => {
    // Store the selected Swatch on a key in Project.properties
    if (locationState && locationState.setProperty) {
      dispatch(
          setPendingProjectProperty(
              {
                property: locationState.setProperty,
                item: swatch,
                itemKey: locationState.itemKey,
              },
          )
      );
      return navigate(backUrl);
    }
  }

  return (
      <>
        {/* HEAD */}
        <div className="o-page__header">
          <div className="o-container">
            <div>
              <Link to={backUrl} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
              {project && <span className="ml-4 font-bold">{project.firstName} {project.lastName}</span>}
              {project && <span className="ml-2 text-gray-400">{project.leestnr}</span>}
            </div>
          </div>
        </div>
        {/* ACTIONS */}

        <div className="o-page__main">
          <div className="o-container">
            <div className="o-page__actions">
              <h1>
                <Trans i18nKey="select your swatch" />
              </h1>
              <div className="flex items-center">
                <SwatchSearch/>
              </div>
            </div>

            {/* ITEMS */}
            <SwatchList onSelect={onSelectSwatch}/>
          </div>
        </div>
      </>
  )
};

export default SelectSwatch;
