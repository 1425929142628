import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getLabel, TextInput, ValueItem, RadioButtonInput } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
}

const values:ValueItem[] = [
  {
    value: 'Calfs',
  },
  {
    value: 'Mals soft',
  },
  {
    value: 'Reuma voering',
  },
  {
    value: 'Stof',
  },
  {
    value: 'Chroomvrij',
  },
  {
    value: 'other',
    label: '...',
    textEntry: 'voering-other',
  },
];

const sectionName = 'voering';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionC: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t('Voering')}</h2>
      <div className="grid grid-cols-3 mt-4 print:mt-0 font-bold">
        <span></span>
        <span>{t('Voor')}</span>
        <span>{t('Achter')}</span>
      </div>
      <div className="mt-6 print:mt-0">
        {/** INPUT */}
        { values.map((c, idx) => (
          <div className={`grid grid-cols-3 mt-4 o-selection ${properties['voering-voor'] === c.value || properties['voering-achter'] === c.value ? 'o-selection--checked' : '  '}`} key={c.value} >
            <div>
              <span>{getLabel(c.label, properties['voering-voor'] === c.value || properties['voering-achter'] === c.value, c.value)}</span>
              { /** text input */ }
              <TextInput
                name="voering-voor"
                checked={properties['voering-voor'] === c.value || properties['voering-achter'] === c.value}
                onChange={onChange}
                properties={properties}
                id={`${sectionName}-${idx}`}
                {...c}
              />
            </div>
            <RadioButtonInput
              name="voering-voor"
              checked={properties['voering-voor'] === c.value}
              onChange={onChange}
              value={c.value}
              properties={properties}
              id={`${sectionId}-${idx}-voor`}
            />
            <RadioButtonInput
              name="voering-achter"
              checked={properties['voering-achter'] === c.value}
              onChange={onChange}
              value={c.value}
              properties={properties}
              id={`${sectionId}-${idx}-achter`}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionC;
