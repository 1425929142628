import React from "react";
import { Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import { Button } from '../Button';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserProfile } from '../../features/authentication/authenticationSlice';
import { logout } from '../../features/authentication/actions';
import { userDisplayName } from '../../features/user/userSlice';
import { Avatar } from '../Avatar';

export const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef<HTMLButtonElement>();
  const popoverDropdownRef = React.createRef<HTMLDivElement>();

  const openDropdownPopover = () => {
    if (btnDropdownRef?.current && popoverDropdownRef?.current) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
      setDropdownPopoverShow(true);
      window.document.addEventListener('mouseup', closeDropdownPopover);
    }
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
    window.document.removeEventListener('mouseup', closeDropdownPopover);
  };

  const onLogout = () => {
    dispatch(logout());
  }

  return (
    <>
      <button
        className="px-3 py-4 lg:py-2 flex items-center"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
      { userProfile && (
        <>
          <span>{userDisplayName(userProfile)}</span>
          <Avatar url={userProfile.imageUrl} name={userProfile.email} size={48} />
        </>)}
      </button>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <Link
          to="/profile"
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100"
        >
          Profile
        </Link>

        <Button
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100"
          onClick={onLogout}
        >
          Logout
        </Button>

      </div>
    </>
  );
};

export default ProfileDropdown;
