import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { List } from './List';
import { Search } from './Search';
import * as React from "react";

export function Products() {
  const { t } = useTranslation();
  return (
    <section className="o-page__main">
        <div className="o-container">
            {/* HEADER */}
            <div className="o-page__actions">
                <h1>{t('catalogue')}</h1>
                <div className="flex items-center">
                    <Search />
                    <Link to={'/products/add'} className="o-button">
                      <i className="fa fa-plus"></i>
                      <span>{t('add product')}</span>
                    </Link>
                </div>
            </div>
            {/* ITEMS */}
            <List />
        </div>
        </section>
  );
}

export default Products;
