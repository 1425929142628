import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link, useNavigate, useParams, useNavigationType } from "react-router-dom";
import HashLinkObserver from "react-hash-link";
import { useDebounce } from 'react-use';
import get from 'lodash/get';
import { useAppDispatch } from '../../../app/hooks';
import { selectPending, setPending } from '../projectSlice';
import { createProject, updateProject, getProject } from '../actions';
import { ProductModel } from './ProductDetailsModel';
import { ProductDetailsSectionA } from './ProductDetailsSectionA';
import { ProductDetailsSectionB } from './ProductDetailsSectionB';
import { ProductDetailsSectionC } from './ProductDetailsSectionC';
import { ProductDetailsSectionD } from './ProductDetailsSectionD';
import { ProductDetailsSectionE } from './ProductDetailsSectionE';
import { ProductDetailsSectionF } from './ProductDetailsSectionF';
import { ProductDetailsSectionG } from './ProductDetailsSectionG';
import { ProductDetailsSectionH } from './ProductDetailsSectionH';
import { ProductDetailsSectionI } from './ProductDetailsSectionI';
import { ProductDetailsSectionJ } from './ProductDetailsSectionJ';
import { ProductDetailsSectionK } from './ProductDetailsSectionK';
import { ProductDetailsSectionL } from './ProductDetailsSectionL';
import { ProductDetailsSectionM } from './ProductDetailsSectionM';
import { ProductDetailsSectionN } from './ProductDetailsSectionN';
import { ProductDetailsSectionO } from './ProductDetailsSectionO';
import { ProductDetailsSectionP } from './ProductDetailsSectionP';
import { ProductDetailsSectionQ } from './ProductDetailsSectionQ';
import { Sidebar } from './Sidebar';
import { ProjectCtx } from '../ProjectContext';
import { generatePath } from '../utils';
import {exportPdf} from '../ActionsDropdown';

interface Props {
  view:string;
}

const DEBOUNCE_SAVE_DELAY_MS = 1500;

export const ProductDetails: React.FC<Props> = ({view}:Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectContext = React.useContext(ProjectCtx);
  const id = get(useParams(), 'id','');
  const navigationType = useNavigationType();
  const project = useSelector(selectPending);
  const baseUrl = generatePath(view, id);
  projectContext.baseUrl = baseUrl;

  const [localProject, setLocalProject] = React.useState<PendingProject|Project|null>(null);
  const [defaultImageUrl, setDefaultImageUrl] = React.useState('');
  const [localValidation, setLocalValidation] = React.useState<ValidationResult[]>([]); // Updated project validation from api after save
  const [useAutosave, setUseAutosave] = React.useState(false);

  const getValidationInResponse = ({ payload }:any) => {
    if (payload && Array.isArray(payload.validation)) {
      setLocalValidation(payload.validation);
    }
  }

  // Set localProject based on store project
  React.useEffect(() => {
    // After saving a pending project the response will contain the id of the new project
    if (project !== null && localProject !== null && typeof project.id !== 'undefined' && typeof localProject.id === 'undefined') {
      setLocalProject({
        ...localProject,
        id: project.id,
        prescriptionImageUrl: project.prescriptionImageUrl,
      });
      return;
    }
    if (localProject !== null) { return; }
    if (project !== null) {
      setLocalProject({...project});
      setLocalValidation(project.validation || []);
      setDefaultImageUrl(project.product && project.product.imageUrl ? project.product.imageUrl : '');
    }
  }, [project, localProject]);

  // Go to the start of the add project 'wizard' if we do not come from another
  // route (eg: after browser refresh)
  React.useEffect(() => {
    // @TODO.high check after upgrade
    if (navigationType === "REPLACE") {
      // If the app has no previous route in the "edit"
      // fetch the project details.
      if (view === 'edit') {
        if (typeof id !== 'string') { return; }
        dispatch(getProject(id));
      } else {
        console.log('Go back to the start of the flow');
        navigate(baseUrl, {replace:true});
      }
    }
    // @TODO.high check after upgrade
    if ((navigationType === "PUSH" || navigationType === "POP" ) && view === 'edit' && typeof id === 'string') {
      dispatch(getProject(id));
    }
  }, [navigate, baseUrl, dispatch, id, view, navigationType]);

  const removeProduct = () => {
    if (!localProject) { return; }
    const data = {
      ...localProject,
      product: null,
      imageUrl: '',
      imageBase64: null,
    };
    setLocalProject(data);
    dispatch(setPending(data));
    setDefaultImageUrl('');
    setUseAutosave(true);
  }

  const onSave = React.useCallback(async () => {
    if (!localProject) { return; }
    dispatch(setPending(localProject));
    // After the first post/createProject the pending project will get the id
    // from the API response. Use updateProject for pending project with id.
    if (view === 'add' && typeof localProject.id === 'undefined') {
      return dispatch(createProject({
        project: localProject,
      }))
      .then(getValidationInResponse);
    } else {
      return dispatch(updateProject({ project: localProject }))
      .then(getValidationInResponse);
    }
  }, [dispatch, localProject, view]);

  // Update localProject on form update
  const onValueChange = React.useCallback((name, value) => {
    if (!localProject) { return; }
    const data = {
      ...localProject,
      [name]: value,
    };
    setLocalProject(data);
    dispatch(setPending(data));
    setUseAutosave(true);
  }, [localProject, dispatch]);

  // Update localProject on form update
  const onPropValueChange = React.useCallback((name, value) => {
    if (!localProject) { return; }
    const data = {
      ...localProject,
      properties: {
        ...localProject.properties,
        [name]: value,
      },
    };
    setLocalProject(data);
    dispatch(setPending(data));
    setUseAutosave(true);
  }, [localProject, dispatch]);

  // Update localProject on form update
  const onChange = React.useCallback((evt) => {
    const target = evt.target;
    onValueChange(target.name, target.type === 'checkbox' ? '' + target.checked : target.value);
  }, [onValueChange]);

  // Update localProject on form update
  const onPropertyChange = React.useCallback((evt) => {
    const target = evt.target;
    onPropValueChange(target.name, target.type === 'checkbox' ? '' + target.checked : target.value);

  }, [onPropValueChange]);

  const onPropertyReset = React.useCallback((name:string) => {
    // currently onPropertyReset is only used for 'imageUrl'
    if (name.indexOf('imageUrl') === -1) { return; }
    onPropValueChange(name, '');
  }, [onPropValueChange]);

  useDebounce(
    () => {
      if (!useAutosave || !localProject) { return; }
      // Wait for new projects to be saves
      if (view === 'add' && typeof localProject.id === 'undefined') { return; }
      onSave();
  }, DEBOUNCE_SAVE_DELAY_MS, [localProject, useAutosave]);

  const onExportPdf = React.useCallback(async () => {
    if (project) {
      await onSave();
      exportPdf(project);
    }
  }, [project, onSave]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
          <div className="o-container">
              <div className={`print:flex`}>
              <img src={"/images/logo.svg"} alt={"Reyskens orthopedie"} className={`hidden print:block`} />
                  <Link to="/projects" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
                  {project && <span className="ml-4 font-bold">{project.firstName} {project.lastName}</span>}
                  {project && <span className="ml-2 text-gray-400">{project.leestnr}</span>}
              </div>
              <div>
                <button
                    className="bg-gray-200 pl-6 pr-6 text-gray rounded-full p-3 hover:bg-gray-400"
                    type="button"
                    disabled={project && typeof project.id !== 'undefined'? false : true}
                    onClick={onExportPdf}
                >
                  <i className={"fa fa-download"} />
                  <span>
                    <Trans i18nKey="download as pdf" />
                  </span>
                </button>
                <button
                    className="o-button ml-4"
                    type="button"
                    onClick={onSave}
                >
                  <i className={"fa fa-save"} />
                  <span>
                    <Trans i18nKey="save" />
                  </span>
                </button>
              </div>
          </div>
      </div>
      <div className="o-page__main">
        <div className="o-container">
          <Sidebar validation={localValidation} />
          {/* DETAIL */}
          <div className="relative md:ml-64 print:ml-0">

            {(project && project.urgent) && (
              <div className="bg-red-600 font-bold text-white p-2 text-xl hidden print:block">
                <Trans i18nKey="urgent message" />
              </div>
            )}
            <div className={`print:bg-red-400`}>
            { localProject && (
              <ProductModel
                project={localProject}
                onRemove={removeProduct}
                onChange={onChange}
                onPropertyChange={onPropertyChange}
                onPropertyValueChange={onPropValueChange}
                properties={localProject?.properties}
                view={view}
                defaultImageUrl={defaultImageUrl}
                resetFn={onPropertyReset}
              />
            )}
            </div>
            <div className={`print:grid print:gap-4 print:grid-cols-2`}>
              {/** SCHACHTHOOGTE */}
              <ProductDetailsSectionA properties={localProject?.properties} onChange={onPropertyChange} />
              {/** SLUITING */}
              <ProductDetailsSectionB
                properties={localProject?.properties}
                onChange={onPropertyChange}
                defaultImageUrl={defaultImageUrl}
                resetFn={onPropertyReset}
              />
            </div>
            <div className={`print:grid print:gap-4 print:grid-cols-2`}>
              {/** VOERING */}
              <ProductDetailsSectionC properties={localProject?.properties} onChange={onPropertyChange} />
              {/** CONTREFORT */}
              <ProductDetailsSectionD properties={localProject?.properties} onChange={onPropertyChange} />
            </div>

            <div className={`print:grid print:gap-4 print:grid-cols-3`}>
              {/** TONG BEVESTIGING */}
              <ProductDetailsSectionQ properties={localProject?.properties} onChange={onPropertyChange} />
              {/** TONG POLSTER */}
              <ProductDetailsSectionP properties={localProject?.properties} onChange={onPropertyChange} />
              {/** BOORD POLSTER */}
              <ProductDetailsSectionO
                properties={localProject?.properties}
                onChange={onPropertyChange}
                defaultImageUrl={defaultImageUrl}
                resetFn={onPropertyReset}
              />
            </div>
            {/** ENKEL POLSTER */}
            <ProductDetailsSectionE properties={localProject?.properties} onChange={onPropertyChange} />
            {/** HAKOVERTREK */}
            <ProductDetailsSectionF properties={localProject?.properties} onChange={onPropertyChange} />
            {/** ONDERWERK MAAKWIJZE  */}
            <ProductDetailsSectionG
              properties={localProject?.properties}
              onChange={onPropertyChange}
              defaultImageUrl={defaultImageUrl}
              resetFn={onPropertyReset}
            />
            {/** RANDTYPE  */}
            <ProductDetailsSectionH
              properties={localProject?.properties}
              onChange={onPropertyChange}
              defaultImageUrl={defaultImageUrl}
              resetFn={onPropertyReset}
              />
            {/** HAK  */}
            <ProductDetailsSectionI
              properties={localProject?.properties}
              onChange={onPropertyChange}
              defaultImageUrl={defaultImageUrl}
              resetFn={onPropertyReset}
              />
            {/** KLEUR ONDERWERK  */}
            <ProductDetailsSectionJ
              properties={localProject?.properties}
              onChange={onPropertyChange}
              defaultImageUrl={defaultImageUrl}
              resetFn={onPropertyReset}
              />
            {/** NEUS  */}
            <ProductDetailsSectionK properties={localProject?.properties} onChange={onPropertyChange} />
            {/** SCHORING  */}
            <ProductDetailsSectionL properties={localProject?.properties} onChange={onPropertyChange} />
            {/** ZOOLMATERIAAL  */}
            <ProductDetailsSectionM
              properties={localProject?.properties}
              onChange={onPropertyChange}
              defaultImageUrl={defaultImageUrl}
              resetFn={onPropertyReset}
              />
            {/** LEVERANCIER  */}
            <ProductDetailsSectionN
              project={localProject}
              onChange={onValueChange}
              properties={localProject?.properties}
              onPropertyChange={onPropertyChange}
            />
          </div>
        </div>
      </div>
      <HashLinkObserver />
    </>
  )
};

export default ProductDetails;
