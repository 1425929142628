import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';
import { ActionsDropdown } from './ActionsDropdown';
import { Avatar } from '../../components/Avatar';

export interface Props {
  user: User;
}

export const Row: React.FC<Props> = (props: Props) => {
  const { user } = props;

  return (
    <tr className="o-table__row">
      <td className="col-span-2">
        <Link to={`/users/edit/${user.id}`}>
          <Avatar url={user.imageUrl} name={user.email} size={48} />
          {user.firstName} {user.lastName}
        </Link>
      </td>
      <td>
        <Trans i18nKey={`ROLE.${user.role}`} />
      </td>
      <td className="text-right">
        <ActionsDropdown user={user} />
      </td>
    </tr>
  )
};

export default Row;
