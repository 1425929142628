import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Selector as SupplierSelector } from '../../suppliers/Selector';
import ProductDetailComment from './ProductDetailComment';

interface Props {
  project: Project | PendingProject | null;
  properties: ProjectProperties | undefined;
  onChange: Function;
  onPropertyChange: React.FormEventHandler;
}

const sectionName = 'Leverancier';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionN: React.FC<Props> = React.memo(({
  onPropertyChange,
  onChange,
  properties,
  project,
}:Props) => {
  const { t } = useTranslation();
  if (!properties || !project) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="mt-6">
        {/** INPUT */}
        <div className="m-2">
          <label className="block">{t("Keuze")}</label>
          <SupplierSelector
            name="supplier"
            supplier={project && project.supplier}
            onSupplierChange={onChange}
            className="o-input__text"
          />
        </div>
        {/** INPUT */}
        <div className="m-2">
          <label className="block" htmlFor="leverancier-deadline">{t("Deadline")}</label>
          <input
            name="leverancier-deadline"
            id="leverancier-deadline"
            value={properties["leverancier-deadline"]}
            type="date"
            onChange={onPropertyChange}
            className="o-input__text"
          />
        </div>
        {/** INPUT */}
        <div className="m-2 mt-4">
          <label htmlFor="urgent">
            <input
              name="urgent"
              id="urgent"
              type="checkbox"
              checked={project.urgent}
              onChange={(evt) => onChange(evt.target.name, evt.target.checked)}
              className="mr-4"
            />
            <Trans i18nKey="urgent" />
          </label>
        </div>
        {/** INPUT */}
        <ProductDetailComment name="leverancier-comment" value={properties["leverancier-comment"]} onChange={onPropertyChange} />
      </div>
    </div>
  );
});

export default ProductDetailsSectionN;
