import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
  defaultImageUrl: string;
  resetFn: Function;
}

const valuesA:ValueItem[] = ([
  {
    value: 'Blok hak',
    img: '/images/hak-a.png',
  },
  {
    value: 'Volle hak',
      img: '/images/hak-b.png',
  },
  {
    value: 'Slee hak',
      img: '/images/hak-c.png',
  },
  {
    value: 'Als foto',
    selectProductImage: 'hak-imageUrl',
  },
]);

const valuesB:ValueItem[] = ([
  {
    value: 'Afronden',
  },
  {
    value: 'Fel afronden',
  },
  {
    value: 'Geen afronding',
  },
]);

const sectionName = 'Hak';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionI: React.FC<Props> = React.memo(({onChange, properties, defaultImageUrl, resetFn}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }

  const hasPaperProperties = properties["hak-papier-in-hak-links"] === "true" || properties["hak-papier-in-hak-rechts"] === "true";
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="md:grid print:grid grid-cols-2">
        <div>
          {/** INPUT */}
          { valuesA.map((c, idx) => (
            <div className="mt-4" key={c.value} >
              <div className={""}>
                <RadioButton
                  name="hak"
                  id={`${sectionId}-0-${idx}`}
                  checked={properties['hak'] === c.value}
                  onChange={onChange}
                  properties={properties}
                  defaultImageUrl={defaultImageUrl}
                  resetFn={resetFn}
                  {...c}
                />
                {c.img && <img src={c.img} style={{"maxWidth": "64px"}} alt={c.value} />}
              </div>
            </div>
          ))}
        </div>
        <div>
          {/** --- */}
          <div className={ properties["hak-afwijkende-kanten"] === "true" ? 'grid grid-cols-2' : 'grid grid-cols-1' }>
            {/** INPUT */}
            {/** Only show "hak-hoogte-links" if "hak-afwijkende-kanten" is true */}
            {properties["hak-afwijkende-kanten"] === "true" && (
              <div className="m-2">
                <label className="font-bold"
                  htmlFor={`${sectionId}-1`}
                >{t('Hoogte Links')} *</label>
                <input
                  name="hak-hoogte-links"
                  value={properties["hak-hoogte-links"] || ''}
                  id={`${sectionId}-1`}
                  type="number"
                  className="o-input__text"
                  onChange={onChange}
                />
                <span>
                  mm
                </span>
              </div>
            )}
            {/** INPUT */}
            <div className="m-2">
              <label className="font-bold"
                htmlFor={`${sectionId}-2`}
              >{t(properties["hak-afwijkende-kanten"] === "true" ? 'Hoogte Rechts' : 'Hoogte')} *</label>
              <input
                name="hak-hoogte-rechts"
                value={properties["hak-hoogte-rechts"] || ''}
                id={`${sectionId}-2`}
                type="number"
                className="o-input__text"
                onChange={onChange}
              />
              <span>
                mm
              </span>
            </div>
            {/** INPUT */}
            <div className={`${properties["hak-afwijkende-kanten"] === "true" ? '' : 'print:hidden'}`}>
              <label
                htmlFor={`${sectionId}-3`}
              >
              <input
                name="hak-afwijkende-kanten"
                id={`${sectionId}-3`}
                checked={properties["hak-afwijkende-kanten"] === "true"}
                type="checkbox"
                onChange={onChange}
                className="mr-2"
              />
              {t('Afwijkende kanten')}</label>
            </div>
          </div>
          {/** --- */}
          <div>
            {/** INPUT */}
            <div className={`${properties["hak-buffer"] === '' ? 'print:hidden' : '' }`}>
              <label
                htmlFor={`${sectionId}-4`}
              >{t('Buffer')}</label>
              <input
                name="hak-buffer"
                id={`${sectionId}-4`}
                value={properties["hak-buffer"]}
                type="number"
                className="o-input__text"
                onChange={onChange}
              />
              <span>
                mm
              </span>
            </div>
            {/** INPUT */}
            <div className={`${properties["hak-ondertrekken"] === '' ? 'print:hidden' : '' }`}>
              <label
                htmlFor={`${sectionId}-5`}
              >{t('Ondertrekken')}</label>
              <input
                name="hak-ondertrekken"
                id={`${sectionId}-5`}
                value={properties["hak-ondertrekken"]}
                type="number"
                className="o-input__text"
                onChange={onChange}
              />
              <span>
                mm
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-6 mb-6 print:hidden"/>
      <div>
        {/** INPUT */}
        { valuesB.map((c, idx) => (
          <div key={c.value} >
            <div>
              <RadioButton
                name="hak-afronding"
                id={`${sectionId}-6-${idx}`}
                checked={properties['hak-afronding'] === c.value}
                onChange={onChange}
                properties={properties}
                {...c}
              />
            </div>
          </div>
        ))}
      </div>
      <div className={`${!hasPaperProperties ? 'print:hidden' : ''}`}>
      <hr className="mt-6 mb-6 print:hidden"/>
      <div>
        <div className="grid grid-cols-3">
          <div>
            <span>{t("Papier in hak")}</span>
          </div>
          {/** INPUT */}
          <div>
            <label
              htmlFor={`${sectionId}-7`}
            >
            <input
              name="hak-papier-in-hak-links"
              id={`${sectionId}-7`}
              checked={properties["hak-papier-in-hak-links"] === "true"}
              type="checkbox"
              onChange={onChange}
              className="mr-2"
            />
            {t('Links')}</label>
          </div>
          {/** INPUT */}
          <div>
            <label
              htmlFor={`${sectionId}-8`}
            >
            <input
              name="hak-papier-in-hak-rechts"
              id={`${sectionId}-8`}
              checked={properties["hak-papier-in-hak-rechts"] === "true"}
              type="checkbox"
              onChange={onChange}
              className="mr-2"
            />
            {t('Rechts')}</label>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
});

export default ProductDetailsSectionI;
