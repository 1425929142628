import {request} from '../../api';
import config from '../../config';
import { SignInCredentials } from './authenticationSlice';

function storageSet(key:string, value:string) {
  window.localStorage.setItem(key, value);
}

function storageGet(key:string) {
  return window.localStorage.getItem(key);
}

function storageRemove(key:string, value?:string) {
  if (value && window.localStorage.getItem(key) !== value) {
    return;
  }
  return window.localStorage.removeItem(key);
}

const baseUrl = `${config.baseUrl}/auth`;
const service = () => {
  return {
    login: (user: SignInCredentials) => request(`${baseUrl}/login`, 'post', user),
    logout: () => request(`${baseUrl}/logout`, 'post'),
    profiles: () => request(`${baseUrl}/profiles`, 'get'),
    me: () => request(`${baseUrl}/me`, 'get'),
    setAccessToken: (accessToken:string) => storageSet('accessToken', accessToken),
    getAccessToken: () => storageGet('accessToken'),
    removeAccessToken: (accessToken?:string) => storageRemove('accessToken', accessToken),
    updateUserPassword: (id: string, payload:UpdateUserPassword) => request(`${baseUrl}/user/${id}/password`, 'patch', payload),
    resetUserPassword: (id: string, payload:ResetUserPassword) => request(`${baseUrl}/user/${id}/reset`, 'post', payload),
  };
};

export default service;
