import {useEffect, useState, useCallback, default as React} from 'react';
import { useParams } from "react-router-dom";
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { selectUser, selectFeedback} from './userSlice';
import { getUser, updateUser } from './actions';
import { EditUserForm } from './components/EditUserForm';
import { Feedback } from '../../components/Feedback';
import ChangeUserPassword from './components/ChangeUserPassword';

export const EditUser: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [localUser, setLocalUser] = useState<User|null>(null);
  const user = useSelector(selectUser);
  const feedback = useSelector(selectFeedback);

  // Fetch user
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getUser(id));
  }, [dispatch, id]);

  // Copy user into local data for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    if (user !== null && localUser === null && id === `${user.id}`) {
      setLocalUser({...user});
    }
  }, [localUser, user, id]);

  // Form edits to local data
  const onChange = useCallback((evt) => {
    if (!localUser) { return; }
    const target = evt.target;
    let value = '';
    switch(target.type) {
      case 'radiobutton':
        value = target.checked;
        break;
      case 'checkbox':
        value = target.checked ? target.value : null;
        break;
      default:
        value = target.value;
    }
    setLocalUser({...localUser, [target.name]: value });
  }, [localUser]);

  const onSubmit = useCallback((file:File|null) => {
    if (!localUser || localUser.id === null) { return false; }
    dispatch(updateUser({
      user: {
        ...localUser,
        role: localUser.role ? localUser.role : 'USER',
      },
      image: file,
    }));
  }, [dispatch, localUser]);

  const onRemoveImage = useCallback(() => {
    if (!localUser) { return; }
    setLocalUser({...localUser, imageUrl: '' });
  }, [localUser]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to={"/users"} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4"><Trans i18nKey="edit employee" /> </span>
          </div>
        </div>
      </div>

      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
        {localUser && (
          <EditUserForm
            user={localUser}
            onChange={onChange}
            onSubmit={onSubmit}
            onRemoveImage={onRemoveImage}
          />
        )}
        <Feedback feedback={feedback} />
        {localUser && (
          <ChangeUserPassword user={localUser} />
        )}
        </div>
      </div>
    </>
  )
};

export default EditUser;
