import * as React from 'react';
import { Trans } from 'react-i18next';


interface ItemProps {
  onChange: Function;
  item: string;
  index: number;
  name: string;
  onRemove: Function;
};

const MAX_SUPPLIER_MAILS = 5;

const Item: React.FC<ItemProps> = (props:ItemProps) => {
  return (
    <div className="mb-3">
      <div>
      <label className="mr-2">
        <Trans i18nKey="email" />
      </label>
      <input
        name="emails"
        type="email"
        className="o-input__text"
        onChange={evt => props.onChange(props.name, evt.target.value, props.index)}
        value={props.item}
        required={props.index === 0}
      />
      </div>
      <div>
        {props.index > 0 && (
          <button
              className="o-action__icon"
              type="button"
              onClick={() => props.onRemove(props.name, props.index) }>
            <i className="fas fa-trash"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export interface EmailListProps {
  items: string[];
  onChange: Function;
  onAdd: Function;
  onRemove: Function;
  name: string;
};

export const EmailList: React.FC<EmailListProps> = (props:EmailListProps) => {
  return (
    <>
    {props.items.map((item, idx) => (
      <Item key={idx} onChange={props.onChange} onRemove={props.onRemove} item={item} index={idx} name={props.name} />
    ))}
    {(props.items.length < MAX_SUPPLIER_MAILS) && (
      <button
        name={props.name}
        type="button"
        className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300"
        onClick={() => { props.onAdd(props.name) }}
      >
        <i className="fa fa-plus"></i>
        <span>
          <Trans i18nKey="add email" />
        </span>
      </button>
    )}
    </>
  );
};
