import {useState, useCallback, default as React} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { selectFeedback } from './productsSlice';
import { AddProductForm } from './components/AddProductForm';
import { createProduct } from './actions';
import { Feedback } from '../../components/Feedback';

interface Props {
  backToUrl?: string,
}

export const AddProduct: React.FC<Props> = ({ backToUrl }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const feedback = useSelector(selectFeedback);

  const [product, setProduct] = useState<PendingProduct>({
    name: '',
    category: '',
    imageUrl: '',
  });

  const onChange = useCallback(({ target }) => {
    setProduct({...product, [target.name]: target.value });
  }, [product]);

  const onSubmit = useCallback((file:File|null) => {
    dispatch(createProduct({ product, image: file }));
  }, [dispatch, product]);

  const onRemoveImage = useCallback(() => {
    if (!product) { return; }
    setProduct({...product, imageUrl: '' });
  }, [product]);

  return (
    <>
      {/* HEADER */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to={backToUrl ? backToUrl : "/products"} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">{t('add product')}</span>
          </div>
        </div>
      </div>
      {/* MAIN */}
      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
        <AddProductForm
          product={product}
          onChange={onChange}
          onSubmit={onSubmit}
          onRemoveImage={onRemoveImage}
        />
        <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default AddProduct;
