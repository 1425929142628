import * as React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { Navbar } from '../../components/NavBar';
import { AddSwatch } from "./Add";
import { EditSwatch } from "./Edit";
import { RemoveSwatch } from "./Remove";
import { Swatches } from "./Swatches";

export const SwatchesRoutes: React.FC = () =>  {
  return (
    <Routes>
      {/* SWATCHES (product stalen)*/}
      <Route
        path="/add"
        element={ <AddSwatch /> }
      />
      <Route
        path="/edit/:id"
        element={ <EditSwatch /> }
        />
      <Route
        path="/remove/:id"
        element={ <RemoveSwatch /> }
      />
      <Route
        path="/:category"
        element={
          <>
            <Navbar />
            <Swatches />
          </>
        }
      />
      <Route
        path="/"
        element={
          <>
            <Navbar />
            <Swatches />
          </>
        }
      />
    </Routes>
  );
}

export default SwatchesRoutes;
