import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams, useNavigate } from "react-router-dom";
import { Trans } from 'react-i18next';
import { ImageListType } from "react-images-uploading";
import { selectProject, setProject } from './projectSlice';
import { getProject, updateProject } from './actions';
import { CustomerForm } from './components/CustomerForm';
import { ValidationSummary } from './components/ValidationSummary';
import { ProjectCtx } from './ProjectContext';
import { useAppDispatch } from '../../app/hooks';

interface Props {
  view:string;
}

export const EditProject: React.FC<Props> = ({ view }:Props) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const projectContext = React.useContext(ProjectCtx);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [images, setImages] = React.useState<ImageListType>([]);

  const project = useSelector(selectProject);

  const [localProject, setLocalProject] = useState<Project|null>(null);
  projectContext.baseUrl = `/projects/${view}`;

  // Fetch project
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getProject(id));
  }, [dispatch, id]);

  // Copy project into local data for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    if (project && project !== null && localProject === null && id === `${project.id}`) {
      setLocalProject({
        ...project,
      });
    }
  }, [localProject, project, id]);

  // Update localProject on form update
  const onChange = useCallback((evt) => {
    if (!localProject) { return; }
    const target = evt.target;
    const value = target.type === 'radiobutton' ? target.checked : target.value;
    const name = target.name;
    setLocalProject({...localProject, [name]: value });
  }, [localProject]);

  const onSubmit = useCallback((file?:File|null) => {
    if (!localProject || typeof id !== 'string') { return; }
    dispatch(setProject(localProject));
    dispatch(updateProject({ project: localProject, image: file }))
    .then(({ payload }) => {
      dispatch(setProject(payload)); // use the saved project result in start for the project details
      navigate(`/projects/edit/${id}/project-details`);
    });
  }, [dispatch, localProject, id, navigate]);

  const onSave = useCallback(() => {
    if (formRef && formRef.current && formRef.current.reportValidity()) {
      onSubmit((images[0] && images[0].file) || null);
    }
  }, [formRef, images, onSubmit]);

  const onRemoveImage = useCallback(() => {
    if (!localProject) { return; }
    setLocalProject({...localProject, prescriptionImageUrl: '' });
  }, [localProject]);

  const onImageChange = useCallback((imageList: ImageListType) => {
    setImages(imageList);
  }, []);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to="/projects" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">
              <Trans i18nKey="edit project" />
            </span>
          </div>
            <button
              className="o-button ml-4  "
              type="button"
              onClick={onSave}
            >
              <Trans i18nKey="save and continue" />
            </button>
        </div>
      </div>
      {/* FORM */}
      <div className="o-page__main">
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
          <CustomerForm
            ref={formRef}
            project={localProject}
            onChange={onChange}
            onRemoveImage={onRemoveImage}
            onImageChange={onImageChange}
            onSubmit={onSubmit}
            submitLabel="model details" />
          <ValidationSummary project={project} />
        </div>
      </div>
    </>
  )
};

export default EditProject;
