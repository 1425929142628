import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import { ImageListType } from "react-images-uploading";
import { createPending, selectPending, setPending, clearPending } from './projectSlice';
import { duplicateProject, createProject } from './actions';
import { selectUserProfile } from '../authentication/authenticationSlice';
import CustomerForm from './components/CustomerForm';
import { ProjectCtx } from './ProjectContext';
import { useAppDispatch } from '../../app/hooks';
import { generatePath } from './utils';

interface Props {
  view:string;
}

export const AddProject: React.FC<Props> = ({ view }:Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const locationState = location.state as { duplicate: string|null };
  const projectContext = React.useContext(ProjectCtx);
  const formRef = React.useRef<HTMLFormElement>(null);
  const [images, setImages] = React.useState<ImageListType>([]);
  const userProfile = useSelector(selectUserProfile);
  const project = useSelector(selectPending);
  const [localProject, setLocalProject] = useState<PendingProject|Project|null>(null);
  projectContext.baseUrl = generatePath(view);

  //
  useEffect(() => {
    dispatch(clearPending());
    if (locationState && locationState.duplicate) {
      dispatch(duplicateProject(locationState.duplicate));
    }
  }, [dispatch, navigate, locationState]);

  // Set localProject based on store project
  useEffect(() => {
    if (localProject !== null) { return }
    if (locationState && locationState.duplicate) {
      if (project !== null) {
        setLocalProject({...project});
      }
    } else if (project === null) {
      dispatch(createPending());
    } else {
      setLocalProject({...project});
    }
  }, [project, localProject, dispatch, navigate, locationState]);

  useEffect(() => {
    if (localProject && !localProject.user && userProfile) {
      setLocalProject({
        ...localProject,
        user: {...userProfile},
      });
    }
  }, [userProfile, localProject]);

  // Update localProject on form update
  const onChange = useCallback((evt) => {
    if (!localProject) { return; }
    const target = evt.target;
    const value = target.type === 'radiobutton' ? target.checked : target.value;
    const name = target.name;
    setLocalProject({...localProject, [name]: value });
  }, [localProject]);

  const onSubmit = useCallback((file?:File|null, gotoDetails=true) => {
    if (!localProject) { return; }
    dispatch(setPending(localProject));
    dispatch(createProject({ project: localProject, image: file }))
    .then(() => {
      if (gotoDetails) {
        navigate('/projects/add/select-product');
      } else {
        navigate('/projects');
      }
    });
  }, [dispatch, localProject, navigate]);

  const onSave = useCallback((gotoDetails=false) => {
    if (formRef && formRef.current && formRef.current.reportValidity()) {
      onSubmit((images[0] && images[0].file) || null, gotoDetails);
    }
  }, [formRef, onSubmit, images]);

  const onRemoveImage = useCallback(() => {
    if (!localProject) { return; }
    setLocalProject({...localProject, prescriptionImageUrl: '' });
  }, [localProject]);

  const onImageChange = useCallback((imageList: ImageListType) => {
    setImages(imageList);
  }, []);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
          <div className="o-container">
              <div>
                <Link to="/projects" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
                  <span className="ml-4">{t('new project')}</span>
              </div>

              <div>
                <button
                  className="o-button"
                  type="button"
                  onClick={() => onSave(false)}
                >
                  <i className="fa fa-save"></i>
                  <span>
                    <Trans i18nKey="save" />
                  </span>
                </button>
                <button
                  className="o-button ml-4"
                  type="button"
                  onClick={onSave}
                >
                  <i className="fa fa-forward"></i>
                  <span>
                    <Trans i18nKey="save and continue" />
                  </span>
                </button>
              </div>

          </div>
      </div>

      {/* FORM */}
      <div className="o-page__main">
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
          <CustomerForm
            ref={formRef}
            project={localProject}
            onChange={onChange}
            onRemoveImage={onRemoveImage}
            onSubmit={onSubmit}
            onImageChange={onImageChange}
            submitLabel={(localProject && localProject.product) ? "model details" : "select model"}
          />
        </div>
      </div>
    </>
  )
};

export default AddProject;
