import { request, upload } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/user`;

const service = () => {
  return {
    findAll: (pageInfo:PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    getUser: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'get'),
    updateUser: (user:User) => request(`${baseUrl}/${user.id}`, 'patch', user),
    createUser: (user:PendingUser) => request(`${baseUrl}`, 'post', user),
    removeUser: ({ id }: { id: string }) => request(`${baseUrl}/${id}`, 'delete'),
    upload: (id:string, file:any, onUploadProgress:any) => upload(`${baseUrl}/${id}/avatar`, file, onUploadProgress),
    removeImage: (id:string) => request(`${baseUrl}/${id}/avatar`, 'delete'),
  };
};

export default service;
