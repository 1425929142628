import {useEffect, useState, useCallback, default as React} from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectLeather, selectFeedback } from './leatherSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getLeather, updateLeather} from './actions';
import { EditForm } from './components/EditForm';
import { Feedback } from '../../components/Feedback';

export const EditLeather: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [localLeather, setLocalLeather] = useState<Leather|null>(null);
  const leather = useSelector(selectLeather);
  const feedback = useSelector(selectFeedback);

  // Fetch leather
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getLeather(id));
  }, [dispatch, id]);

  // Copy leather into local data for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    // Copy backend leather into local leather
    if (leather && leather !== null && localLeather === null && id === `${leather.id}`) {
      const data = {...leather};
      // remove null values
      data.code = leather.code || '';
      setLocalLeather({...data});
    }
  }, [localLeather, leather, id]);

  // Form edits to local data
  const onChange = useCallback(({ target }) => {
    if (!localLeather) { return; }
    setLocalLeather({...localLeather, [target.name]: target.value });
  }, [localLeather]);

  // Submit
  const onSubmit = useCallback((evt) => {
    evt.preventDefault();
    if (!localLeather || localLeather.id === null) { return false; }
    dispatch(updateLeather({leather: {...localLeather}}));
  }, [dispatch, localLeather]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to="/leathers" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">{t('edit leather')}</span>
          </div>
        </div>
      </div>
      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
          {localLeather && (<EditForm
            leather={localLeather}
            onChange={onChange}
            onSubmit={onSubmit}
          />)}
          <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default EditLeather;
