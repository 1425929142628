import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import uniqueId from 'lodash/uniqueId'
import { defaultSearchOptions, selectSearchFeedback } from '../projectSlice';
import { Selector as SupplierSelector } from '../../suppliers/Selector';

interface Props {
  onFilterChange: Function;
}

interface InputProps extends React.HTMLProps<HTMLInputElement> {
  id?: string;
  name?: string;
  value: string;
  label?: string; // translation key
}

export const Input: React.FC<InputProps> = (props:InputProps) => {
  const id= props.id || uniqueId('input-');
  return (
    <>
      {props.label &&
        <label className="block" htmlFor={id}>
          <Trans i18nKey={props.label}/>
        </label>
      }
      <input
        {...props}
        type={props.type || "text"}
        id={props.id || id}
        name={props.name || id}
        className={`${props.className ? props.className : 'o-input__text '}`}
      />
    </>
  );
};

interface CheckboxProps extends InputProps {
  label: string; // translation key
}

export const Checkbox: React.FC<CheckboxProps> = (props:CheckboxProps) => {
  const id= props.id || uniqueId('input-');
  return (
    <label className="block" htmlFor={id}>
      <input
        {...props}
        type="checkbox"
        id={props.id || id}
        name={props.name || id}
        className={`${props.className ? props.className : 'o-input__checkbox mr-2'}`}
      />
      <Trans i18nKey={props.label}/>
    </label>
  );
};

export const Filter: React.FC<Props> = ({onFilterChange}:Props) => {
  const dispatch = useDispatch();
  const feedback = useSelector(selectSearchFeedback);

  const [fromDate, setFromDate] = React.useState(defaultSearchOptions.fromDate);
  const [toDate, setToDate] = React.useState(defaultSearchOptions.toDate);
  const [validOnly, setValidOnly] = React.useState(defaultSearchOptions.validOnly);
  const [unsendOnly, setUnsendOnly] = React.useState(defaultSearchOptions.unsendOnly);
  const [supplier, setSupplier] = React.useState(defaultSearchOptions.supplier);

  const onSubmit = React.useCallback((evt) => {
    if (evt) {
      evt.preventDefault();
    }
    onFilterChange({
      fromDate,
      toDate,
      validOnly,
      unsendOnly,
      supplier,
    });
  }, [fromDate, toDate, validOnly, unsendOnly, supplier, onFilterChange]);

  React.useEffect(() => {
    onFilterChange({
      fromDate,
      toDate,
      validOnly,
      unsendOnly,
      supplier,
    });
  }, [dispatch, fromDate, toDate, validOnly, unsendOnly, supplier, onFilterChange]);

  return (
    <form onSubmit={onSubmit}>
      <div className="">
        <div className="flex gap-4">


            {/** INPUT */}
            <div className="m-2">
              <Input
                name="fromDate"
                type="date"
                value={fromDate || ''}
                label="from date"
                onChange={(evt) => setFromDate((evt.target as HTMLInputElement).value)}
              />
            </div>
            {/** INPUT */}
            <div className="m-2">
              <Input
                name="toDate"
                type="date"
                value={toDate || ''}
                label="to date"
                onChange={(evt) => setToDate((evt.target as HTMLInputElement).value)}
              />
            </div>
          </div>

          <div className="flex">
            {/** INPUT */}
            <div className="m-2">
              <Checkbox
                name="unsendOnly"
                value="true"
                checked={unsendOnly}
                label="unsendProjects"
                onChange={(evt) => setUnsendOnly((evt.target as HTMLInputElement).checked)}
              />
            </div>
          </div>

          <div className="flex">
            {/** INPUT */}
            <div className="m-2">
              <Checkbox
                name="validOnly"
                value="true"
                checked={validOnly}
                label="validProjectsOnly"
                onChange={(evt) => setValidOnly((evt.target as HTMLInputElement).checked)}
              />
            </div>
          </div>

          <div className="flex">
            {/** INPUT */}
            <div className="m-2">
              <label className="block">
                <Trans i18nKey="Leverancier" />
              </label>
              <SupplierSelector
                className="o-input__text"
                name="supplier"
                supplier={supplier || null}
                onSupplierChange={(_name:string, supplier:Supplier) => setSupplier(supplier)}
                firstOptionLabel="all suppliers"
              />
            </div>


          <button className="hidden" type="submit">
            <Trans i18nKey="search"/>
          </button>

        </div>

        <div className="flex-initial">
          <button
            className="bg-gray-200 text-gray-800 px-3 py-2 rounded-full hover:bg-gray-600 hover:text-gray-100 mr-1 ml-1"
            type="submit">
            <i className="fas fa-search"></i>
          </button>
          {(feedback && feedback.isWaiting) && (
            <span className="z-10 h-full leading-snug font-normal absolute text-center text-gray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 right-3 pr-3 py-3">
              <i className="fas fa-circle-notch animate-spin"></i>
            </span>
          )}
        </div>
      </div>
    </form>
  )
}

export default Filter;
