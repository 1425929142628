import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import { selectFeedback, selectSwatch, displayName } from './swatchSlice';
import { Feedback } from '../../components/Feedback';
import { removeSwatch, getSwatch } from './actions';
import { Button } from '../../components/Button';

export const RemoveSwatch: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const feedback = useSelector(selectFeedback);
  const swatch = useSelector(selectSwatch);

  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getSwatch(id));
  }, [dispatch, id]);

  return (
    <>
      <div>
        <Link to="/swatches">{t('back')}</Link>
        <h2>Swatch {displayName(swatch)} verwijderen?</h2>
      </div>
      <div>
        <Link to="/swatches">Terug</Link>
        {typeof id === 'string' && <Button
        onClick={() => {dispatch(removeSwatch(id))}}
        className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
          Verwijderen
        </Button>
        }
        <Feedback feedback={feedback} />
      </div>
    </>
  )
};

export default RemoveSwatch;
