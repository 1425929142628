import { request, upload } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/product`;
const service = () => {
  return {
    findAll: (pageInfo:PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    findOne: (id:string) => request(`${baseUrl}/${id}`, 'get'),
    create: (product:PendingProduct) => request(`${baseUrl}`, 'post', product),
    update: (product:Product) => request(`${baseUrl}/${product.id}`, 'patch', product),
    remove: (id:string) => request(`${baseUrl}/${id}`, 'delete'),
    upload: (id:string, file:any, onUploadProgress:any) => upload(`${baseUrl}/${id}/image`, file, onUploadProgress),
    search: (text:string) => request(`${baseUrl}/search`, 'get', { text }),
  };
};

export default service; 
