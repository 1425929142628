import { ItemSelector } from './ItemSelector';

interface Props {
  imageUrl?: string;
  value: keyof ProjectProperties;
  resetFn?: Function | null;
  className?: string;
}

export const ProductSelector: React.FC<Props> = (props:Props) => (
  <ItemSelector
    item="product"
    {...props}
  />
);

export default ProductSelector;
