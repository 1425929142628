import React from "react";
import { Link, useMatch, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../features/authentication/authenticationSlice';
import { ProfileDropdown } from "./ProfileDropdown";

export interface NavRoute {
  name: string;
  url: string;
  roles: UserRole[]; // User profile should have this role to view the route
}

const routes:NavRoute[] = [
  {
    name: 'Projecten',
    url: '/projects',
    roles: ['USER', 'ADMIN'],
  },
  {
    name: 'Catalogus',
    url:'/products',
    roles: ['USER', 'ADMIN'],
  },
  {
    name: 'Stalen',
    url: '/swatches',
    roles: ['USER', 'ADMIN'],
  },
  {
    name: 'Lederen',
    url: '/leathers',
    roles: ['USER', 'ADMIN'],
  },
  {
    name: 'Leveranciers',
    url: '/suppliers',
    roles: ['USER', 'ADMIN'],
  },
  {
    name: 'Medewerkers',
    url:'/users' ,
    roles: ['ADMIN'],
  },
];

export interface NavbarLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavbarLink: React.FC<NavbarLinkProps> = ({ to, children }: NavbarLinkProps) => {
  const classNames = ["lg:text-gray px-3 py-4 lg:py-2 flex items-center"];
  const match = useMatch({
    path: to,
    end: true,
  });
  if (match !== null) {
    classNames.push('border-b-2');
  }

  return (
    <li className="flex items-center">
      <Link
      className={classNames.join(' ')}
      to={to}>{children}</Link>
    </li>
  )
}

export const Navbar: React.FC = () => {
  const location = useLocation();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const userProfile = useSelector(selectUserProfile);
  const role = userProfile ? userProfile.role : 'USER';
  const validRoutes = routes.filter(c => c.roles.indexOf(role) !== -1);

  // Close popup on route change
  React.useEffect(() => {
    setNavbarOpen(false);
  }, [location]);

  function toggleNavbar() {
    setNavbarOpen(!navbarOpen)
  }

  return (

    <nav className="bg-white w-full flex flex-wrap items-center justify-between navbar-expand-lg z-50 shadow-sm">
      <div className="container mx-auto flex flex-wrap items-center justify-between">

        {/** LOGO **/}
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <Link
            className="text-gray text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            to="/"
          >
            <img src={"/images/logo.svg"} alt={"Reyskens orthopedie"} />
          </Link>
          <button
            className="cursor-pointer text-xl leading-none px-3 py-1  rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={toggleNavbar}
          >
            <i className="text-gray fas fa-bars"></i>
          </button>
        </div>

        <div
          className={
            `lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none ${navbarOpen ? " block rounded shadow-lg" : " hidden"}`
          }
        >
          {/** ROUTES **/}
          <ul className="flex flex-col lg:flex-row list-none mr-auto">
            {validRoutes.map(c => <NavbarLink key={c.url} to={c.url}>{c.name}</NavbarLink>)}
          </ul>
          {/** PROFILE **/}
          {userProfile && (
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <ProfileDropdown />
              </li>
            </ul>
          )}

        </div>
      </div>
    </nav>
  );
}
