import { createSlice, PayloadAction, createAction, nanoid } from '@reduxjs/toolkit'
import { RootState } from '../../app/store';

interface ToastState {
  items: ToastMessage[];
}

const initialState: ToastState = {
  items: [],
}

export const successToast = createAction<string>('toast/successToast');
export const errorToast = createAction<string>('toast/error');

export const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    addToast: (state, action: PayloadAction<ToastMessage>) => {
      state.items = [...state.items, action.payload];
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.items = state.items.filter(c => c.id !== action.payload);
    },
  },
  extraReducers: (builder) => builder
    .addCase(successToast, (state, action) => {
      state.items = [...state.items, {
        id: nanoid(),
        type: 'success',
        message: action.payload,
        delay: 3500,
      }];
    })
    .addCase(errorToast, (state, action) => {
      state.items = [...state.items, {
        id: nanoid(),
        type: 'error',
        message: action.payload,
        delay: 5000,
      }];
    })
})

export const { addToast, removeToast } = toastSlice.actions;
export const selectToasts = (state: RootState) => state.toast.items;

export default toastSlice.reducer;
