import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { selectUserProfile } from '../../features/authentication/authenticationSlice';
import { me } from '../../features/authentication/actions';
import { Avatar } from '../../components/Avatar';
import { Button } from '../../components/Button';
import { PasswordChange } from './components/PasswordChange';
import { setImage, removeImage } from './actions';
import { config } from '../../config';

export interface RowProps {
  label: string;
  value?: string;
}

export const Row: React.FC<RowProps> = ({ label, value }: RowProps) =>
  <div className="relative w-full mb-3">
    <div className="text-gray-700">
      {label}
    </div>
    <div>
      {value}
    </div>
  </div>

export const Profile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [localProfile, setLocalProfile] = React.useState<User|null>(null);
  const profile = useSelector(selectUserProfile);
  const [images, setImages] = React.useState<ImageListType>([]);
  const [changed, setChanged] = React.useState(false);

  // Set localProfile
  React.useEffect(() => {
    if (localProfile !== null) { return; }
    if (profile !== null) {
      setLocalProfile({...profile});
    }
  }, [profile, localProfile]);

  const onImageChange = (
    imageList: ImageListType,
  ) => {
    setImages(imageList);
    setChanged(true);
  };

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    update();
  }

  const update = React.useCallback(() => {
    if (!changed || localProfile === null) { return; }
    if (images.length === 0 && localProfile.imageUrl === '') {
      dispatch(removeImage({
        user: localProfile,
        cb: () => {
          // Trigger user avatar image in the app
          dispatch(me());
        },
      }));
    } else {
      dispatch(setImage({
        user: localProfile,
        image: (images[0] && images[0].file) || null,
        cb: () => {
          // Trigger user avatar image in the app
          dispatch(me());
        },
      }));
    }
    setChanged(false);
  }, [ dispatch, localProfile, images, changed]);

  const onRemoveImage = React.useCallback(() => {
    if (localProfile === null) { return; }
    setImages([]);
    setLocalProfile({
      ...localProfile,
      imageUrl: '',
    });
    setChanged(true);
  }, [ localProfile ]);

  if (!profile) {
    return null;
  }

  const imageUrl = (images[0] && images[0].data_url) || (localProfile && localProfile.imageUrl) || config.placeholderImage;

  return (
    <div className={"o-page__main"}>
      <div className="mx-auto sm:w-2/3 xl:w-1/3">
        <div className={"w-full bg-white py-8 px-12"}>
            <form onSubmit={onSubmit}>
              <div className="mb-3">
                <ImageUploading
                  value={images}
                  onChange={onImageChange}
                  dataURLKey="data_url"
                >
                  {({
                    onImageUpload,
                    onImageRemoveAll,
                    isDragging,
                    dragProps,
                  }) => (
                    // Make sure buttons have type="buttons" (the default type is "submit")
                    <div className="upload__image-wrapper flex">
                      <Avatar
                        url={imageUrl}
                        name={profile.email}
                        onClick={onImageUpload}
                        className="cursor-pointer"
                        size="100"
                      />
                      <div className="flex m-4">
                        <div className="flex-initial">
                          <button
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800"
                            type="button"
                            {...dragProps}
                          >
                            <Trans i18nKey="select photo" />
                          </button>
                        </div>
                        <div className="flex-initial">
                          <button
                            onClick={() => { onImageRemoveAll(); onRemoveImage() }}
                            type="button"
                            className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300">
                            <Trans i18nKey="remove photo" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </ImageUploading>
              </div>

              <Row label="Voornaam" value={profile.firstName} />
              <Row label="Naam" value={profile.lastName} />
              <Row label="Email" value={profile.email} />
              <Row label="Role" value={t(`ROLE.${profile.role}`)} />
              <Button
                className={`${changed ? 'bg-gray-500 text-white hover:bg-gray-800' : 'bg-gray-200 text-grey hover:bg-gray-200'}  px-6 py-3 rounded-full mr-1 mb-1`}
                type="submit"
                disabled={!changed}
              >
                <Trans i18nKey="update" />
              </Button>
            </form>
            <div className="pt-5 mt-5 border-t">
              {localProfile && <PasswordChange user={localProfile} /> }
            </div>
          </div>
      </div>
    </div>
  )
};
