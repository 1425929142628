import * as React from 'react';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';
import { Button } from '../../../components/Button';
import { EmailList } from './EmailList';

export interface FormProps {
  onAdd: Function;
  onRemove: Function;
  supplier: PendingSupplier,
  onChange: React.FormEventHandler,
  onListItemChange: Function,
  onSubmit: Function,
};

export const AddForm: React.FC<FormProps> = (props:FormProps) => {
  const { supplier, onChange } = props;

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit();
  }

  return (
    <div className={"w-full bg-white py-8 px-12"}>
      {/* FORM */}
      <h2>Gegevens van de leverancier</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="mr-2">
            <Trans i18nKey="name" />
          </label>
          <input
            name="name"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={supplier.name}
            required={true}
          />
        </div>
        <div className="mb-5">
          <EmailList
            name="emails"
            items={supplier.emails}
            onChange={props.onListItemChange}
            onAdd={props.onAdd}
            onRemove={props.onRemove}
          />
        </div>
        <Link to="/suppliers">
          <Trans i18nKey="back" />
        </Link>
        <Button className="o-button" type="submit">
          <Trans i18nKey="submit" />
        </Button>
      </form>
    </div>
  )
};

export default AddForm;
