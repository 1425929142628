import { createAsyncThunk } from '@reduxjs/toolkit';
import productService from './productService';

const onUploadProgress = () => {};

export const updateProduct = createAsyncThunk<any, {product: Product, image?: File|null}, { rejectValue: string }>(
  'products/updateProduct',
  async ({product, image}, { rejectWithValue }) => {
    try {
      await productService().update(product);
      if (image && product.id) {
        await productService().upload(product.id, image, onUploadProgress);
      }
      return await productService().findOne(product.id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getProducts = createAsyncThunk<any, PageInfo, { rejectValue: string  }>(
  'products/getProducts',
  async (pageInfo, { rejectWithValue }) => {
    try {
      return await productService().findAll({
        page: 1, // default paging value
        limit: 25, // default paging value
        ...pageInfo,
      });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getProduct = createAsyncThunk<any, string, { rejectValue: string }>(
  'products/getProduct',
  async (id, { rejectWithValue }) => {
    try {
      return await productService().findOne(id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const createProduct = createAsyncThunk<any, {product: PendingProduct, image?: File|null, cb?: Function}, { rejectValue: string }>(
  'products/createProduct',
  async ({product, image, cb}, { rejectWithValue }) => {
    try {
      const result:any = await productService().create(product);
      if (image && result && result.id) {
        const uploadResult = await productService().upload(result.id, image, onUploadProgress);
        if (typeof uploadResult === 'string') {
          result.imageUrl = uploadResult;
        }
        const newProduct = await productService().findOne(result.id);
        if (cb) {
          cb(newProduct);
        }
        return newProduct;
      }
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const removeProduct = createAsyncThunk<any, string, { rejectValue: string }>(
  'products/removeProduct',
  async (id, { rejectWithValue }) => {
    try {
      return productService().remove(id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const searchProduct = createAsyncThunk<any, string, { rejectValue: string }>(
  'products/searchProduct',
  async (text, { rejectWithValue }) => {
    try {
      const result = await productService().search(text);
      return result;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);
