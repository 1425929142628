import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {useMedia} from 'react-use';

interface Props {
  value: string | undefined;
  name: keyof ProjectProperties | keyof Project;
  onChange: React.FormEventHandler;
  allowsVisible?: boolean; // If true then always show comment textarea, never the "add comment" button.
}

export const ProductDetailComment: React.FC<Props> = React.memo(({onChange, value, name, allowsVisible = false}:Props) => {
  const { t } = useTranslation();
  const [showComment, setShowComment] = React.useState<boolean|null>(allowsVisible === true ? true : null);
  const isPrint = useMedia('print');
  const minRowCount = 4;

  React.useEffect(() => {
    if (value && showComment === null) {
      setShowComment(typeof value === 'string' && value.length > 0);
    }
  }, [value, showComment]);

  const rows = React.useMemo(() => {
    if (isPrint && typeof value === 'string') {
      return Math.max((value||'').split("\n").length, minRowCount);
    }
    return minRowCount;
  }, [isPrint, value]);

  return (
      <div className="mt-6 mb-2">
        {!showComment && (<button
        className="bg-gray-200 pl-6 pr-6 text-gray rounded-full p-3 hover:bg-gray-400 print:hidden"
        onClick={() => setShowComment(!showComment)}
        >
        {t('add comment')}
        </button> )}
        {showComment && (
        <div className="mb-3">
          <label>{t('comment')}</label>
          <textarea
            name={name}
            className="o-input__textarea "
            onChange={onChange}
            rows={rows}
            value={value}
          />
        </div>
        )}
      </div>
  );
});

export default ProductDetailComment;
