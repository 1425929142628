import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
  defaultImageUrl: string;
  resetFn: Function;
}

const valuesA:ValueItem[] = ([
  {
    value: 'Als foto',
    selectProductImage: 'randtype-imageUrl',
  },
  {
    value: 'Number',
    label: 'Als nummer',
    selectSwatchName: 'randtype-number',
  },
]);


const sectionName = 'Randtype';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionH: React.FC<Props> = React.memo(({onChange, properties, defaultImageUrl, resetFn}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid md:grid-cols-2">
        {/** INPUT */}
        { valuesA.map((c, idx) => (
          <div key={c.value} >
            <RadioButton
              name={sectionId}
              checked={properties['randtype'] === c.value}
              onChange={onChange}
              properties={properties}
              id={`${sectionId}-${idx}`}
              defaultImageUrl={defaultImageUrl}
              resetFn={resetFn}
              {...c}
            />
          </div>
        ))}
      </div>

      <hr className="mt-6 mb-6 print:hidden"/>
      <div className="grid md:grid-cols-1">
        <Checkbox
          name="randtype-small"
          id={`${sectionId}-randtype-small`}
          checked={properties['randtype-small'] === "true"}
          value="true"
          onChange={onChange}
          properties={properties}
          label={t('Smal')}
        />
      {/** INPUT */}
        <Checkbox
          name="randtype-breed"
          id={`${sectionId}-randtype-breed`}
          checked={properties['randtype-breed'] === "true"}
          value="true"
          onChange={onChange}
          properties={properties}
          label={t('Breed')}
        />
      {/** INPUT */}
        <Checkbox
          name="randtype-rondom"
          id={`${sectionId}-randtype-rondom`}
          checked={properties['randtype-rondom'] === "true"}
          value="true"
          onChange={onChange}
          properties={properties}
          label={t('Rondom')}
        />
      {/** INPUT */}
        <Checkbox
          name="randtype-bal-bal"
          id={`${sectionId}-randtype-bal-bal`}
          checked={properties['randtype-bal-bal'] === "true"}
          value="true"
          onChange={onChange}
          properties={properties}
          label={t('Bal-bal')}
        />
      {/** INPUT */}
        <Checkbox
          name="randtype-has-other"
          id={`${sectionId}-randtype-other`}
          checked={properties['randtype-has-other'] === "true"}
          textEntry="randtype-other"
          value="true"
          onChange={onChange}
          properties={properties}
          label="..."
        />

      </div>
    </div>
  );
});

export default ProductDetailsSectionH;
