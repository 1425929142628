import {default as React, useEffect} from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useParams, useNavigate } from "react-router-dom";
import { selectUser, userDisplayName} from './userSlice';
import { removeUser, getUser } from './actions';
import { Button } from '../../components/Button';
import { useAppDispatch } from '../../app/hooks';

export const RemoveUser: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  useEffect(() => {
      if (typeof id !== 'string') { return; }
      dispatch(getUser(id))
  }, [dispatch, id]);

  const onRemoveUser = () => {
    if (typeof id !== 'string') { return; }
    dispatch(removeUser(id))
    .then(unwrapResult)
    .then(() => {
      navigate('/users');
    })
    .catch(() => {});
  }

  const name = user ? userDisplayName(user) : '';
  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to="/users" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">Medewerker verwijderen?</span>
          </div>
        </div>
      </div>
      {/* MAIN */}
      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
          <div className={"w-full bg-white py-8 px-12"}>
            <h2>Medewerker {name} verwijderen?</h2>
            <Link to="/users">
              <Trans i18nKey="back" />
            </Link>
            <Button
            onClick={onRemoveUser}
            className="o-button ml-4" type="submit">
              <Trans i18nKey="remove" />
            </Button>
          </div>
        </div>
      </div>
    </>
  )
};

export default RemoveUser;
