import { createAsyncThunk } from '@reduxjs/toolkit';
import swatchService from './swatchService';

const onUploadProgress = () => {};

export const updateSwatch = createAsyncThunk<any, {swatch: Swatch, image: File|null}, { rejectValue: string }>(
  'swatch/updateSwatch',
  async ({swatch, image}, { rejectWithValue }) => {
    try {
      await swatchService().update(swatch);
      if (image && swatch.id) {
        await swatchService().upload(swatch.id, image, onUploadProgress,);
      }
      return await swatchService().findOne(swatch.id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getSwatches = createAsyncThunk<any, PageInfo, { rejectValue: string  }>(
  'swatch/getSwatches',
  async (pageInfo, { rejectWithValue }) => {
    try {
      return await swatchService().findAll({
        page: 1, // default paging value
        limit: 25, // default paging value
        ...pageInfo,
      });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getSwatch = createAsyncThunk<any, string, { rejectValue: string }>(
  'swatch/getSwatch',
  async (id, { rejectWithValue }) => {
    try {
      return await swatchService().findOne(id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const createSwatch = createAsyncThunk<any, {swatch: PendingSwatch, image: File|null, cb?: Function}, { rejectValue: string }>(
  'swatch/createSwatch',
  async ({swatch, image, cb}, { rejectWithValue }) => {
    try {
      const result:any = await swatchService().create(swatch);
      if (image && result && result.id) {
        const uploadResult = await swatchService().upload(result.id, image, onUploadProgress);
        if (typeof uploadResult === 'string') {
          result.imageUrl = uploadResult;
        }
        const newSwatch = await swatchService().findOne(result.id);
        if (cb) {
          cb(newSwatch);
        }
        return newSwatch;
      }
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const removeSwatch = createAsyncThunk<any, string, { rejectValue: string }>(
  'swatch/removeSwatch',
  async (id, { rejectWithValue }) => {
    try {
      return swatchService().remove(id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const searchSwatch = createAsyncThunk<any, SearchOptions, { rejectValue: string }>(
  'swatch/searchSwatch',
  async (options, { rejectWithValue }) => {
    try {
      const result = await swatchService().search(options);
      return result;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);
