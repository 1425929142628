import React from 'react';
import { Link } from 'react-router-dom';
import { createPopper } from '@popperjs/core';
import {useTranslation} from "react-i18next";

interface Props {
  supplier: Supplier;
}

export const ActionsDropdown: React.FC<Props> = ({supplier}:Props) => {
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef<HTMLButtonElement>();
  const popoverDropdownRef = React.createRef<HTMLDivElement>();
  const { t } = useTranslation();
  const openDropdownPopover = () => {
    if (btnDropdownRef?.current && popoverDropdownRef?.current) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
      setDropdownPopoverShow(true);
      window.document.addEventListener('mouseup', closeDropdownPopover);
    }
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
    window.document.removeEventListener('mouseup', closeDropdownPopover);
  };

  return (
    <>
      <button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        ...
      </button>

      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <Link
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100"
          to={`/suppliers/edit/${supplier.id}`}
        >
          {t('edit')}
        </Link>
        <Link
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100"
          to={`/suppliers/remove/${supplier.id}`}
        >
          {t('remove')}
        </Link>
      </div>
    </>
  );
};

export default ActionsDropdown;
