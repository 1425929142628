import { createSlice } from '@reduxjs/toolkit';
import { getSuppliers, getSupplier, updateSupplier, createSupplier, removeSupplier } from './actions';
import { RootState } from '../../app/store';
import { getResponseError } from '../../api';

interface supplierState {
  error: boolean | null;
  isWaiting: boolean;
  items: Supplier[] | null;
  current: Supplier | null;
  message: string;
  paging: PageMeta | null;
}

const initialState: supplierState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  message: '',
  paging: null,
}

export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL SUPPLIERS
    .addCase(getSuppliers.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
    })
    .addCase(getSuppliers.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items.map((c:Supplier) => ({
        ...c,
        emails: (c.emails === null && c.email) ? [c.email] : c.emails,
      }));
      state.paging = action.payload.meta;
      state.message = '';
    })
    .addCase(getSuppliers.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action);
    })
    // GET SINGLE SUPPLIER
    .addCase(getSupplier.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(getSupplier.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = {
        ...action.payload,
        emails: (action.payload.emails === null && action.payload.email) ? [action.payload.email] : action.payload.emails,
      };
      state.message = '';
    })
    .addCase(getSupplier.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // UPDATE SUPPLIER
    .addCase(updateSupplier.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De aanpassingen worden opgeslagen...';
    })
    .addCase(updateSupplier.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De aanpassingen zijn opgeslagen';
    })
    .addCase(updateSupplier.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // CREATE SUPPLIER
    .addCase(createSupplier.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De medewerker wordt aangemaakt...';
    })
    .addCase(createSupplier.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De medewerker is aangemaakt.';
    })
    .addCase(createSupplier.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // REMOVE SUPPLIER
    .addCase(removeSupplier.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden verwijderd';
    })
    .addCase(removeSupplier.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn verwijderd';
    })
    .addCase(removeSupplier.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // DEFAULT
    .addDefaultCase(() => {}),
});

// SELECTORS
export const selectSuppliers = (state: RootState) => state.suppliers.items;
export const selectSupplier = (state: RootState) => state.suppliers.current;
export const selectPaging = (state: RootState) => state.suppliers.paging;
export const selectFeedback = (state: RootState):Feedback => ({
  isWaiting: state.suppliers.isWaiting,
  message: state.suppliers.message,
  error: state.suppliers.error,
});

export default suppliersSlice.reducer;
