import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getProducts } from './actions';
import { Card } from './Card';
import { selectProducts, selectPaging, selectSearchFeedback } from './productsSlice';
import { Pagination } from '../../components/Pagination';

export interface Props {
  onSelect?: Function;
}

export const List: React.FC<Props> = (props: Props) => {
  const products = useSelector(selectProducts);
  const feedback = useSelector(selectSearchFeedback);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paging = useSelector(selectPaging);

  useEffect(() => {
    dispatch(getProducts({ page: 1 }));
  }, [dispatch]);

  const onPaging = (page:number) => {
    dispatch(getProducts({ page }));
  }

  let feedbackMessage = '';
  if (feedback && !feedback.isWaiting && feedback.isSearchResult === true) {
    feedbackMessage = feedback.count === 0 ? 'no search result' : 'search result count';
  }

  return (
    <>
      {feedbackMessage && (
        <div className="p-4 mb-4 font-bold">
        {t(feedbackMessage, { count: (feedback && feedback.count) || 0})}
        </div>
      )}
      {/* ITEMS */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4  auto-rows-max">
        {products && products.map(c => <Card key={c.id} product={c} onSelect={props.onSelect} />)}
      </div>
      {/* PAGER */}
      <Pagination meta={paging} onChange={onPaging}/>
    </>
  );
}

export default List;
