import {useEffect, useState, useCallback, default as React} from 'react';
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { selectSwatch, selectFeedback } from './swatchSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getSwatch, updateSwatch} from './actions';
import { EditForm } from './components/EditForm';
import { Feedback } from '../../components/Feedback';

export const EditSwatch: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [localSwatch, setLocalSwatch] = useState<Swatch|null>(null);
  const swatch = useSelector(selectSwatch);
  const feedback = useSelector(selectFeedback);

  // Fetch swatch
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getSwatch(id));
  }, [dispatch, id]);

  // Copy swatch into local data for edit
  useEffect(() => {
    if (typeof id !== 'string') { return; }
    // Copy backend swatch into local swatch
    if (swatch && swatch !== null && localSwatch === null && id === `${swatch.id}`) {
      setLocalSwatch({name: '', ...swatch});
    }
  }, [localSwatch, swatch, id]);


  // Form edits to local data
  const onChange = useCallback(({ target }) => {
    if (!localSwatch) { return; }
    setLocalSwatch({...localSwatch, [target.name]: target.value });
  }, [localSwatch]);

  // Submit
  const onSubmit = useCallback((file:File|null) => {
    if (!localSwatch || localSwatch.id === null) { return false; }
    dispatch(updateSwatch({swatch: {...localSwatch}, image: file}));
  }, [dispatch, localSwatch]);

  const onRemoveImage = useCallback(() => {
    if (!localSwatch) { return; }
    setLocalSwatch({...localSwatch, imageUrl: '' });
  }, [localSwatch]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to="/swatches" className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">{t('edit swatch')}</span>
          </div>
        </div>
      </div>

      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
      {localSwatch && (<EditForm
        swatch={localSwatch}
        onChange={onChange}
        onSubmit={onSubmit}
        onRemoveImage={onRemoveImage}
      />)}
          <Feedback feedback={feedback} />
        </div>
      </div>

    </>
  )
};

export default EditSwatch;
