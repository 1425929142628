import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
}

const values:ValueItem[] = ([
  { value: 'Met velcro' },
  {
    value: 'other',
    label: '...',
    textEntry: 'tongbevestiging-other',
  },
]);

const sectionName = 'Tongbevestiging';
const sectionId:string = 'tongbevestiging';

export const ProductDetailsSectionQ: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid grid-cols-1">
          {/** INPUT */}
          { values.map((c, idx) => (
            <div className="grid md:grid-cols-1" key={c.value} >
              <RadioButton
                name={sectionId}
                id={`${sectionId}-${idx}`}
                checked={properties['tongbevestiging'] === c.value}
                onChange={onChange}
                properties={properties}
                {...c}
              />
            </div>
          ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionQ;
