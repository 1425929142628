import i18n from 'i18next';
import translation from './nl-be/translation.json';
import { initReactI18next } from 'react-i18next';

export const resources = {
  'nl': {
    translation,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: 'nl',
  resources,
  fallbackLng: 'nl',
  parseMissingKeyHandler: (key:string):string => {
    if (key.indexOf('api.') === 0) {
      return key.substr(4);
    }
    return key;
  },
});
