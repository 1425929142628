import * as React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectAuthenticated } from './authenticationSlice';

export interface RequireAuthProps {
  children?: any;
  redirectTo?: string;
}

export const RequireAuth: React.FC<RequireAuthProps> = ({ children, redirectTo="/login" }: RequireAuthProps) =>  {
  let location = useLocation();
  const authenticated = useSelector(selectAuthenticated);
  if (authenticated) {
    return children;
  }
  return (
    <Navigate
      to={{
        pathname: redirectTo,
      }}
      state= {{
        from: {
          pathname: (location && location.pathname) || '',
        }
      }}
    />
  );
}
