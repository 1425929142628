import { Link } from "react-router-dom";
import { DateTime } from 'luxon';
import { ActionsDropdown } from './ActionsDropdown';

export interface Props {
  supplier: Supplier;
}

export const Row: React.FC<Props> = (props: Props) => {
  const { supplier } = props;
  const dt = DateTime.fromISO(supplier.updatedAt, { setZone: false });
  const updatedAtDate = dt.toFormat('dd MMMM´yy', { locale: 'nl-be'});
  const updatedAtDateTime = dt.toLocaleString(DateTime.DATETIME_FULL);

  return (
    <tr className="o-table__row">
      <td>
        <Link to={`/suppliers/edit/${supplier.id}`}>
          {supplier.name}
        </Link>
      </td>
      <td><div className="overflow-hidden truncate w-80">{supplier.emails.join(', ')}</div></td>
      <td><span title={`${updatedAtDateTime}`}>{updatedAtDate}</span></td>
      <td className="text-right">
        <ActionsDropdown supplier={supplier} />
      </td>
    </tr>
  )
};

export default Row;
