import * as React from 'react';
import {SketchField, Tools} from 'react-sketch2';
import { CirclePicker } from 'react-color';

// Using react-sketch2 (a fork of react-sketch) instead of react-sketch.
// Switch back to react-sketch once https://github.com/tbolis/react-sketch/issues/126
// is resolved.
interface Props {
  imageUrl?: string | null;  // the current sketch url to load
  backgroundImageUrl?: string | null; // product image to reset/clear the canvas to
  edit: boolean;
}

export const Sketch = React.forwardRef((
    props: Props,
    ref: React.Ref<{toDataURL:any, clear:any}>,
  ) => {
  React.useImperativeHandle(ref, () => ({ toDataURL, clear }));

  const { edit, imageUrl, backgroundImageUrl } = props;
  const sketchRef = React.useRef<any>(null);

  const colors = ['#FF6900', '#EB144C', '#FCB900', '#00D084', '#0693E3',
    '#ABB8C3', '#000000'];
  const [lineColor, setLineColor] = React.useState(colors[0]);
  const [lineWidth, setLineWidth] = React.useState(10);
  const [tool, setTool] = React.useState(null);
  const [canUndo, setCanUndo] = React.useState(false);
  const [canRedo, setCanRedo] = React.useState(false);
  const [sketchWidth, setSketchWidth] = React.useState<number>(200);
  const [sketchHeight, setSketchHeight] = React.useState<number>(200);

  const toDataURL= () : string => {
    if (!sketchRef?.current) { return ''; }
    return sketchRef.current.toDataURL() || '';
  };

  const onLineWidthChange = (evt:any) => {
    setLineWidth(Number(evt.target.value));
  };

  const onSketchChange = React.useCallback(() => {
    if (!sketchRef || !sketchRef.current) { return; }
    setCanUndo(sketchRef.current.canUndo());
    setCanRedo(sketchRef.current.canRedo());
  }, [sketchRef]);

  const undo = () => {
    if (!sketchRef?.current) { return; }
    if (sketchRef.current.canUndo()) {
      sketchRef.current.undo();
    }
  };

  const redo = () => {
    if (!sketchRef?.current) { return; }
    if (sketchRef.current.canRedo()) {
      sketchRef.current.redo();
    }
  };

  const clear = () => {
    if (!sketchRef?.current) { return; }
    sketchRef.current.clear();
    initSketch();
  };

  const initSketch = async () => {
    if (!sketchRef?.current || !imageUrl || !backgroundImageUrl) {
      return;
    }
    const response = await fetch(imageUrl || backgroundImageUrl);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        sketchRef.current.setBackgroundFromDataUrl(reader.result, {
          originX: "left",
          originY: "top",
        })},
      false
    );
    reader.readAsDataURL(blob);
  }

  // First get and set the imageUrl with and height on the sketch
  // This will display the sketch and make the sketch ref available
  React.useEffect(() => {
    if (!imageUrl || !backgroundImageUrl) { return; }
    const img = new Image();
    img.setAttribute('crossOrigin', 'anonymous');
    img.onload = function() {
        setSketchWidth(img.width);
        setSketchHeight(img.height);
    }
    img.src = imageUrl || backgroundImageUrl;
  }, [imageUrl, backgroundImageUrl]);

  React.useEffect(() => {
    initSketch();
  }, [sketchRef, sketchWidth, sketchHeight]);

  React.useEffect(() => {
    if (edit && !tool) {
      setTool(Tools.Pencil);
    }
  }, [edit, tool]);

  return (
    <>
    {edit && (
      <div className={"p-4 bg-white flex"}>
        <div>
          <label>Kleur</label>
          <CirclePicker
            width="800"
            color={lineColor}
            colors={colors}
            onChange={(color) => setLineColor(color.hex)}/>
        </div>
        <div className={"px-8"}>
          <label className="mr-4 font-bold">Dikte</label>
          <select
            value={lineWidth}
            onChange={onLineWidthChange}>
            <option value="5" key="5">5</option>
            <option value="10" key="10">10</option>
            <option value="20" key="20">20</option>
            <option value="50" key="50">50</option>
          </select>
        </div>
        <div className={"flex"}>
          <div>
            <button
              className={`bg-gray-${canUndo ? 800 : 200} text-${canUndo ? 'roti-50' : 'gray-600'} px-4 py-3 text-2xl h-full rounded-full mr-1 mb-1 hover:bg-gray-${canUndo ? 500 : 200}`}
              disabled={!canUndo}
              onClick={undo}>
              <i className="fas fa-undo"></i>
            </button>
            <button
              className={`bg-gray-${canRedo ? 800 : 200} text-${canRedo ? 'roti-50' : 'gray-600'} px-4 py-3 text-2xl h-full rounded-full mr-1 mb-1 hover:bg-gray-${canRedo ? 500 : 200}`}
              onClick={redo}>
              <i className="fas fa-redo"></i>
            </button>
          </div>
          <div className={"px-4"}>
            <button
              className={`bg-gray-${tool === Tools.Pencil ? 800 : 200} text-${tool === Tools.Pencil ? 'roti-50' : 'gray-600'} px-4 py-3 text-2xl h-full rounded-full mr-1 mb-1`}
              onClick={() => {setTool(Tools.Pencil)}}>
              <i className="fas fa-pen"></i>
            </button>
            <button
              className={`bg-gray-${tool === Tools.Rectangle ? 800 : 200} text-${tool === Tools.Rectangle ? 'roti-50' : 'gray-600'}  px-4 py-3 text-2xl h-full rounded-full mr-1 mb-1`}
              onClick={() => {setTool(Tools.Rectangle)}}>
              <i className="fas fa-square"></i>
            </button>
            <button
              className={`bg-gray-${tool === Tools.Circle ? 800 : 200} text-${tool === Tools.Circle ? 'roti-50' : 'gray-600'}  px-4 py-3 text-2xl h-full rounded-full mr-1 mb-1`}
              onClick={() => {setTool(Tools.Circle)}}>
              <i className="fas fa-circle"></i>
            </button>
        </div>
       </div>
      </div>
     )}
     {sketchWidth &&
       <SketchField
         ref={sketchRef}
         width={`${sketchWidth}px`}
         height={`${sketchHeight}px`}
         tool={tool}
         lineColor={lineColor}
         fillColor="transparent"
         lineWidth={lineWidth}
         className={"mx-auto"}
         onChange={onSketchChange}
       /> }
    </>
  )
});


