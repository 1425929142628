import { request, upload } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/leather`;
const service = () => {
  return {
    findAll: (pageInfo:PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    findOne: (id:string) => request(`${baseUrl}/${id}`, 'get'),
    create: (leather:PendingLeather) => request(`${baseUrl}`, 'post', leather),
    update: (leather:Leather) => request(`${baseUrl}/${leather.id}`, 'patch', leather),
    remove: (id:string) => request(`${baseUrl}/${id}`, 'delete'),
    upload: (id:string, file:any, onUploadProgress:any) => upload(`${baseUrl}/${id}/image`, file, onUploadProgress),
    search: (text:string) => request(`${baseUrl}/search`, 'get', { text }),
  };
};

export default service; 
