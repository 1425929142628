import * as React from "react";
import { Trans } from 'react-i18next';

export interface PaginationProps {
  meta: PageMeta | null,
  onChange: Function,
}

export const Pagination: React.FC<PaginationProps> = ({ meta, onChange }: PaginationProps) => {
  if (meta === null) {
    return null;
  }
  const isFirstPage = meta.currentPage === 1;
  const isLastPage = meta.currentPage === meta.totalPages;
  const showButtons = meta.totalPages > 1;
  // Use "1" if meta.totalPages is 0 to prevent showing "Pageina 1/0"
  const totalPages = Math.max(meta.totalPages, 1);

  return (
    <div className="m-4 o-pager">
      {/* PREV */}
      {showButtons && (
      <button
        className="o-action__icon"
        type="button"
        disabled={isFirstPage}
        onClick={() => onChange(Math.max(1, meta.currentPage - 1))}
      >
        <i className="fa fa-arrow-left"></i>
      </button>)}
      {/* INDICATOR */}
      <span className="ml-4 mr-4">
        <Trans i18nKey="page" />&nbsp;
        {meta.currentPage} / {totalPages}
      </span>
      {/* NEXT */}
      {showButtons && (<button
        className="o-action__icon"
        type="button"
        disabled={isLastPage}
        onClick={() => onChange(Math.min(meta.totalPages, meta.currentPage + 1))}
      ><i className="fa fa-arrow-right"></i></button>)}
    </div>
  )
};

