import * as React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { Navbar } from '../../components/NavBar';
import { Profile } from './Profile';
import { Users } from './Users';
import { EditUser  } from './Edit';
import { AddUser  } from './Add';
import { RemoveUser  } from './Remove';

export const UserRoutes: React.FC = () =>  {
  return (
    <Routes>
      <Route
        path="/edit/:id"
        element={ <EditUser /> }
      />
      <Route
        path="/add"
        element={ <AddUser /> }
      />
      <Route
        path="/remove/:id"
        element={ <RemoveUser /> }
      />
      <Route
        path="/"
        element={
          <>
            <Navbar />
            <Users />
          </>
        }
      />
    </Routes>
  );
}

export const ProfileRoutes: React.FC = () =>  {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <>
            <Navbar />
            <Profile />
          </>
        }
      />
    </Routes>
  );
}

