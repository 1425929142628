import { createSlice } from '@reduxjs/toolkit';
import { getUsers, getUser, updateUser, createUser, removeUser } from './actions';
import { RootState } from '../../app/store';
import { getResponseError } from '../../api';

interface userState {
  error: boolean | null;
  isWaiting: boolean;
  items: User[] | null;
  current: User | null;
  message: string;
  paging: PageMeta | null;
}

const initialState: userState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  message: '',
  paging: null,
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL USERS
    .addCase(getUsers.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
    })
    .addCase(getUsers.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.paging = action.payload.meta;
      state.message = '';
    })
    .addCase(getUsers.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action);
    })
    // GET SINGLE USER
    .addCase(getUser.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(getUser.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = '';
    })
    .addCase(getUser.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // UPDATE USER
    .addCase(updateUser.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De aanpassingen worden opgeslagen...';
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De aanpassingen zijn opgeslagen';
    })
    .addCase(updateUser.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // CREATE USER
    .addCase(createUser.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De medewerker wordt aangemaakt...';
    })
    .addCase(createUser.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De medewerker is aangemaakt.';
    })
    .addCase(createUser.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // REMOVE USER
    .addCase(removeUser.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden verwijderd';
    })
    .addCase(removeUser.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn verwijderd';
    })
    .addCase(removeUser.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    .addDefaultCase(() => {}),
});

// SELECTORS
export const selectUsers = (state: RootState) => state.user.items;
export const selectUser = (state: RootState) => state.user.current;
export const selectPaging = (state: RootState) => state.user.paging;
export const selectFeedback = (state: RootState):Feedback => ({
  isWaiting: state.user.isWaiting,
  message: state.user.message,
  error: state.user.error,
});

// UTIL
export const userDisplayName = (user: User) => {
  let result = [];
  if (user) {
    if (user.firstName) {
      result.push(user.firstName);
    }
    if (result.length === 0) {
      result.push(user.email);
    }
  }

  return result.join();
};

export default usersSlice.reducer;
