import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
  defaultImageUrl: string;
  resetFn: Function;
}

const values:ValueItem[] = ([
  {
    value: 'Als foto',
    selectProductImage: 'onderwerk-maakwijze-imageUrl',
  },
  { value: 'Zonder rand gelijmd' },
  { value: 'Met rand gelijmd' },
  { value: 'Overtrokken hiel zebra' },
  { value: 'Overtrokken hiel leder' },
  { value: 'Afgelapt' },
  {
    value: 'other',
    label: '...',
    textEntry: 'onderwerk-maakwijze-other',
  },
]);

const sectionName = 'Onderwerk maakwijze';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionG: React.FC<Props> = React.memo(({onChange, properties, defaultImageUrl, resetFn}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid md:grid-cols-2">
        {/** INPUT */}
        { values.map((c, idx) => (
          <div key={c.value} >
            <RadioButton
              name="onderwerk-maakwijze"
              checked={properties['onderwerk-maakwijze'] === c.value}
              onChange={onChange}
              properties={properties}
              id={`${sectionId}-${idx}`}
              defaultImageUrl={defaultImageUrl}
              resetFn={resetFn}
              {...c}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionG;
