import * as React from 'react';
import { DateTime } from 'luxon';

interface Props {
  value: string | undefined;
  showTime?: boolean;
}

export const Date: React.FC<Props> = ({value, showTime}:Props) => {
  const dt = DateTime.fromISO(value || '', { setZone: false });
  return (
    <span>{dt.isValid ? dt.toLocaleString(showTime === true ? DateTime.DATETIME_MED : DateTime.DATE_FULL) : '-' }</span>
  )
}
