import { ItemSelector } from './ItemSelector';


type swatchCategory = "swatch/zool" | "swatch/rand"

interface Props {
  imageUrl?: string;
  name?: string;
  value: keyof ProjectProperties;
  item: swatchCategory; // the category show in the swatch selector
  itemKey?: keyof NamedItem,
}

export const SwatchSelector: React.FC<Props> = (props:Props) => (
  <ItemSelector
    {...props}
  />
);

export default SwatchSelector;
