import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
  defaultImageUrl: string;
  resetFn: Function;
}

const valuesA:ValueItem[] = ([
  { value: 'Astostar' },
  { value: 'Poro' },
  { value: 'Ruwe zool' },
  {
    value: 'Fabriekszool',
    selectSwatchImage: 'zoolmateriaal-fabriekszool-imageUrl',
  },
  { value: 'Rubber' },
  { value: 'Sportzool' },
  { value: 'Nora Astro Light Delta' },
  { value: 'Neotrans' },
  { value: 'Leder' },
  { value: 'Leder + antislip' },
  { value: 'Autoband' },
  { value: 'Vibram Eiger' },
  { value: 'Vibram Gumite' },
  {
    value: 'Als foto',
    selectProductImage: 'zoolmateriaal-imageUrl',
  },
  {
    value: 'other',
    label: '...',
    textEntry: 'zoolmateriaal-other',
  },
]);

const valuesB:ValueItem[] = ([
  { value: '4 mm' },
  { value: '6 mm' },
  { value: 'Extra dik' },
  { value: 'Teenstuk hard' },
  {
    value: 'other',
    label: '...',
    textEntry: 'zoolmateriaal-dikte-other',
  },
]);

const sectionName = 'Zoolmateriaal';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionM: React.FC<Props> = React.memo(({onChange, properties, defaultImageUrl, resetFn}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid md:grid-cols-2">
        {/** INPUT */}
        { valuesA.map((c, idx) => (
          <div key={c.value} >
            <RadioButton
              name="zoolmateriaal"
              id={`${sectionId}-zoolmateriaal-${idx}`}
              checked={properties['zoolmateriaal'] === c.value}
              onChange={onChange}
              properties={properties}
              defaultImageUrl={defaultImageUrl}
              resetFn={resetFn}
              {...c}
            />
          </div>
        ))}
      </div>
      <hr className="mt-6 mb-6 print:hidden"/>
      <div className="grid md:grid-cols-2">
        {/** INPUT */}
        { valuesB.map((c, idx) => (
          <div  key={c.value} >
            <RadioButton
              name="zoolmateriaal-dikte"
              id={`${sectionId}-zoolmateriaal-dikte-${idx}`}
              checked={properties['zoolmateriaal-dikte'] === c.value}
              onChange={onChange}
              properties={properties}
              {...c}
            />
            { (c.value === "Teenstuk hard" && properties['zoolmateriaal-dikte'] === "Teenstuk hard") && (
              <div className="grid grid-cols-2">
                {/** INPUT */}
                <div className="m-2">
                  <label
                    htmlFor="zoolmateriaal-dikte-links"
                  >
                  <input
                    name="zoolmateriaal-dikte-links"
                    id="zoolmateriaal-dikte-links"
                    checked={properties["zoolmateriaal-dikte-links"] === "true"}
                    type="checkbox"
                    onChange={onChange}
                    className="mr-2"
                  />
                  {t('Links')}</label>
                </div>
                {/** INPUT */}
                <div className="m-2">
                  <label htmlFor="zoolmateriaal-dikte-rechts">
                  <input
                    name="zoolmateriaal-dikte-rechts"
                    id="zoolmateriaal-dikte-rechts"
                    checked={properties["zoolmateriaal-dikte-rechts"] === "true"}
                    type="checkbox"
                    onChange={onChange}
                    className="mr-2"
                  />
                  {t('Rechts')}</label>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <hr className="mt-6 mb-6 print:hidden"/>
        {/** INPUT */}
        <div>
          <label
            htmlFor={`${sectionId}-zoolmateriaal-tussenzool-kleur`}
          >{t("Tussenzool kleur")}</label>
          <input
            name="zoolmateriaal-tussenzool-kleur"
            id={`${sectionId}-zoolmateriaal-tussenzool-kleur`}
            value={properties["zoolmateriaal-tussenzool-kleur"]}
            type="text"
            onChange={onChange}
            className="o-input__text"
          />
        </div>
    </div>
  );
});

export default ProductDetailsSectionM;
