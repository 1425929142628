import { Link } from "react-router-dom";
import { DateTime } from 'luxon';
import { ActionsDropdown } from './ActionsDropdown';

export interface Props {
  leather: Leather;
}

export const Row: React.FC<Props> = (props: Props) => {
  const { leather } = props;
  const dt = DateTime.fromISO(leather.updatedAt, { setZone: true });
  const updatedAtDate = dt.toFormat('dd MMMM´yy', { locale: 'nl-be'});
  const updatedAtDateTime = dt.toLocaleString(DateTime.DATETIME_FULL);

  return (
    <tr className="o-table__row">
      <td><Link to={`/leathers/edit/${leather.id}`}>{leather.code}</Link></td>
      <td><Link to={`/leathers/edit/${leather.id}`}>{leather.article}</Link></td>
      <td><Link to={`/leathers/edit/${leather.id}`}>{leather.color}</Link></td>
      <td><span title={`${updatedAtDateTime}`}>{updatedAtDate}</span></td>
      <td className="text-right">
        <ActionsDropdown leather={leather} />
      </td>
    </tr>
  )
};

export default Row;
