import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectSuppliers } from './supplierSlice';
import { getSuppliers } from './actions';

interface SelectorProps extends React.HTMLProps<HTMLSelectElement> {
  onSupplierChange: Function;
  supplier: Supplier | null;
  name: string;
  firstOptionLabel?: string;
}

export const Selector: React.FC<SelectorProps> = (props:SelectorProps) => {
  const { supplier, name, onSupplierChange, firstOptionLabel, ...rest } = props;
  const suppliers = useSelector(selectSuppliers);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getSuppliers({ page: 0, limit: 999 }));
  }, [dispatch]);


  const onChange = React.useCallback((evt) => {
    if (!suppliers) { return; }
    onSupplierChange(
      name,
      suppliers.find(c => `${c.id}` === evt.target.value),
    );
  }, [suppliers, onSupplierChange, name]);

  if (Array.isArray(suppliers) && suppliers.length === 0) {
    return (
      <div>
        <Trans i18nKey="no suppliers available" />
      </div>
    )
  }
  return (
    <select
      value={(supplier && supplier.id) || ''}
      name={name}
      onChange={onChange}
      {...rest}
    >
      <option>
        {t(firstOptionLabel || 'select a supplier')}
      </option>
      {suppliers && suppliers.map(c => (
        <option key={c.id} value={c.id}>
          {c.name}
        </option>
      ))}
    </select>
  );
}
