import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { getProjects } from './actions';
import { Row } from './components/Row';
import { selectProjects, selectPaging, selectSearchFeedback, clearPending } from './projectSlice';
import { Pagination } from '../../components/Pagination';
import { Search } from './Search';
import { DateTime } from 'luxon';
import projectService from './projectService';

export const exportXlsx = async () => {
  const result = await projectService().exportXlsx();
  console.log(result);
  const blob = new Blob([result], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = `Projecten-${DateTime.now().toISODate()}.xlsx`;
  link.click();
}

export function Projects() {
  const projects = useSelector(selectProjects);
  const paging = useSelector(selectPaging);
  const feedback = useSelector(selectSearchFeedback);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getProjects({ page: 1 }));
    dispatch(clearPending());
  }, [dispatch]);

  const onPaging = (page:number) => {
    dispatch(getProjects({ page }));
  }

  let feedbackMessage = '';
  if (feedback && !feedback.isWaiting && feedback.isSearchResult === true) {
    feedbackMessage = feedback.count === 0 ? 'no search result' : 'search result count';
  }

  return (
    <div className="o-page__main">
      <div className="o-container">
        <div className="o-page__actions">
          <h1>Projecten</h1>
          <div className="flex items-center gap-1">
            <Search />
            <Link to={'/projects/add'} className="o-button">
              <i className="fa fa-plus"></i>
              <span>
                <Trans i18nKey="new project" />
              </span>
            </Link>
            <Link to={'/projects/send'} className="o-button">
              <i className="fa fa-mail-bulk"></i>
              <span>
                <Trans i18nKey="send projects" />
              </span>
            </Link>
            <button
                className="bg-gray-200 pl-6 pr-6 text-gray rounded-full p-3 hover:bg-gray-400"
                type="button"
                onClick={exportXlsx}
            >
              <i className={"fa fa-file-excel"} />
              <span className='hidden md:inline-block'><Trans i18nKey="excel export" /></span>
            </button>
          </div>
        </div>
        {feedbackMessage && (
            <div className="p-4 mb-4 font-bold">
              {t(feedbackMessage, { count: (feedback && feedback.count) || 0})}
            </div>
        )}

        <>
          <div className="o-table">
            <table>
              {/* TABLE HEADER */}
              <thead>
                <tr className="o-table__header">
                  <th><Trans i18nKey="klant" /></th>
                  <th><Trans i18nKey="leestnr" /></th>
                  <th><Trans i18nKey="model" /></th>
                  <th><Trans i18nKey="datum" /></th>
                  <th><Trans i18nKey="medewerker" /></th>
                  <th><Trans i18nKey="status" /></th>
                  <th></th>
                </tr>
              </thead>
              {/* TABLE BODY */}
              <tbody>
                {projects && projects.map(c => <Row key={c.id} project={c} />)}
              </tbody>
            </table>

          </div>

          {/* PAGER */}
          <Pagination meta={paging} onChange={onPaging}/>
        </>
      </div>
    </div>
  );
}

export default Projects;
