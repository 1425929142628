import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import get from 'lodash/get';
import { Link, useParams } from "react-router-dom";
import { config } from '../../../config';
import { ValueItem, RadioButton } from './FormElements';
import { Selector } from '../../leather/components/Selector';
import { generatePath } from '../utils';
import ProductDetailComment from './ProductDetailComment';

interface ConfirmationModalProps {
  onOk: Function,
  onCancel: Function,
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({onOk, onCancel}:ConfirmationModalProps) => {
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*body*/}
            <div className="relative p-6 flex-auto">
              <p className="my-4 text-blueGray-500 text-lg leading-relaxed">
                <Trans i18nKey="remove product" />?
              </p>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="bg-gray-200 pl-6 pr-6 text-gray rounded-full p-3 hover:bg-gray-400"
                type="button"
                onClick={() => { onCancel() }}
              >
                <Trans i18nKey="cancel" />
              </button>
              <button
                className="bg-gray-500 pl-6 pr-6 text-white rounded-full p-3 hover:bg-gray-800"
                type="button"
                onClick={() => { onOk() }}
              >
                <Trans i18nKey="remove" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

const combiValues:ProjectDetailEntry[] = [
  {
    entry: 'combi-kleur-1',
    label: 'combi-kleur-1',
  },
  {
    entry: 'combi-kleur-2',
    label: 'combi-kleur-2',
  },
  {
    entry: 'combi-kleur-3',
    label: 'combi-kleur-3',
  },
  {
    entry: 'combi-kleur-4',
    label: 'combi-kleur-4',
  },
];

const stikselValues:ValueItem[] = [
  {
    value: 'Als foto',
    selectProductImage: 'kleur-stiksel-imageUrl',
  },
  {
    value: 'Bijpassend',
  },
  {
    value: 'Zwart',
  },
  {
    value: 'Beige',
  },
  {
    value: 'other',
    label: '...',
    textEntry: 'kleur-stiksel-other',
  },
];

const modelTypeValues:ValueItem[] = [
  {
    value: 'Als foto',
    selectProductImage: 'model-type-imageUrl',
  },
  {
    value: 'Derby',
  },
  {
    value: 'Ver open',
  },
  {
    value: 'Zeer ver open',
  },
  {
    value: 'Derby ver open',
  },
  {
    value: 'Derby zeer ver open',
  },
  {
    value: 'other',
    label: '...',
    textEntry: 'model-type-other',
  },
];

interface Props {
  project: Project | PendingProject;
  properties: ProjectProperties | undefined;
  onRemove: Function;
  onChange: React.FormEventHandler;
  onPropertyChange: React.FormEventHandler;
  onPropertyValueChange: Function;
  view: string;
  defaultImageUrl: string;
  resetFn: Function;
}

const sectionId = 'model-en-kleuren';

export const ProductModel: React.FC<Props> = ({project, onRemove, onChange, onPropertyChange, view, properties,
                                              defaultImageUrl, resetFn, onPropertyValueChange}:Props) => {
  const id = get(useParams(), 'id','');
  const [showModal, setShowModal] = React.useState(false);
  const [showComment, setShowComment] = React.useState<boolean|null>(null);

  const { t } = useTranslation();

  const { product } = project;
  const baseUrl = generatePath(view, id);

  React.useEffect(() => {
    if (project && showComment === null) {
      setShowComment(typeof project.comment === 'string' && project.comment.length > 0);
    }
  }, [project, showComment]);

  if (!product) {
    return (
      <Link
        to={`${baseUrl}/select-product`}
        className="bg-gray-500 pl-6 pr-6 text-white rounded-full p-3 hover:bg-gray-800"
      >
        <Trans i18nKey="select model" />
      </Link>
    )
  }

  if (!properties) { return null; }

  return (
    <div className="o-section" id={sectionId}>
      <h2>
        <Trans i18nKey="Model & kleuren" />
      </h2>
      <div>

          <div className="md:flex print:flex gap-4">
              <div className={"md:max-w-xs print:w-1/2 "}>
                <img
                  src={project.imageBase64 || project.imageUrl || product.imageUrl || config.placeholderImage}
                  alt={product.name}
                  className="w-full"
                />
              </div>
              <div>
                <h3>{product.name} </h3>
                <p className={"mb-8"}>{product.category}</p>

                  <div className="flex gap-2 print:hidden">
                      {/* ACTIONS */}
                      <Link
                          className="bg-gray-500 pl-6 pr-6 text-white rounded-full p-3 hover:bg-gray-800"
                          to={`${baseUrl}/project-annotation`}
                      >
                        <Trans i18nKey="enlarge" />
                      </Link>
                      <Link
                          to={`${baseUrl}/select-product`}
                          state={{ backTo: '/project-details' }}
                          className="bg-gray-200 pl-6 pr-6 text-gray rounded-full p-3 hover:bg-gray-400"
                      >
                          <Trans i18nKey="change" />
                      </Link>
                      <button
                          className="o-action__icon "
                          onClick={() => setShowModal(true)}
                      >
                          <i className="fas fa-trash"></i>
                      </button>
                  </div>

              </div>
          </div>

        {/* COMBI COLORS */}
        <div className="md:grid md:grid-cols-2 md:gap-x-8">
          {/** INPUT */}
          { combiValues.map((c) => (
            <div
              key={c.entry}
              className={`flex-1 o-input__wrapper ${properties[c.entry] ? '' : 'print:hidden'}`}
            >
              <label htmlFor={`${sectionId}-${c.entry}`}>
                {t(`${c.label}`)}
              </label>
              <Selector
                name={c.entry}
                id={`${sectionId}-${c.entry}`}
                onChange={onPropertyValueChange}
                value={properties[c.entry] || null}
              />
            </div>
          ))}
        </div>

      </div>


      <div className="grid md:grid-cols-2 gap-y-4 md:gap-x-8 md:gap-y-0 [ print:grid-cols-2 print:gap-x-8 ]">
        {/* KLEUR STIKSEL */}
        <div>
          <h3 className={"o-label"}>
            <Trans i18nKey="Kleur stiksel" />
          </h3>
          <div>
            {/** INPUT */}
            { stikselValues.map((c, idx) => (
            <RadioButton
              key={c.value}
              name="kleur-stiksel"
              checked={properties['kleur-stiksel'] === c.value}
              onChange={onPropertyChange}
              properties={properties}
              id={`${sectionId}-kleur-stiksel-${idx}`}
              defaultImageUrl={defaultImageUrl}
              resetFn={resetFn}
              {...c}
            />))}
          </div>
        </div>


        {/* MODEL TYPE */}
        <div>
          <h3 className={"o-label"}>
            <Trans i18nKey="Modeltype" />
          </h3>
          <div>
            {/** INPUT */}
            { modelTypeValues.map((c, idx) => (
            <RadioButton
              key={c.value}
              name="model-type"
              checked={properties['model-type'] === c.value}
              onChange={onPropertyChange}
              properties={properties}
              id={`${sectionId}-model-type-${idx}`}
              defaultImageUrl={defaultImageUrl}
              resetFn={resetFn}
              {...c}
            />))}
          </div>
        </div>

        {/* INPUT COMMENT */}
        <ProductDetailComment name="comment" value={project.comment} onChange={onChange} />
      </div>

      {showModal ? (<ConfirmationModal onCancel={() => setShowModal(false) } onOk={() => { setShowModal(false); onRemove() }} />) : null}
    </div>
  );
}

export default ProductModel;
