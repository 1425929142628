import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
  defaultImageUrl: string;
  resetFn: Function;
}

const valuesA:ValueItem[] = ([
  { value: 'Aanspannen aan voering' },
  { value: 'Aanpassen dubbel overleer' },
  { value: 'Zonder naad dubbel' },
  { value: 'Geen' },
]);

const valuesB:ValueItem[] = ([
  { value: '3 mm' },
  { value: '6 mm' },
  {
    value: 'Als foto',
    selectProductImage: 'boordpolster-imageUrl',
  },
  { value: 'Geen' },
]);

const sectionName = 'Boordpolster';
const sectionIdA:string = 'boordpolster';
const sectionIdB:string = 'boordpolster-mm';

export const ProductDetailsSectionO: React.FC<Props> = React.memo(({onChange, properties, defaultImageUrl, resetFn}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionIdA}>
      <h2>{t(sectionName)}</h2>
      <div className="grid grid-cols-1">
          {/** INPUT */}
          { valuesA.map((c, idx) => (
            <div className="grid md:grid-cols-1" key={c.value} >
              <RadioButton
                name={sectionIdA}
                id={`${sectionIdA}-${idx}`}
                checked={properties['boordpolster'] === c.value}
                onChange={onChange}
                properties={properties}
                {...c}
              />
            </div>
          ))}
      </div>
      <hr className="mt-6 mb-6 print:hidden"/>
      <div className="grid grid-cols-1">
          {/** INPUT */}
          { valuesB.map((c, idx) => (
            <div className="grid md:grid-cols-3" key={c.value} >
              <RadioButton
                name={sectionIdB}
                id={`${sectionIdB}-${idx}`}
                checked={properties['boordpolster-mm'] === c.value}
                onChange={onChange}
                properties={properties}
                defaultImageUrl={defaultImageUrl}
                resetFn={resetFn}
                {...c}
              />
            </div>
          ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionO;
