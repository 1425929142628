import * as React from 'react';
import { Link } from "react-router-dom";
import { Trans, useTranslation } from 'react-i18next';
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Button } from '../../../components/Button';
import { config } from '../../../config';

export interface FormProps {
  swatch: Swatch,
  onChange: React.FormEventHandler,
  onSubmit: Function,
  onRemoveImage: Function,
};

export const EditForm: React.FC<FormProps> = (props:FormProps) => {
  const { swatch, onChange } = props;
  const { t } = useTranslation();
  const [images, setImages] = React.useState<ImageListType>([]);

  const onImageChange = (
    imageList: ImageListType,
  ) => {
    setImages(imageList);
  };

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit((images[0] && images[0].file) || null);
  }

  const imageUrl = images[0] && images[0].data_url ? images[0].data_url : swatch.imageUrl;

  return (
    <>
      <div className={"min-w-min mx-auto bg-white py-8 px-12"}>
      {/* FORM */}
      <h2>
        <Trans i18nKey="swatch" />
      </h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label>
            <Trans i18nKey="name" />
          </label>
          <input
            name="name"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={swatch.name}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">
            <Trans i18nKey="category" />
          </label>
          <select
            value={swatch.category}
            onChange={onChange}
            name="category"
            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white text-sm shadow focus:outline-none focus:ring "
          >
            <option value="">
              {t('select a category')}
            </option>
            <option value="rand">
              {t('Rand')}
            </option>
            <option value="zool">
              {t('Zool')}
            </option>
          </select>
        </div>
        <div className="mb-3">
          <ImageUploading
            value={images}
            onChange={onImageChange}
            dataURLKey="data_url"
          >
            {({
              onImageUpload,
              onImageRemoveAll,
              isDragging,
              dragProps,
            }) => (
              // Make sure buttons have type="buttons" (the default type is "submit")
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800"
                  type="button"
                  {...dragProps}
                >
                  Foto selecteren
                </button>
                &nbsp;
                {imageUrl && (
                  <button
                    onClick={() => { onImageRemoveAll(); props.onRemoveImage() }}
                    type="button"
                    className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300">
                    Foto verwijderen
                  </button>
                )}
                <img
                  onClick={onImageUpload}
                  src={imageUrl || config.placeholderImage} alt={swatch.name} width="512"/>
              </div>
            )}
          </ImageUploading>
        </div>
        {/* ACTIONS */}
        <span className="mr-6">
        <Link to={`/swatches/remove/${swatch.id}`} className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-4 hover:bg-gray-300">Staal Verwijderen</Link>
        </span>
        <Link to="/swatches" className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300">Terug</Link>
        <Button className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
            Bewaren
        </Button>
      </form>
      </div>
    </>
  )
};

export default EditForm;
