import React from "react";

export interface AlertProps extends React.HTMLProps<HTMLDivElement> {
  children: React.ReactNode;
  color?: string;
  icon?: string;
}

export const Alert: React.FC<AlertProps> = ({ children, color, icon }: AlertProps) =>
  <div
    className={`text-white px-6 py-4 border-0 rounded relative mb-4 bg-${color ? color : 'red' }-400`}
  >
    {icon && (
      <span className="text-xl inline-block mr-5 align-middle">
        <i className={`fas fa-${icon}`} />
      </span>
    )}
    <span className="inline-block align-middle mr-8">
      {children}
    </span>
  </div>
