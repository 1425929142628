import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';
import ProductDetailComment from './ProductDetailComment';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
}

const valuesA:ValueItem[] = ([
  { value: 'Normaal' },
  { value: 'Dubbel' },
  { value: 'Zacht' },
  { value: 'Staal' },
  { value: 'Aluminium' },
]);

const valuesB:ValueItem[] = ([
  {
    value: 'Vol',
    img: '/images/neus-a.png',
  },
  {
    value: 'Vleugel',
    img: '/images/neus-b.png',
  },
]);

const sectionName = 'Neus';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionK: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  const { t } = useTranslation();
  const [showComment, setShowComment] = React.useState<boolean|null>(null);

  React.useEffect(() => {
    if (properties && showComment === null) {
      setShowComment(typeof properties["neus-comment"] === 'string' && properties["neus-comment"].length > 0);
    }
  }, [properties, showComment]);

  if (!properties) { return null; }

  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="grid md:grid-cols-2">
        {/** INPUT */}
        { valuesA.map((c, idx) => (
          <div className="grid grid-cols-3" key={c.value} >
            <RadioButton
              name="neus"
              checked={properties['neus'] === c.value}
              onChange={onChange}
              properties={properties}
              id={`${sectionId}-neus-${idx}`}
              {...c}
            />
          </div>
        ))}
      </div>

      <div className={`${properties["neus-extra-groot-links"] === "true" || properties["neus-extra-groot-rechts"] === "true" ? '' : 'print:hidden'}`}>
        <hr className="mt-6 mb-6 print:hidden"/>
        <div className="mt-6">
          <div className="grid grid-cols-3">
            <div>
              <span>{t("Extra grote neus")}</span>
            </div>
            {/** INPUT */}
            <div className="m-2">
              <label
                htmlFor={`${sectionId}-neus-extra-groot-links`}
              >
              <input
                name="neus-extra-groot-links"
                checked={properties["neus-extra-groot-links"] === "true"}
                type="checkbox"
                onChange={onChange}
                className="mr-2"
                id={`${sectionId}-neus-extra-groot-links`}
              />
              {t('Links')}</label>
            </div>
            {/** INPUT */}
            <div className="m-2">
              <label
                htmlFor={`${sectionId}-neus-extra-groot-rechts`}
              >
              <input
                name="neus-extra-groot-rechts"
                id={`${sectionId}-neus-extra-groot-rechts`}
                checked={properties["neus-extra-groot-rechts"] === "true"}
                type="checkbox"
                onChange={onChange}
                className="mr-2"
              />
              {t('Rechts')}</label>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-6 mb-6 print:hidden"/>
      <div className="mt-6">
        {/** INPUT */}
        { valuesB.map((c, idx) => (
          <div className="mt-4" key={c.value} >
            <div>
              <RadioButton
                name="neus-vorm"
                id={`${sectionId}-neus-vorm-${idx}`}
                checked={properties['neus-vorm'] === c.value}
                onChange={onChange}
                properties={properties}
                {...c}
              />
              {c.img && <img src={c.img} style={{"maxWidth": "64px"}} alt={c.value} />}
            </div>
          </div>
        ))}
      </div>

      <hr className="mt-6 mb-6 print:hidden" />

      {/* INPUT COMMENT */}
      <ProductDetailComment name="neus-comment" value={properties["neus-comment"]} onChange={onChange} />

    </div>
  );
});

export default ProductDetailsSectionK;
