import {useState, useCallback, default as React} from 'react';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useNavigate } from "react-router-dom";
import { Trans } from 'react-i18next';
import { selectFeedback} from './userSlice';
import { useAppDispatch } from '../../app/hooks';
import { createUser } from './actions';
import { AddUserForm  } from './components/AddUserForm';
import { Feedback } from '../../components/Feedback';

export const AddUser: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [localUser, setLocalUser] = useState<PendingUser>({
    firstName: '',
    lastName: '',
    email: '',
    imageUrl: '',
    role: 'USER',
    password: '',
    passwordConfirmation: '',
  });
  const feedback = useSelector(selectFeedback);

  // Form edits to local data
  const onChange = useCallback((evt) => {
    const target = evt.target;
    const name = target.name;
    let value = '';
    switch(target.type) {
      case 'radiobutton':
        value = target.checked;
        break;
      case 'checkbox':
        value = target.checked ? target.value : null;
        break;
      default:
        value = target.value;
    }
    if (localUser) {
      setLocalUser({...localUser, [name]: value });
    }
  }, [localUser]);

  // Submit
  const onSubmit = useCallback((file:File|null) => {
    if (!localUser) { return false; }
    dispatch(createUser({
      user: {
        ...localUser,
        role: localUser.role ? localUser.role : 'USER',
      },
      image: file,
    }))
    .then(unwrapResult)
    .then(() => {
      navigate('/users');
    })
    .catch((err) => {
      console.error(err);
    });
  }, [dispatch, localUser, navigate]);

  const onRemoveImage = useCallback(() => {
    if (!localUser) { return; }
    setLocalUser({...localUser, imageUrl: '' });
  }, [localUser]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to={"/users"} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4"><Trans i18nKey="add employee" /></span>
          </div>
        </div>
      </div>

      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
        {localUser && (
          <AddUserForm
            user={localUser}
            onChange={onChange}
            onSubmit={onSubmit}
            onRemoveImage={onRemoveImage}
          />
        )}
        <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default AddUser;
