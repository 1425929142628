import * as React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { thumbnailUrl } from '../../utils';

export interface Props {
  swatch: Swatch;
  onSelect?: Function;
}

export const Card: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { swatch } = props;

  const onClick = (evt: React.MouseEvent<HTMLImageElement|HTMLAnchorElement>) => {
    // When props onClick is set prevent the Link from navigating
    if (props.onSelect) {
      evt.stopPropagation();
      evt.preventDefault();
      props.onSelect(swatch);
    }
  };

  return (
    <div className="o-card">
      <Link
        onClick={onClick}
        to={`/swatches/edit/${swatch.id}`}
      >
        <div className="o-card__image">
          <img
            src={thumbnailUrl(swatch.imageUrl)}
            alt={t('no image')}
            onClick={onClick}
            className={"o-card__img"}
          />
        </div>
        <div className="o-card__name">
          {swatch.name}
        </div>
      </Link>
    </div>
  )
};

export default Card;
