import * as React from 'react';
import { useParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSwatches } from './actions';
import { Card } from './Card';
import { selectSwatches, selectPaging, selectSearchFeedback } from './swatchSlice';
import { Pagination } from '../../components/Pagination';

export interface Props {
  onSelect?: Function;
}

export const    List: React.FC<Props> = (props: Props) => {
  const swatches = useSelector(selectSwatches);
  const feedback = useSelector(selectSearchFeedback);
  // const { category: categoryParm } = useParams<{ category?: string }>();
  const { category: categoryParm } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const paging = useSelector(selectPaging);

  React.useEffect(() => {
    // If category is set in the url let the swatch search component make the
    // initial list call. @TODO needs better solution
    if (categoryParm && categoryParm !== '') {
      return;
    }
    dispatch(getSwatches({ page: 1 }));
  }, [dispatch, categoryParm]);


  const onPaging = (page:number) => {
    dispatch(getSwatches({ page }));
  }

  let feedbackMessage = '';
  if (feedback && !feedback.isWaiting && feedback.isSearchResult === true) {
    feedbackMessage = feedback.count === 0 ? 'no search result' : 'search result count';
  }

  return (
    <>
      {feedbackMessage && (
        <div className="p-4 mb-4 font-bold">
        {t(feedbackMessage, { count: (feedback && feedback.count) || 0})}
        </div>
      )}
      {/* ITEMS */}
      <div className="grid grid-cols-1 md:grid-cols-5 gap-4  auto-rows-max">
        {swatches && swatches.map(c => <Card key={c.id} swatch={c} onSelect={props.onSelect} />)}
      </div>
      {/* PAGER */}
      <Pagination meta={paging} onChange={onPaging}/>
    </>
  );
}

export default List;
