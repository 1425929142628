import { Link } from "react-router-dom";
import { DateTime } from 'luxon';
import { Trans } from 'react-i18next';
import uniqueId from 'lodash/uniqueId'
import { userDisplayName } from '../../../features/user/userSlice';
import { ActionsDropdown } from '../ActionsDropdown';

export interface Props {
  project: Project;
  inputProps?: CheckboxProps;
  children?: React.ReactNode;
}

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  id?: string;
  name?: string;
  checked: boolean;
  onChange: React.FormEventHandler;
}

export const Checkbox: React.FC<CheckboxProps> = (props:CheckboxProps) => {
  const id= props.id || uniqueId('project-checkbox-');
  return (
    <input
      type="checkbox"
      {...props}
      id={props.id || id}
      name={props.name || id}
    />
  );
};

export const ValidationSummary: React.FC<Props> = ({ project }: Props) => {
  const { validation } = project;
  if (!Array.isArray(validation)) {
    return null;
  }
  if (validation.length === 0) {
    return (
      <i className="fas fa-check"></i>
    );
  }
  return (
    <Link to={`/projects/edit/${project.id}`}>
      <i className="fas fa-exclamation-circle"></i>
      <span className="ml-2">
       {validation.length}&nbsp;
       <Trans i18nKey="notifications" />
      </span>
    </Link>
  );
};

export const Row: React.FC<Props> = (props: Props) => {
  const { project } = props;
  const dt = DateTime.fromISO(project.updatedAt, { setZone: false });
  const updatedAtDate = dt.toFormat('dd MMMM´yy', { locale: 'nl-be'});
  const updatedAtDateTime = dt.toLocaleString(DateTime.DATETIME_FULL);
  const showCheckbox = typeof props.inputProps !== 'undefined';

  return (
    <>
    <tr className={`o-table__row`}>
      {typeof props.inputProps !== 'undefined' && (
        <td>
          <Checkbox
            {...props.inputProps}
          />
        </td>
      )}
      <td><Link to={`/projects/edit/${project.id}/project-details`}>{project.firstName} {project.lastName}</Link></td>
      <td>{project.leestnr}</td>
      <td>{project.product && project.product.name}</td>
      <td className="capitalize"><span title={`${updatedAtDateTime}`}>{updatedAtDate}</span></td>
      <td>{userDisplayName(project.user)}</td>
      <td>
        <ValidationSummary project={project} />
      </td>
      {!showCheckbox &&
        <td className="text-right">
          <ActionsDropdown project={project} />
        </td>
      }
    </tr>
    {props.children}
    </>
  )
};

export default Row;
