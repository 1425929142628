import {useState, useCallback, default as React} from 'react';
import { Trans } from 'react-i18next';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { selectFeedback} from './supplierSlice';
import { useAppDispatch } from '../../app/hooks';
import { createSupplier } from './actions';
import { AddForm  } from './components/AddForm';
import { Feedback } from '../../components/Feedback';

export const AddSupplier: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [localSupplier, setLocalSupplier] = useState<PendingSupplier>({
    name: '',
    emails: [''],
  });
  const feedback = useSelector(selectFeedback);

  // Form edits to local data
  const onChange = useCallback((evt) => {
    if (!localSupplier) { return; }
    const target = evt.target;
    setLocalSupplier({...localSupplier, [target.name]: target.value });
  }, [localSupplier]);

  // Submit
  const onSubmit = useCallback(() => {
    if (!localSupplier) { return false; }
    dispatch(createSupplier({ supplier: localSupplier }))
    .then(unwrapResult)
    .then(() => {
      navigate('/suppliers');
    })
    .catch(() => {});
  }, [dispatch, navigate, localSupplier]);

  const onListItemChange = useCallback((name: keyof PendingSupplier, value: any, index:number) => {
    if (!localSupplier) { return; }
    const newList:string[] = Object.assign([], localSupplier[name]);
    newList[index] = value;
    setLocalSupplier({...localSupplier, [name]: newList });
  }, [localSupplier]);

  const onAddListItem = useCallback((name: keyof PendingSupplier) => {
    if (!localSupplier) { return; }

    // Add item to array
    const newList:string[] = Object.assign([], localSupplier[name]);
    newList.push('');

    // Update local supplier
    setLocalSupplier({
      ...localSupplier,
      [name]: newList,
    });
  }, [localSupplier]);

  const onRemoveListItem = useCallback((name: keyof PendingSupplier, index:number) => {
    if (!localSupplier) { return; }

    // Add item to array
    const newList:string[] = Object.assign([], localSupplier[name]);
    newList.splice(index, 1);

    // Update local supplier
    setLocalSupplier({
      ...localSupplier,
      [name]: newList,
    });
  }, [localSupplier]);

  return (
    <>
      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to={"/suppliers"} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4"><Trans i18nKey="create supplier" /></span>
          </div>
        </div>
      </div>

      <div className={"o-page__main"}>
        <div className="mx-auto sm:w-2/3 xl:w-1/3">
        {localSupplier && (
          <AddForm
            supplier={localSupplier}
            onChange={onChange}
            onAdd={onAddListItem}
            onRemove={onRemoveListItem}
            onListItemChange={onListItemChange}
            onSubmit={onSubmit}
          />
        )}
        <Feedback feedback={feedback} />
        </div>
      </div>
    </>
  )
};

export default AddSupplier;
