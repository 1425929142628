import { errorToast } from '../features/toast/toastSlice';

export function handleServiceError(err:any, dispatch?:any) {
  if (err.message && dispatch) {
    if (Array.isArray(err.message)) {
      err.message.map((c:string) => dispatch(errorToast(c)));
    } else if (typeof err.message === 'string') {
      dispatch(errorToast(err.message));
   }
  }
  console.log(err);
  return err;
}
