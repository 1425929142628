import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Link, useParams, useNavigate } from "react-router-dom";
import { selectSupplier } from './supplierSlice';
import { removeSupplier, getSupplier } from './actions';
import { Button } from '../../components/Button';
import { useAppDispatch } from '../../app/hooks';

export const RemoveSupplier: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const supplier = useSelector(selectSupplier);

  useEffect(() => {
    if (typeof id !== 'string') { return; }
    dispatch(getSupplier(id));
  }, [dispatch, id]);

  const onRemove = () => {
    if (typeof id !== 'string') { return; }
    dispatch(removeSupplier(id))
    .then(unwrapResult)
    .then(() => {
      navigate('/suppliers');
    })
    .catch(() => {});
  }

  if (!supplier) {
    return null;
  }

  return (
    <>
      <div>
        <Link to="/suppliers">
          <Trans i18nKey="back" />
        </Link>
        <h2>
          <Trans i18nKey="remove supplier" values={{ name: supplier.name }} />?
        </h2>
      </div>
      <div>
        <Link to="/suppliers">
          <Trans i18nKey="back" />
        </Link>
        <Button
          onClick={onRemove}
          className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
          <Trans i18nKey="remove" />
        </Button>
      </div>
    </>
  )
};

export default RemoveSupplier;
