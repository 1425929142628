import { createAsyncThunk } from '@reduxjs/toolkit';
import supplierService from './supplierService';
import { successToast } from '../toast/toastSlice';
import { handleServiceError  } from '../../api/utils';

export const updateSupplier = createAsyncThunk<any, {supplier: Supplier }, { rejectValue: string }>(
  'suppliers/updateSupplier',
  async ({ supplier }, { rejectWithValue }) => {
    try {
      return await supplierService().updateSupplier({
        ...supplier,
        emails: supplier.emails.filter(c => c.trim().length > 0),
      });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getSuppliers = createAsyncThunk<any, PageInfo, { rejectValue: string }>(
  'suppliers/getSuppliers',
  async (pageInfo, { rejectWithValue }) => {
    try {
      return await supplierService().findAll({
        page: 1, // default paging value
        limit: 15, // default paging value
        ...pageInfo,
      });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getSupplier = createAsyncThunk<any, string, { rejectValue: string }>(
  'suppliers/getSupplier',
  async (id, { rejectWithValue }) => {
    try {
      return await supplierService().getSupplier({ id });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const createSupplier = createAsyncThunk<any, { supplier: PendingSupplier}, { rejectValue: string, dispatch: any }>(
  'suppliers/createSupplier',
  async ({ supplier }, { rejectWithValue, dispatch }) => {
    try {
      const newSupplier:any = await supplierService().createSupplier(supplier);
      dispatch(successToast('api.created'));
      return newSupplier;
    } catch (err:any) {
      handleServiceError(err);
      return rejectWithValue(err);
    }
  },
);

export const removeSupplier = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'suppliers/removeSupplier',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await supplierService().removeSupplier({ id });
      dispatch(successToast('api.removed'));
      return id;
    } catch (err:any) {
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

