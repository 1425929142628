import { createAsyncThunk } from '@reduxjs/toolkit';
import userService from './userService';
import { successToast } from '../toast/toastSlice';
import { handleServiceError  } from '../../api/utils';

const onUploadProgress = () => {};

export const updateUser = createAsyncThunk<any, {user: User, image?: File|null}, { rejectValue: string }>(
  'users/updateUser',
  async ({ user, image }, { rejectWithValue }) => {
    try {
      await userService().updateUser(user);
      if (image && user.id) {
        await userService().upload(user.id, image, onUploadProgress);
      }
      return await userService().getUser({ id: user.id });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getUsers = createAsyncThunk<any, PageInfo, { rejectValue: string }>(
  'users/getUsers',
  async (pageInfo, { rejectWithValue }) => {
    try {
      return await userService().findAll({
        page: 1, // default paging value
        limit: 15, // default paging value
        ...pageInfo,
      });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getUser = createAsyncThunk<any, string, { rejectValue: string }>(
  'users/getUser',
  async (id, { rejectWithValue }) => {
    try {
      return await userService().getUser({ id });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const createUser = createAsyncThunk<any, { user: PendingUser, image?: File|null, cb?: Function}, { rejectValue:
  string, dispatch: any }>(
  'users/createUser',
  async ({ user, image, cb }, { rejectWithValue, dispatch }) => {
    try {
      // if (image && result && result.id) {
      const result:any = await userService().createUser(user);
      if (image && result && result.id) {
        await userService().upload(result.id, image, onUploadProgress);
      }
      const newUser = await userService().getUser({ id: result.id });
      if (cb) {
        cb(newUser);
      }
      dispatch(successToast('api.created'));
      return newUser;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const removeUser = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'users/removeUser',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await userService().removeUser({ id });
      dispatch(successToast('api.removed'));
      return id;
    } catch (err:any) {
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }

  },
);

export const setImage = createAsyncThunk<any, {user: User, image: File|null, cb?: Function}, { rejectValue: string }>(
  'users/setImage',
  async ({user, image, cb}, { rejectWithValue }) => {
    try {
      if (image) {
        const uploadResult = await userService().upload(user.id, image, onUploadProgress);
        if (typeof uploadResult === 'string') {
          user.imageUrl = uploadResult;
        }
        if (cb) {
          cb(user);
        }
        return user;
      }
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const removeImage = createAsyncThunk<any, {user: User, cb?: Function}, { rejectValue: string }>(
  'users/removeImage',
  async ({user, cb}, { rejectWithValue }) => {
    try {
      await userService().removeImage(user.id);
      if (cb) {
        cb(user);
      }
      return user;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

