import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Trans } from 'react-i18next';
import { selectUsers, selectPaging } from './userSlice';
import { Row } from './Row';
import { getUsers } from './actions';
import { Pagination } from '../../components/Pagination';

export function Users() {
  const users = useSelector(selectUsers);
  const paging = useSelector(selectPaging);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getUsers({ page: 0 }));
  }, [dispatch]);

  const onPaging = (page:number) => {
    dispatch(getUsers({ page }));
  }

  return (

    <div className="o-page__main">
      <div className="o-container">
        <div className="o-page__actions">
              <h1>
                <Trans i18nKey="employees" />
              </h1>
              <div className="flex items-center">
            <Link to={'/users/add'} className="o-button">
              <i className="fa fa-plus"></i>
              <span>Medewerker aanmaken</span>
            </Link>
              </div>
        </div>
      <>
        <div className="o-table">
          <table>
            {/* TABLE HEADER */}
            <tr className="o-table__header grid-cols-4 ">
              <th>
                <Trans i18nKey="name" />
              </th>
              <th>
                <Trans i18nKey="role" />
              </th>
              <th></th>
            </tr>
            <tbody>
            {users && users.map(c => <Row key={c.id} user={c} />)}
            </tbody>
          </table>
        </div>

        {/* PAGER */}
        <Pagination meta={paging} onChange={onPaging}/>
      </>
      </div>
    </div>
  );
}
