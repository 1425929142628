import { request, upload, downloadPdf, downloadXlsx } from '../../api';
import config from '../../config';
import { DateTime } from 'luxon';

const baseUrl = `${config.baseUrl}/project`;
const service = () => {
  return {
    findAll: (pageInfo:PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    findOne: (id:string) => request(`${baseUrl}/${id}`, 'get'),
    create: (project:PendingProject) => request(`${baseUrl}`, 'post', {
      ...project,
      user: {
        id: project && project.user ? project.user.id : '',
      }
    }),
    update: (project:Project|PendingProject) => request(`${baseUrl}/${project.id}`, 'patch', project),
    remove: (id:string) => request(`${baseUrl}/${id}`, 'delete'),
    upload: (id:string, file:any, onUploadProgress:any, variant?:string) => upload(`${baseUrl}/${id}/image/${variant || ''}`, file, onUploadProgress),
    search: (options:ProjectSearchOptions) => request(`${baseUrl}/search`, 'get', {
      ...options,
      supplier: (options.supplier && options.supplier.id) || null,
    }),
    sendToSupplier: (ids:number[]) => request(`${baseUrl}/send-to-supplier`, 'post', { projects: ids }),
    exportPdf: (id:number) => downloadPdf(`${baseUrl}/${id}/export/pdf`),
    exportXlsx: () => downloadXlsx(`${baseUrl}/export/today`),
  };
};


export function projectFilename(project:Project|PendingProject):string {
  const customer = (`${project.firstName} ${project.lastName}`).trim();
  return `${customer} ${project.leestnr} ${DateTime.now().toISODate()}.pdf`
}

export default service;

