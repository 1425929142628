import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useNavigationType } from "react-router-dom";
import { Sketch} from '../../../components/Sketch';
import { selectPending, setPending } from '../projectSlice';
import { generatePath } from '../utils';

interface Props {
  view:string;
}

export const ProductAnnotation: React.FC<Props> = ({ view }:Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const project = useSelector(selectPending);
  const sketchRef = React.useRef<any>();
  //
  const product:Product|null = (project && project.product) || null;
  const baseUrl = generatePath(view, id);

  const [edit, setEdit] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState<string|null>(null);

  // Go to the start of the add project 'wizard' if we do not come from another
  // route (eg: after browser refresh)
  // @TODO.high check after upgrade
  if (navigationType === "REPLACE") {
    console.log('Go back to the start of the flow');
    navigate(baseUrl, {replace: true});
  }

  React.useEffect(() => {
    if (project && project.product && imageUrl === null) {
      setImageUrl(project.imageBase64 || project.imageUrl || project.product?.imageUrl || null);
    }
  }, [project, imageUrl]);

  const onSave = React.useCallback(() => {
    if (!sketchRef?.current || !project ) { return; }
    dispatch(setPending({
      ...project,
      imageBase64: sketchRef.current.toDataURL(),
    }));
    navigate(`${baseUrl}/project-details`);
  }, [dispatch, project, baseUrl, navigate]);

  const onRemove = React.useCallback(() => {
    if (project && project.product)  {
      // Requires the sketch to be cleard after updating the imageUrl
      setImageUrl(project.product.imageUrl || null);
    }
  }, [project]);

  // Clear the sketch after changing the imageRef ( eg: on clear )
  React.useEffect(() => {
    if (imageUrl && sketchRef.current)  {
      sketchRef.current.clear();
    }
  }, [imageUrl, sketchRef]);

  const onAnnotate = React.useCallback(() => {
    setEdit(true);
  }, []);

  return (
    <>

      {/* HEAD */}
      <div className="o-page__header">
        <div className="o-container">
          <div>
            <Link to={`${baseUrl}/project-details`} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
            <span className="ml-4">
              {product && (
                <>
                  <span>{product.name}</span>
                  <span>{product.category}</span>
                </>
              )}
            </span>
          </div>
          <div>
            <button
              className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800"
              type="button"
              onClick={onAnnotate}
            >
              {t('annotate')}
            </button>
            <button
              className="o-button ml-4"
              type="button"
              onClick={onRemove}
            >
              {t('remove')}
            </button>
            <button
              className="o-button ml-4"
              type="button"
              onClick={onSave}
            >
              {t('save')}
            </button>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {(imageUrl) && (
        <>
        <div>
          <Sketch
            ref={sketchRef}
            imageUrl={imageUrl}
            backgroundImageUrl={project?.product?.imageUrl}
            edit={edit}
          />
        </div>

        </>
      )}
    </>
  )
};

export default ProductAnnotation;
