import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components/Button';
import { createPopper } from '@popperjs/core';
import { useTranslation } from 'react-i18next';
import projectService, { projectFilename } from './projectService';

interface Props {
  project: Project;
}

export const exportPdf = async (project:Project|PendingProject) => {
  if (typeof project.id === 'undefined') { return; }
  const result = await projectService().exportPdf(project.id);
  const blob = new Blob([result], {type: 'application/pdf'})
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = projectFilename(project) || 'project.pdf';
  link.click();
}

export const ActionsDropdown: React.FC<Props> = ({project}:Props) => {
  const { t } = useTranslation();
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef<HTMLButtonElement>();
  const popoverDropdownRef = React.createRef<HTMLDivElement>();

  const openDropdownPopover = () => {
    if (btnDropdownRef?.current && popoverDropdownRef?.current) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
      });
      setDropdownPopoverShow(true);
      window.document.addEventListener('mouseup', closeDropdownPopover);
    }
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
    window.document.removeEventListener('mouseup', closeDropdownPopover);
  };

  return (
    <>
      <button
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        ...
      </button>

      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >

        <Link
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100"
          to={`/projects/edit/${project.id}`}
        >
          {t('edit customer')}
        </Link>
        <Link
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100"
          to='/projects/add'
          state={{ duplicate: project.id }}
        >
          {t('duplicate')}
        </Link>

        <Button
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100 text-left"
          onClick={() => exportPdf(project)}
        >
          {t('download as pdf')}
        </Button>

        <Link
          className="py-2 px-4 block w-full whitespace-nowrap bg-transparent hover:bg-gray-100"
          to={`/projects/remove/${project.id}`}
        >
          {t('remove')}
        </Link>
      </div>
    </>
  );
};

export default ActionsDropdown;
