import { Trans } from 'react-i18next';
import { Alert } from '../../components/Alert';

export interface FeedbackProps extends React.HTMLProps<HTMLDivElement> {
  feedback: Feedback;
  showProgress?: boolean;
  children?: React.ReactNode;
}

export const Feedback: React.FC<FeedbackProps> = ({feedback, showProgress, children, className }: FeedbackProps) => {
  if (!feedback || feedback.message === '') {
    return null;
  }
  return (
    <Alert color={feedback.error ? 'red' : 'blue'} className={className}>
      {(showProgress && showProgress === true) && (
        <span className="z-10 h-full leading-snug font-normal text-center bg-transparent rounded text-base items-center justify-center w-8 right-2 pr-2 py-3">
          <i className="fas fa-circle-notch animate-spin"></i>
        </span>
      )}
      <Trans i18nKey={feedback.message} />
      {children}
    </Alert>
  );
}

export default Feedback;
