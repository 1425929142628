import { createAsyncThunk } from '@reduxjs/toolkit';
import projectService from './projectService';
import { successToast } from '../toast/toastSlice';
import { authenticationState } from '../authentication/authenticationSlice';
import { handleServiceError  } from '../../api/utils';

const onUploadProgress = () => {};

export const getProjects = createAsyncThunk<any, PageInfo, { rejectValue: string }>(
  'projects/getProducts',
  async (pageInfo, { rejectWithValue }) => {
    try {
      return await projectService().findAll({
        page: 1, // default paging value
        limit: 15, // default paging value
        ...pageInfo,
      });
    } catch (err:any) {
      return rejectWithValue(err);
    }
  },
);

export const getProject = createAsyncThunk<any, string, { rejectValue: string }>(
  'projects/getProject',
  async (id, { rejectWithValue }) => {
    try {
      return await projectService().findOne(id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const removeProject = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'projects/removeProject',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await projectService().remove(id);
      dispatch(successToast('api.removed'));
      return id;
    } catch (err:any) {
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const updateProject = createAsyncThunk<any, {project: PendingProject, cb?: Function, image?: File|null}, { rejectValue: string,
  dispatch: any }>(
  'projects/updateProject',
  async ({project, cb, image}, { rejectWithValue, dispatch }) => {
    try {
      if (typeof project.id === 'undefined') {
        return rejectWithValue('Project has no id');
      }
      const data = {
        ...project,
        properties: {
          ...project.properties,
          // Empty deprecated project properties
          'leverancier-keuze': '',
        }
      };
      if (!data.supplier) {
        data.supplier = null;
      }
      if (typeof data.imageBase64 !== 'undefined') {
        delete data.imageBase64;
      }
      if (data.validation) {
        delete data.validation;
      }
      const result:any = await projectService().update(data);
      // Annotated product image
      if (project.imageBase64 && result && result.id) {
        const file = await fetch(project.imageBase64);
        const blob = await file.blob();
        const uploadResult = await projectService().upload(result.id, blob, onUploadProgress);
        if (typeof uploadResult === 'string') {
          result.imageUrl = uploadResult;
        }
      }
      // Prescription image
      if (image && result && result.id) {
        await projectService().upload(result.id, image, onUploadProgress, 'prescriptionImageUrl');
      }

      const newProject = await projectService().findOne(result.id);
      if (cb) {
        cb(newProject);
      }
      dispatch(successToast('api.updated'));
      return newProject;
    } catch (err:any) {
      handleServiceError(err);
      return rejectWithValue(err);
    }
  },
);

export const createProject = createAsyncThunk<any, {project: PendingProject, cb?: Function, image?: File|null}, { rejectValue: string,
  getState:Function, dispatch: any }>(
  'projects/createProject',
  async ({project, cb, image}, { rejectWithValue, getState, dispatch }) => {
    try {
      const data = { ...project };
      const { authentication } = getState() as { authentication: authenticationState };
      if (!data.user || typeof data.user.id === 'undefined') {
        data.user = authentication.user;
      }
      if (!data.supplier) {
        data.supplier = null;
      }
      if (typeof data.imageBase64 !== 'undefined') {
        delete data.imageBase64;
      }
      const result:any = await projectService().create(data);
      // Annotated product image
      if (project.imageBase64 && result && result.id) {
        const file = await fetch(project.imageBase64);
        const blob = await file.blob();
        const uploadResult = await projectService().upload(result.id, blob, onUploadProgress);
        if (typeof uploadResult === 'string') {
          result.imageUrl = uploadResult;
        }
      }
      // Prescription image
      if (image && result && result.id) {
        await projectService().upload(result.id, image, onUploadProgress, 'prescriptionImageUrl');
      }

      const newProject = await projectService().findOne(result.id);

      if (cb) {
        cb(newProject);
      }
      dispatch(successToast('api.created'));
      return newProject;
    } catch (err:any) {
      handleServiceError(err);
      return rejectWithValue(err);
    }
  },
);

export const searchProject = createAsyncThunk<any, ProjectSearchOptions, { rejectValue: string }>(
  'projects/searchProduct',
  async (projectSearchOptions, { rejectWithValue }) => {
    try {
      const result = await projectService().search({
        page: 1, // default paging value
        limit: 15, // default paging value
        ...projectSearchOptions,
      });
      return result;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const duplicateProject = createAsyncThunk<any, string, { rejectValue: string }>(
  'projects/duplicateProject',
  async (id, { rejectWithValue }) => {
    try {
      const result = await projectService().findOne(id);
      return result;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const sendToSupplier = createAsyncThunk<any, { projects: number[], cb?: Function }, { rejectValue: string }>(
  'projects/sendToSupplier',
  async ({projects, cb}, { rejectWithValue }) => {
    try {
      const result = await projectService().sendToSupplier(projects);
      if (cb) {
        cb(result);
      }
      return result;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);
