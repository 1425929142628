import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Trans} from 'react-i18next';
import {Link, useNavigate, useParams, useNavigationType, useLocation} from "react-router-dom";
import {selectPending, setPendingProduct, setPendingProjectProperty} from './projectSlice';
import {List as ProductList} from '../products/List';
import {Search as ProductSearch} from '../products/Search';
import {ProjectCtx} from './ProjectContext';
import {generatePath} from './utils';

interface Props {
  view: string;
}

export const SelectProduct: React.FC<Props> = ({view}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  const navigationType = useNavigationType();
  const location = useLocation();
  const projectContext = React.useContext(ProjectCtx);
  const project = useSelector(selectPending);
  const baseUrl = projectContext.baseUrl || generatePath(view, id);
  const locationState = location.state as {
    backTo: string | null,
    // Optional key in Project.properties to set the selected Product to
    // Otherwise the Product wil be set on Project.Product
    setProperty?: keyof ProjectProperties,
  };
  // @TODO.high check after upgrade
  const backUrl = locationState && locationState.backTo ? `${baseUrl}${locationState.backTo}` : baseUrl;

  // Go to the start of the add project 'wizard' if we do not come from another
  // route (eg: after browser refresh)
  // @TODO.high check after upgrade
  if (navigationType === "REPLACE") {
    console.log('Go back to the start of the flow');
    navigate(baseUrl, {replace: true});
  }

  const onSelectProduct = (product: Product) => {
    // Store the selected Product on a key in Project.properties
    if (locationState && locationState.setProperty) {
      dispatch(
          setPendingProjectProperty(
              {
                property: locationState.setProperty,
                item: product
              },
          )
      );
      return navigate(backUrl);
    }
    // Store the selected Product on the Project
    dispatch(setPendingProduct(product));
    navigate(`${baseUrl}/project-details`);
  }

  return (
      <>
        {/* HEAD */}
        <div className="o-page__header">
          <div className="o-container">
            <div>
              <Link to={backUrl} className="o-action__icon"><i className="fa fa-arrow-left"></i></Link>
              {project && <span className="ml-4 font-bold">{project.firstName} {project.lastName}</span>}
              {project && <span className="ml-2 text-gray-400">{project.leestnr}</span>}
            </div>
          </div>
        </div>
        {/* ACTIONS */}

        <div className="o-page__main">
          <div className="o-container">
            <div className="o-page__actions">
              <h1>
                <Trans i18nKey="select your model" />
              </h1>
              <div className="flex items-center">
                <ProductSearch/>
                <Link
                    to={`${baseUrl}/product-upload`}
                    className="bg-gray-500 pl-6 pr-6 text-white rounded-full p-3 hover:bg-gray-800"
                >
                  <Trans i18nKey="upload photo" />
                </Link>
              </div>
            </div>

            {/* ITEMS */}
            <ProductList onSelect={onSelectProduct}/>
          </div>
        </div>
      </>
  )
};

export default SelectProduct;
