import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useNavigationType } from "react-router-dom";
import { selectFeedback } from '../../products/productsSlice';
import { AddProductForm } from '../../products/components/AddProductForm';
import { createProduct } from '../../products/actions';
import { setPendingProduct  } from '../projectSlice';
import { Feedback } from '../../../components/Feedback';
import { generatePath } from '../utils';

interface Props {
  view: string;
}

export const ProductUpload: React.FC<Props> = ({ view }:Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const navigationType = useNavigationType();
  const feedback = useSelector(selectFeedback);
  const baseUrl = generatePath(view, id);

  // Go to the start of the add project 'wizard' if we do not come from another
  // route (eg: after browser refresh)
  // @TODO.high check after upgrade
  if (navigationType === "REPLACE") {
    console.log('Go back to the start of the flow');
    navigate(baseUrl, {replace: true});
  }

  const [product, setProduct] = React.useState<PendingProduct>({
    name: '',
    category: '',
    imageUrl: '',
  });

  const onChange = React.useCallback(({ target }) => {
    setProduct({...product, [target.name]: target.value });
  }, [product]);

  // Create the new product, attach the new product the new project and show
  // the confirm model view
  const onSubmit = React.useCallback((file:File|null) => {
    dispatch(createProduct({
      product,
      image: file,
      // Called after the new product and image is uploaded to the backend
      cb: (product:Product) => {
        dispatch(setPendingProduct(product));
        navigate(`${baseUrl}/confirm-product`);
      }
    }));
  }, [dispatch, product, navigate, baseUrl]);


  const onRemoveImage = React.useCallback(() => {
    if (!product) { return; }
    // Set new product as product for the new project
    setProduct({...product, imageUrl: '' });
  }, [product]);

  return (
    <>
      {/* HEAD */}
      <div>
        <Link
        to={`${baseUrl}/select-product`}
        >{t('back')}</Link>
        {t('upload photo')}</div>
        <AddProductForm
          product={product}
          onChange={onChange}
          onSubmit={onSubmit}
          onRemoveImage={onRemoveImage}
          backLinkUrl={`${baseUrl}/select-product`}
        />
        <Feedback feedback={feedback} />
    </>
  )
};

export default ProductUpload;
