import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ValueItem, RadioButton } from './FormElements';

interface Props {
  properties: ProjectProperties | undefined;
  onChange: React.FormEventHandler;
}

const values:ValueItem[] = ([
  { value: 'Leder' },
  { value: 'Zebra' },
  { value: 'Bijleveren' },
]);

const sectionName = 'Hakovertrek';
const sectionId = sectionName.replace(/\s/g, '').toLowerCase();

export const ProductDetailsSectionF: React.FC<Props> = React.memo(({onChange, properties}:Props) => {
  const { t } = useTranslation();
  if (!properties) { return null; }
  return (
    <div className="o-section" id={sectionId}>
      <h2>{t(sectionName)}</h2>
      <div className="mt-6 print:mt-0">
        {/** INPUT */}
        { values.map((c, idx) => (
          <div key={c.value} >
            <RadioButton
              name="hakovertrek"
              checked={properties['hakovertrek'] === c.value}
              onChange={onChange}
              value={c.value}
              properties={properties}
              id={`${sectionName}-${idx}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default ProductDetailsSectionF;
