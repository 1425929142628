import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { List } from './List';
import { Search } from './Search';

export const CATEGORIES:IDict = {
  rand: 'Rand',
  zool: 'Zool',
}

export function Swatches() {
  const { t } = useTranslation();
  return (
    <section className="o-page__main">
        <div className="o-container">
            {/* HEADER */}
            <div className="o-page__actions">
                <h1>{t('swatches')}</h1>
                <div className="flex items-center">
                    <Search />
                    <Link to={'/swatches/add'} className="o-button">
                      <i className="fa fa-plus"></i>
                      <span>{t('add swatch')}</span>
                    </Link>
                </div>
            </div>
            {/* ITEMS */}
            <div className="flex flex-wrap">
                <List />
            </div>
        </div>
    </section>
  );
}

export default Swatches;
