import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from '../../../components/Button';
import ImageUploading, { ImageListType } from "react-images-uploading";

export interface Props {
  project: PendingProject|Project|null,
  onChange: React.FormEventHandler,
  onSubmit: Function,
  submitLabel?: string,
  onRemoveImage: Function,
  onImageChange?: Function,
};

export type Ref = HTMLFormElement;

export const CustomerForm = React.forwardRef((
    props: Props,
    ref: React.Ref<HTMLFormElement>,
  ) => {
  const { project, onChange } = props;
  const [images, setImages] = React.useState<ImageListType>([]);
  const onImageChange = (
    imageList: ImageListType,
  ) => {
    setImages(imageList);
    if (typeof props.onImageChange === 'function') {
      props.onImageChange(imageList);
    }
  };

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit((images[0] && images[0].file) || null);
    return false;
  }

  if (!project) { return null; }
  const imageUrl = images[0] && images[0].data_url ? images[0].data_url : project.prescriptionImageUrl;
  const prescriptionImageIsPdf = typeof project.prescriptionImageUrl === 'string' &&
    project.prescriptionImageUrl.toLowerCase().indexOf('.pdf') === project.prescriptionImageUrl.length - 4;
  return (
    <div className={"w-full bg-white p-5 md:py-8 md:px-12"}>
      {/* FORM */}
      <h2>
        <Trans i18nKey="client data" />
      </h2>
      <form
        ref={ref}
        onSubmit={onSubmit}
      >
          <div className="md:flex gap-4">
              <div className="mb-3">
                  <label>
                    <Trans i18nKey="firstName" />
                  </label>
                  <input
                      name="firstName"
                      type="text"
                      className="o-input__text"
                      onChange={onChange}
                      value={project.firstName}
                      required={true}
                  />
              </div>
              <div className="mb-3">
                  <label>
                    <Trans i18nKey="lastName" />
                  </label>
                  <input
                      name="lastName"
                      type="text"
                      className="o-input__text"
                      onChange={onChange}
                      value={project.lastName}
                      required={true}
                  />
              </div>
          </div>

        <div className="mb-3">
          <label>
            <Trans i18nKey="leestnr" />
          </label>
          <input
            name="leestnr"
            type="text"
            className="o-input__text w-full"
            onChange={onChange}
            value={project.leestnr}
            required={true}
          />
        </div>
          <div className="md:flex gap-4">
              <div className="mb-3 flex-1">
                <label>
                  <Trans i18nKey="birthdate" />
                </label>
                <input
                    name="birthdate"
                    type="date"
                    className="o-input__text"
                    onChange={onChange}
                    value={project.birthdate}
                    required={true}
                />
              </div>
              <div className="mb-3 flex-1">
                  <label>
                    <Trans i18nKey="gender" />
                  </label>
                  <div className="flex">
                      <div className="flex-1 o-input__radiobutton">
                          <input
                              name="gender"
                              value="MALE"
                              type="radio"
                              checked={project.gender === "MALE"}
                              onChange={onChange}
                              id="project-gender-male"
                          />
                          <label
                              htmlFor="project-gender-male">
                              <Trans i18nKey="male" />
                          </label>
                      </div>
                      <div className="flex-1 o-input__radiobutton">
                        <input
                            name="gender"
                            type="radio"
                            value="FEMALE"
                            checked={project.gender === "FEMALE"}
                            onChange={onChange}
                            id="project-gender-female"
                        />
                        <label
                          htmlFor="project-gender-female">
                          <Trans i18nKey="female" />
                        </label>
                      </div>
                  </div>
              </div>
          </div>

          <h3 className="mt-8">
            <Trans i18nKey="prescription" />
          </h3>
          <div className="mb-3">
            <label htmlFor="project-doctor">
              <Trans i18nKey="doctor" />
            </label>
            <input
              name="doctor"
              id="project-doctor"
              type="text"
              className="o-input__text w-full"
              onChange={onChange}
              value={project.doctor || ''}
            />
          </div>

          <div className="mb-3">
            <ImageUploading
              value={images}
              onChange={onImageChange}
              dataURLKey="data_url"
              acceptType={['jpg', 'gif', 'png', 'pdf']}
            >
              {({
                onImageUpload,
                onImageRemoveAll,
                isDragging,
                dragProps,
              }) => (
                // Make sure buttons have type="buttons" (the default type is "submit")
                <div className="upload__image-wrapper">
                  <button
                    style={isDragging ? { color: 'red' } : undefined}
                    onClick={onImageUpload}
                    className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800"
                    type="button"
                    {...dragProps}
                  >
                    <i className="fa fa-image"></i>
                    <span>
                      <Trans i18nKey="select prescription" />
                    </span>
                  </button>
                  &nbsp;
                  <button
                    onClick={() => { onImageRemoveAll(); props.onRemoveImage() }}
                    type="button"
                    className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300">
                    <Trans i18nKey="remove prescription" />
                  </button>
                  {/** prescription image */}
                  {(imageUrl && !prescriptionImageIsPdf) && (
                    <img onClick={onImageUpload} src={imageUrl} alt="" width="512"/>
                  )}
                  {/** prescription image */}
                  {(imageUrl && prescriptionImageIsPdf) && (
                    <div className='mt-8'>
                      <a
                      className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300"
                      href={imageUrl} target="_blank" rel="noreferrer">Voorschrift openen</a>
                    </div>
                  )}
                </div>
              )}
            </ImageUploading>
          </div>

        <Button className="o-button hidden" type="submit">
          <Trans i18nKey={props.submitLabel ? props.submitLabel : 'select model'} />
        </Button>

      </form>
    </div>
  )
});

export default CustomerForm;
