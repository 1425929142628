import * as React from 'react';
import {Link} from "react-router-dom";
import {thumbnailUrl} from '../../utils';

export interface Props {
  product: Product;
  onSelect?: Function;
}

export const Card: React.FC<Props> = (props: Props) => {
  const {product} = props;

  const onClick = (evt: React.MouseEvent<HTMLImageElement|HTMLAnchorElement>) => {
    // when props onClick is set prevent the Link from navigating
    if (props.onSelect) {
      evt.stopPropagation();
      evt.preventDefault();
      props.onSelect(product);
    }
  };

  return (
      <div className="o-card">
        <Link
          onClick={onClick}
          to={`/products/edit/${product.id}`}
        >
          <div className="o-card__image">
            <img
              src={thumbnailUrl(product.imageUrl)}
              alt={product.name}
              onClick={onClick}
              className={"o-card__img"}
            />
          </div>
          <div className="o-card__name">{product.name}</div>
        </Link>
      </div>
  )
};

export default Card;
