import * as React from 'react';
import { Button } from '../../../components/Button';
import { useTranslation } from 'react-i18next';

export interface FormProps {
  leather: Leather,
  onChange: React.FormEventHandler,
  onSubmit: React.FormEventHandler,
};

export const EditForm: React.FC<FormProps> = (props:FormProps) => {
  const { leather, onChange, onSubmit } = props;
  const { t } = useTranslation();

  return (
    <div className={"min-w-min mx-auto bg-white py-8 px-12"}>
      {/* FORM */}
      <h2>Gegevens van het leder</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label className="mr-2">{t('m-code')}</label>
          <input
            name="code"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={leather.code}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">{t('article')}</label>
          <input
            name="article"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={leather.article}
            required={true}
          />
        </div>
        <div className="mb-3">
          <label className="mr-2">{t('color')}</label>
          <input
            name="color"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={leather.color}
            required={true}
          />
        </div>
        <Button className="o-button" type="submit">
          {t('update')}
        </Button>
      </form>
    </div>
  )
};
