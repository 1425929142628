import { request, upload } from '../../api';
import config from '../../config';

const baseUrl = `${config.baseUrl}/swatch`;
const service = () => {
  return {
    findAll: (pageInfo:PageInfo) => request(`${baseUrl}`, 'get', pageInfo),
    findOne: (id:string) => request(`${baseUrl}/${id}`, 'get'),
    create: (swatch:PendingSwatch) => request(`${baseUrl}`, 'post', swatch),
    update: (swatch:Swatch) => request(`${baseUrl}/${swatch.id}`, 'patch', swatch),
    remove: (id:string) => request(`${baseUrl}/${id}`, 'delete'),
    upload: (id:string, file:any, onUploadProgress:any) => upload(`${baseUrl}/${id}/image`, file, onUploadProgress),
    search: (options:SearchOptions) => request(`${baseUrl}/search`, 'get', options),
  };
};

export default service;
