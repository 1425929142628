import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import authenticationReducer from '../features/authentication/authenticationSlice';
import userProfileReducer from '../features/authentication/userProfileSlice';
import projectsReducer from '../features/projects/projectSlice';
import productsReducer from '../features/products/productsSlice';
import leatherReducer from '../features/leather/leatherSlice';
import swatchReducer from '../features/swatch/swatchSlice';
import supplierReducer from '../features/suppliers/supplierSlice';
import toastReducer from '../features/toast/toastSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    authentication: authenticationReducer,
    userProfile: userProfileReducer,
    projects: projectsReducer,
    products: productsReducer,
    leathers: leatherReducer,
    swatches: swatchReducer,
    suppliers: supplierReducer,
    toast: toastReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
