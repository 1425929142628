import * as React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { ProjectCtx } from '../ProjectContext';

interface Props {
  item: string;
  itemKey?: string;
  imageUrl?: string;
  name?: string;
  value: keyof ProjectProperties;
  resetFn?: Function | null;
  className?:string;
}

export const ItemSelector: React.FC<Props> = ({imageUrl, name, value, item, itemKey, resetFn, className}:Props) => {
  const projectContext = React.useContext(ProjectCtx);
  if (!projectContext.baseUrl) {
    return null;
  }
  return (
    <div className="m-2" id={`item-selector-${value}`}>
      { imageUrl && <img src={imageUrl} alt="" className={`max-w-xs w-full mb-4 ${className}`} /> }
      { name && <div>{name}</div> }
      <Link
        to={`${projectContext.baseUrl}/select-${item}`}
        state={{
          backTo: `/project-details#item-selector-${value}`,
          setProperty: value,
          itemKey,
        }}
        className="bg-gray-200 pl-6 pr-6 text-gray rounded-full  inline-block p-3 hover:bg-gray-400 print:hidden"
      >
        <Trans i18nKey="change" />
      </Link>
      {resetFn && <button type="button" className={`print:hidden`} onClick={() => resetFn(value)}><i className="fa fa-undo ml-3"></i></button>}
    </div>
  );
}

export default ItemSelector;
