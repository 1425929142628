import { createAsyncThunk } from '@reduxjs/toolkit';
import leatherService from './leatherService';
import { successToast } from '../toast/toastSlice';
import { handleServiceError  } from '../../api/utils';

export const updateLeather = createAsyncThunk<any, {leather: Leather}, { rejectValue: string }>(
  'leathers/updateLeather',
  async ({leather}, { rejectWithValue }) => {
    try {
      await leatherService().update(leather);
      return await leatherService().findOne(leather.id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getLeathers = createAsyncThunk<any, PageInfo, { rejectValue: string  }>(
  'leathers/getLeathers',
  async (pageInfo, { rejectWithValue }) => {
    try {
      return await leatherService().findAll({
        page: 1, // default paging value
        limit: 15, // default paging value
        ...pageInfo,
      });
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const getLeather = createAsyncThunk<any, string, { rejectValue: string }>(
  'leathers/getLeather',
  async (id, { rejectWithValue }) => {
    try {
      return await leatherService().findOne(id);
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);

export const createLeather = createAsyncThunk<any, {leather: PendingLeather, cb?: Function}, { rejectValue: string,
  dispatch: any }>(
  'leathers/createLeather',
  async ({leather, cb}, { rejectWithValue, dispatch }) => {
    try {
      const result:any = await leatherService().create(leather);
      const newLeather = await leatherService().findOne(result.id);
      if (cb) {
        cb(newLeather);
      }
      dispatch(successToast('api.created'));
      return newLeather;
    } catch (err:any) {
      handleServiceError(err);
      return rejectWithValue(err);
    }
  },
);

export const removeLeather = createAsyncThunk<any, string, { rejectValue: string, dispatch: any }>(
  'leathers/removeLeather',
  async (id, { rejectWithValue, dispatch }) => {
    try {
      await leatherService().remove(id);
      dispatch(successToast('api.removed'));
      return id;
    } catch (err:any) {
      handleServiceError(err, dispatch);
      return rejectWithValue(err);
    }
  },
);

export const searchLeather = createAsyncThunk<any, string, { rejectValue: string }>(
  'leathers/searchLeather',
  async (text, { rejectWithValue }) => {
    try {
      const result = await leatherService().search(text);
      return result;
    } catch (err:any) {
      console.error(err);
      return rejectWithValue(err);
    }
  },
);
