import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { Button } from '../../../components/Button';
import ImageUploading, { ImageListType } from "react-images-uploading";
import { config } from '../../../config';

export interface FormProps {
  product: PendingProduct,
  onChange: React.FormEventHandler,
  onSubmit: Function,
  onRemoveImage: Function,
  backLinkUrl?: string,
};

export const AddProductForm: React.FC<FormProps> = (props:FormProps) => {
  const { product, onChange } = props;
  const [images, setImages] = React.useState<ImageListType>([]);

  const onImageChange = (
    imageList: ImageListType,
  ) => {
    setImages(imageList);
  };

  const onSubmit = (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    props.onSubmit((images[0] && images[0].file) || null);
  }

  const imageUrl = images[0] && images[0].data_url ? images[0].data_url : product.imageUrl;

  return (
    <div className={"w-full bg-white py-8 px-12"}>
      {/* FORM */}
      <h2>Model</h2>
      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <label>
            <Trans i18nKey="name" /> *
          </label>
          <input
            name="name"
            type="text"
            className="o-input__text"
            onChange={onChange}
            required={true}
            value={product.name}
          />
        </div>
        <div className="mb-3">
          <label>
            <Trans i18nKey="category" />
          </label>
          <input
            name="category"
            type="text"
            className="o-input__text"
            onChange={onChange}
            value={product.category}
          />
        </div>
        <div className="mb-3">
          <ImageUploading
            value={images}
            onChange={onImageChange}
            dataURLKey="data_url"
          >
            {({
              onImageUpload,
              onImageRemoveAll,
              isDragging,
              dragProps,
            }) => (
              // Make sure buttons have type="buttons" (the default type is "submit")
              <div className="upload__image-wrapper">
                <button
                  style={isDragging ? { color: 'red' } : undefined}
                  onClick={onImageUpload}
                  className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800"
                  type="button"
                  {...dragProps}
                >
                  <Trans i18nKey="select photo" />
                </button>
                &nbsp;
                <button
                  onClick={() => { onImageRemoveAll(); props.onRemoveImage() }}
                  type="button"
                  className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300">
                  <Trans i18nKey="remove photo" />
                </button>
                <img
                  onClick={onImageUpload}
                  src={imageUrl || config.placeholderImage} alt={product.name} width="512"/>
              </div>
            )}
          </ImageUploading>
        </div>
        <Link to={props.backLinkUrl || '/products'} className="bg-gray-200 text-gray px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-300">
          <Trans i18nKey="back" />
        </Link>
        <Button className="bg-gray-500 text-white px-6 py-3 rounded-full mr-1 mb-1 hover:bg-gray-800" type="submit">
            <Trans i18nKey="submit" />
        </Button>
      </form>
    </div>
  )
};

export default AddProductForm;
