import { createSlice } from '@reduxjs/toolkit';
import {
  getSwatches,
  getSwatch,
  updateSwatch,
  createSwatch,
  removeSwatch,
  searchSwatch,
} from './actions';
import { RootState } from '../../app/store';
import { getResponseError } from '../../api';

interface swatchState {
  error: boolean | null,
  isWaiting: boolean,
  items: Swatch[] | null,
  current: Swatch | null,
  message: string;
  paging: PageMeta | null,
  isSearchResult: boolean, // 'items' is result of search ?
}

const initialState: swatchState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  message: '',
  paging: null,
  isSearchResult: false,
}

export const swatchSlice = createSlice({
  name: 'swatches',
  initialState,
  reducers: {},
  extraReducers: (builder) => builder
    // GET ALL swatches
    .addCase(getSwatches.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = false;
    })
    .addCase(getSwatches.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.paging = action.payload.meta;
      state.message = '';
    })
    .addCase(getSwatches.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = '';
      state.message = getResponseError(action);
    })
    // GET SINGLE swatch
    .addCase(getSwatch.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(getSwatch.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = '';
    })
    .addCase(getSwatch.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // UPDATE swatch
    .addCase(updateSwatch.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De aanpassingen worden opgeslagen...';
    })
    .addCase(updateSwatch.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De aanpassingen zijn opgeslagen';
    })
    .addCase(updateSwatch.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // CREATE swatch
    .addCase(createSwatch.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden aangemaakt...';
    })
    .addCase(createSwatch.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn aangemaakt...';
    })
    .addCase(createSwatch.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // REMOVE swatch
    .addCase(removeSwatch.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden verwijderd';
    })
    .addCase(removeSwatch.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn verwijderd';
    })
    .addCase(removeSwatch.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // SEARCH swatch
    .addCase(searchSwatch.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchSwatch.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchSwatch.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action);
      state.isSearchResult = true;
    })
    .addDefaultCase(() => {}),
});

// SELECTORS
export const selectSwatches = (state: RootState) => state.swatches.items;
export const selectSwatch = (state: RootState) => state.swatches.current;
export const selectPaging = (state: RootState) => {
  if (state.swatches.isSearchResult) { return null; }
  return state.swatches.paging;
}
export const selectFeedback = (state: RootState):Feedback => ({
  isWaiting: state.swatches.isWaiting,
  message: state.swatches.message,
  error: state.swatches.error,
});
export const selectSearchFeedback = (state: RootState):SearchFeedback => ({
  isSearchResult: state.swatches.isSearchResult,
  isWaiting: state.swatches.isWaiting,
  count: state.swatches.isSearchResult && Array.isArray(state.swatches.items) ? state.swatches.items?.length : null,
});

// UTIL
export const displayName = (swatch: Swatch|null) => {
  return swatch ? swatch.name : '';
}

export default swatchSlice.reducer;
