import * as React from 'react';
import {
  Route,
  Routes,
} from "react-router-dom";
import { Projects } from './Projects';
import { EditProject } from './Edit';
import { RemoveProject  } from './Remove';
import { AddProject } from './Add';
import { SelectProduct } from './SelectProduct';
import { SelectSwatch } from './SelectSwatch';
import { ProductDetails } from './components/ProductDetails';
import { ProductAnnotation } from './components/ProductAnnotation';
import { ProductUpload } from './components/ProductUpload';
import { Send as SendProjects} from './Send';
import { Navbar } from '../../components/NavBar';
import { ProjectCtx, initialProjectContext } from './ProjectContext';

export const ProjectRoutes: React.FC = () =>  {
  return (
    <ProjectCtx.Provider value={initialProjectContext}>
      <Routes>
        {/* PROJECT ADD */}
        <Route
          path="/add/select-product"
          element={<SelectProduct view="add"/>}
        />
        <Route
          path="/add/select-swatch/:category"
          element={ <SelectSwatch view="add"/> }
        />
        <Route
          path="/add/select-swatch"
          element={ <SelectSwatch view="add"/> }
        />
        <Route
          path="/add/product-upload"
          element={ <ProductUpload view="add"/> }
        />
        <Route
          path="/add/project-details"
          element={ <ProductDetails view="add"/> }
        />
        <Route
          path="/add/project-annotation"
          element={ <ProductAnnotation view="add" /> }
        />
        <Route
          path="/add"
          element={ <AddProject view="add" /> }
        />

        {/* PROJECT EDIT */}
        <Route
          path="/edit/:id/select-product"
          element={ <SelectProduct view="edit"/> }
        />
        <Route
          path="/edit/:id/select-swatch/:category"
          element={ <SelectSwatch view="edit"/> }
        />
        <Route
          path="/edit/:id/select-swatch"
          element={ <SelectSwatch view="edit"/> }
        />
        <Route
          path="/edit/:id/product-upload"
          element={ <ProductUpload view="edit" /> }
        />
        <Route
          path="/edit/:id/project-details"
          element={ <ProductDetails view="edit" /> }
        />
        <Route
          path="/edit/:id/project-annotation"
          element={ <ProductAnnotation view="edit" /> }
        />

        <Route
          path="/edit/:id"
          element={ <EditProject view="edit" /> }
        />
        {/* PROJECT EDIT */}
        <Route
          path="/remove/:id"
          element={ <RemoveProject /> }
        />
        <Route
          path="/send"
          element={ <SendProjects /> }
        />
        <Route
          path="/"
          element={
            <>
            <Navbar />
            <Projects />
            </>
          }
        />
      </Routes>
    </ProjectCtx.Provider>
  );
}


export default ProjectRoutes;
