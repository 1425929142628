import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import {
  getProjects,
  getProject,
  updateProject,
  createProject,
  removeProject,
  searchProject,
  duplicateProject,
  sendToSupplier,
} from './actions';
import { RootState } from '../../app/store';
import { getResponseError } from '../../api';

export interface projectsState {
  error: boolean | null;
  isWaiting: boolean;
  items: Project[] | null;
  paging: PageMeta | null;
  current: Project | null; // details of a selected project (should not change)
  pending: PendingProject | Project | null, // details of new or edited project (could changed based on user input)
  message: string;
  isSearchResult: boolean, // 'items' is result of search ?
}

const initialState: projectsState = {
  error: false,
  isWaiting: false,
  items: null,
  current: null,
  pending: null,
  message: '',
  paging: null,
  isSearchResult: false,
}

function getBirthDate(payload:any):string {
  return payload.birthdate ? DateTime.fromISO(payload.birthdate, { setZone: false }).toISODate() : '';
}

function getProjectFromPayload(payload:any) {
    const result = {
      ...payload,
    };
    if ('birthdate' in payload) {
      // API returns full datetime for birthdate but input type date needs yyyy-mm-dd without time part
        result.birthdate = getBirthDate(payload);
    }
    return result;
}

export function createPendingProject(product?:Product|null):PendingProject {
  const properties = {
    ...createProductProperties(),
    // New product default 'properties' values
    'contrefort-links': 'Dik med lat',
    'contrefort-links-cm': '3',
    'contrefort-rechts': 'Dik med',
    'contrefort-rechts-cm': '4',
    'enkel-polster-links': 'Extra polster',
    'enkel-polster-links-mm': '',
    'enkel-polster-rechts': 'Totale polstering',
    'hak-afronding': 'Afronden',
    'hak': 'Als foto',
    'hak-imageUrl': product ? product.imageUrl : '',
  };
  return {
    firstName: '',
    lastName: '',
    leestnr: '',
    birthdate: '',
    gender: 'UNSPECIFIED',
    comment: '',
    properties,
    product: null,
    user: null,
    imageUrl: '',
    supplier: null,
  };
}

export function createProductProperties():ProjectProperties {
  return {
    'schachthoogte-links': '',
    'schachthoogte-rechts': '',
    'schachthoogte-links-en-rechts': '',
    'sluiting': '',
    'sluiting-imageUrl':'',
    'sluiting-velcro-imageUrl':'',
    'sluiting-other': '',
    'voering-voor': '',
    'voering-achter': '',
    'voering-other': '',
    'contrefort-links':'',
    'contrefort-rechts':'',
    'contrefort-links-cm':'',
    'contrefort-rechts-cm':'',
    'enkel-polster-links':'',
    'enkel-polster-rechts':'',
    'enkel-polster-links-mm': '',
    'enkel-polster-rechts-mm':'',
    'tongbevestiging':'met velcro',
    'tongbevestiging-other':'',
    'tongpolster':'',
    'boordpolster': '',
    'boordpolster-mm': '',
    'boordpolster-imageUrl': '',
    'hakovertrek': '',
    'onderwerk-maakwijze': '',
    'onderwerk-maakwijze-imageUrl': '',
    'onderwerk-maakwijze-other': '',
    'randtype': '',
    'randtype-imageUrl': '',
    'randtype-other': '',
    'randtype-number': '',
    'hak': '',
    'hak-imageUrl': '',
    'hak-hoogte-links': '',
    'hak-hoogte-rechts': '',
    'hak-afwijkende-kanten': 'false',
    'hak-buffer': '',
    'hak-ondertrekken': '',
    'hak-afronding': '',
    'hak-papier-in-hak-links': 'false',
    'hak-papier-in-hak-rechts': 'false',
    'kleur-onderwerk': '',
    'kleur-onderwerk-imageUrl': '',
    'kleur-onderwerk-other': '',
    'neus': '',
    'neus-extra-groot-links': '',
    'neus-extra-groot-rechts': '',
    'neus-vorm': '',
    'neus-comment': '',
    'schoring-med-links': '',
    'schoring-med-links-mm': '',
    'schoring-lat-links': '',
    'schoring-lat-links-mm': '',
    'schoring-med-rechts': '',
    'schoring-med-rechts-mm': '',
    'schoring-lat-rechts': '',
    'schoring-lat-rechts-mm': '',
    'zoolmateriaal': '',
    'zoolmateriaal-imageUrl': '',
    'zoolmateriaal-other': '',
    'zoolmateriaal-dikte': '',
    'zoolmateriaal-dikte-other': '',
    'zoolmateriaal-tussenzool-kleur': '',
    'leverancier-deadline': '',
    'leverancier-comment': '',
  };
}

export const defaultSearchOptions:ProjectSearchOptions = {
  text: '',
  fromDate: DateTime.now().startOf('month').toSQLDate(),
  // fromDate: '',
  toDate: '',
  validOnly: true,
  unsendOnly: true,
  supplier: null,
}

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clearPending: (state) => {
      state.pending = null;
    },
    // Set product on pending project
    setPendingProduct: (state, action: PayloadAction<Product>) => {
      if (state.pending === null) {
        state.pending = createPendingProject(action.payload);
      }
      const resetProjectImage = state.pending && state.pending.product &&
        action.payload && state.pending.product.id !== action.payload.id;

      state.pending.product = action.payload;
      if (resetProjectImage && state.pending.imageUrl) {
        state.pending.imageUrl = '';
      }
      if (resetProjectImage && state.pending.imageBase64) {
        state.pending.imageBase64 = null;
      }
      // Default value of pending project 'hak' property is the product image url
      if (
        state.pending.properties &&
        state.pending.properties.hak === 'Als foto' &&
        state.pending.properties['hak-imageUrl'] ===  ''
      ) {
        state.pending.properties['hak-imageUrl'] = action.payload.imageUrl;
      }
    },
    createPending: (state) => {
      state.pending = createPendingProject(null);
    },
    setPending: (state, action: PayloadAction<PendingProject|Project>) => {
      const resetProjectImage = state.pending && state.pending.product &&
        action.payload && action.payload.product &&
        state.pending.product.id !== action.payload.product.id;

      state.pending = action.payload;
      if (resetProjectImage && state.pending.imageUrl) {
        state.pending.imageUrl = '';
      }
      if (resetProjectImage && state.pending.imageBase64) {
        state.pending.imageBase64 = null;
      }
    },
    setProject: (state, action: PayloadAction<Project>) => {
      state.pending = action.payload;
    },
    // Copy property value from 'item' into the pending project
    setPendingProjectProperty: (
      state,
      action: PayloadAction<{
        item: NamedItem,
        itemKey?: keyof NamedItem,
        property: keyof ProjectProperties
      }>
    ) => {
      const { property, item, itemKey } = action.payload;
      if (state.pending && property && item) {
        if (!state.pending.properties) {
          state.pending.properties = createProductProperties();
        }
        state.pending.properties[property] = item[itemKey || 'imageUrl'] || '';
      }
    },
  },
  extraReducers: (builder) => builder
    // GET ALL projectS
    .addCase(getProjects.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = false;
    })
    .addCase(getProjects.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.paging = action.payload.meta;
      state.message = '';
    })
    .addCase(getProjects.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = '';
      state.message = getResponseError(action);
    })
    // GET SINGLE PROJECT
    .addCase(getProject.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(getProject.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      // Older projects could return project validation as empty {} instead of []
      if (!Array.isArray(action.payload.validation)) {
        action.payload.validation = [];
      }
      state.current = getProjectFromPayload(action.payload);
      state.message = '';
      if (state.pending === null || state.pending.id !== action.payload.id) {
        state.pending = action.payload;
      }
    })
    .addCase(getProject.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // UPDATE PROJECT
    .addCase(updateProject.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De aanpassingen worden opgeslagen...';
    })
    .addCase(updateProject.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = getProjectFromPayload(action.payload);
      state.message = 'De aanpassingen zijn opgeslagen';
    })
    .addCase(updateProject.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // CREATE PROJECT
    .addCase(createProject.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden aangemaakt...';
    })
    .addCase(createProject.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = getProjectFromPayload(action.payload);
      if (state.pending) {
        state.pending.id = action.payload.id;
      }
      state.message = 'De gegevens zijn aangemaakt...';
    })
    .addCase(createProject.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // DUPLICATE PROJECT
    .addCase(duplicateProject.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = '';
    })
    .addCase(duplicateProject.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      const { payload } = action;

      const pendingProject = createPendingProject(null);
      // API returns full datetime for birthdate but input type date needs yyyy-mm-dd without time part
      pendingProject.birthdate = getBirthDate(action.payload) || pendingProject.birthdate
      pendingProject.comment = payload.comment || pendingProject.comment;
      pendingProject.firstName = payload.firstName || pendingProject.firstName;
      pendingProject.lastName = payload.lastName || pendingProject.lastName;
      pendingProject.gender = payload.gender || pendingProject.gender;
      pendingProject.leestnr = payload.leestnr || pendingProject.leestnr;
      pendingProject.product = payload.product || pendingProject.product;
      pendingProject.properties = payload.properties || pendingProject.properties;
      pendingProject.imageUrl = payload.imageUrl || pendingProject.imageUrl;
      state.pending = pendingProject;
      state.message = '';
    })
    .addCase(duplicateProject.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // REMOVE PROJECT
    .addCase(removeProject.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.current = null;
      state.message = 'De gegevens worden verwijderd';
    })
    .addCase(removeProject.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.current = action.payload;
      state.message = 'De gegevens zijn verwijderd';
    })
    .addCase(removeProject.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.current = null;
      state.message = getResponseError(action);
    })
    // SEARCH PRODUCT
    .addCase(searchProject.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.items = null;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchProject.fulfilled, (state, action) => {
      state.error = false;
      state.isWaiting = false;
      state.items = action.payload.items;
      state.paging = action.payload.meta;
      state.message = '';
      state.isSearchResult = true;
    })
    .addCase(searchProject.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.items = null;
      state.message = getResponseError(action);
      state.isSearchResult = true;
    })
    // SEND PRODUCTS TO SUPPLIER
    .addCase(sendToSupplier.pending, (state) => {
      state.error = null;
      state.isWaiting = true;
      state.message = '';
    })
    .addCase(sendToSupplier.fulfilled, (state) => {
      state.error = false;
      state.isWaiting = false;
      state.message = 'Send to supplier Complete';
    })
    .addCase(sendToSupplier.rejected, (state, action) => {
      state.error = true;
      state.isWaiting = false;
      state.message = getResponseError(action);
    })
    .addDefaultCase(() => {}),
});

// ACTIONS
export const {
  clearPending,
  setPending,
  setPendingProduct,
  setPendingProjectProperty,
  createPending,
  setProject,
} = projectsSlice.actions;

// SELECTORS
export const selectProjects = (state: RootState) => state.projects.items;
export const selectProject = (state: RootState) => state.projects.current;
export const selectPaging = (state: RootState) => {
  // if (state.projects.isSearchResult) { return null; }
  return state.projects.paging;
}
export const selectPending = (state: RootState) => {
  return state.projects.pending;
}
export const selectFeedback = (state: RootState):Feedback => ({
  isWaiting: state.projects.isWaiting,
  message: state.projects.message,
  error: state.projects.error,
});
export const selectSearchFeedback = (state: RootState):SearchFeedback => ({
  isSearchResult: state.projects.isSearchResult,
  isWaiting: state.projects.isWaiting,
  count: state.projects.isSearchResult && state.projects.paging ? state.projects.paging.totalItems : null,
});


// UTIL
export const displayName = (project: Project|null) => {
  if (!project) { return ''; }
  let result = '';
  result = project.leestnr;
  if (project.firstName || project.lastName) {
    result = `${result} voor ${project.firstName} ${project.lastName}`;
  }
  return result;
}

export default projectsSlice.reducer;
