import * as React from 'react';
import {
  Route,
  Routes,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import './i18n';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { ToastContainer } from './features/toast/ToastContainer';
import { me, logout } from './features/authentication/actions';
import Login from './features/authentication/Login';
import { RequireAuth } from './features/authentication/RequireAuth';
import { ProjectRoutes } from './features/projects/ProjectRoutes';
import { ProductRoutes } from './features/products/ProductRoutes';
import { SwatchesRoutes } from './features/swatch/SwatchesRoutes';
import { LeatherRoutes } from './features/leather/LeatherRoutes';
import { SupplierRoutes } from './features/suppliers/SupplierRoutes';
import { UserRoutes, ProfileRoutes } from './features/user/UserRoutes';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          {/* PROJECTS */}
          <Route
            path="/projects/*"
            element={<RequireAuth><ProjectRoutes /></RequireAuth>}
          />
          {/* PRODUCTS */}
          <Route
            path="/products/*"
            element={<RequireAuth><ProductRoutes /></RequireAuth>}
          />
          {/* SWATCHES (product stalen)*/}
          <Route
            path="/swatches/*"
            element={<RequireAuth><SwatchesRoutes /></RequireAuth>}
          />
          {/* LEATHER */}
          <Route
            path="/leathers/*"
            element={<RequireAuth><LeatherRoutes /></RequireAuth>}
          />
          {/* SUPPLIERS */}
          <Route
            path="/suppliers/*"
            element={<RequireAuth><SupplierRoutes /></RequireAuth>}
          />
          {/* USERS */}
          <Route
            path="/users/*"
            element={<RequireAuth><UserRoutes /></RequireAuth>}
          />
          {/* PROFILE */}
          <Route
            path="/profile/*"
            element={<RequireAuth><ProfileRoutes /></RequireAuth>}
          />
          {/* LOGIN */}
          <Route
            path="/login"
            element={<Login />}
          />
          <Route
            path="/"
            element={<Login />}
          />
          {/* 404 */}
          <Route
            path="*"
            element={<NoMatch />}
          />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </Provider>
  );
}

function NoMatch() {
  let location = useLocation();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
}

(window as any).__STORE__ = store;
// used for logout in E2E unit tests
(window as any).__LOGOUT__ = () => { store.dispatch(logout()); }

// Trigger authentication check
store.dispatch(me());

export default App;
