import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ProductSelector } from './ProductSelector';
import { SwatchSelector } from './SwatchSelector';

export interface ValueItem {
  value: string;
  label?: string;
  img?: string;
  defaultImageUrl?: string;
  selectProductImage?: keyof ProjectProperties; // shows product selector
  selectSwatchImage?: keyof ProjectProperties; // shows swatch/stalen selector
  selectSwatchName?: keyof ProjectProperties; // shows swatch/stalen selector
  textEntry?: keyof ProjectProperties | boolean; // shows text input
  numberEntry?: keyof ProjectProperties | boolean; // shows number input
  optionEntry?: keyof ProjectProperties;
}

interface InputProps extends ValueItem {
  name: string;
  checked: boolean;
  id: string;
  onChange: React.FormEventHandler;
  properties: ProjectProperties;
  suffix?: string;
  className?:string;
  resetFn?: Function;
  disabled?: boolean;
  children?: React.ReactNode;
}

interface SimpleRadioButtonProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  checked: boolean;
  onChange: React.FormEventHandler;
  value: string;
}

interface RadioButtonInputProps extends SimpleRadioButtonProps {
  properties: ProjectProperties;
  textEntry?: keyof ProjectProperties | boolean;
  numberEntry?: keyof ProjectProperties | boolean;
  suffix?: string;
}

interface BasicCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
  checked: boolean;
  onChange: React.FormEventHandler;
}

interface BasicTextProps extends React.HTMLProps<HTMLInputElement> {
  id: string;
  name: string;
  value: string;
  onChange: React.FormEventHandler;
  label?: string;
}

interface CheckboxProps extends InputProps {}

function isProductImage(productImage?:string, defaultImage?:string) {
  return typeof productImage === 'undefined' || productImage === '' || productImage === defaultImage;
}

export function getLabel(label:string|undefined, checked:boolean, value:string) {
  if (label && checked) { return '';}
  return label || value;
}

export const TextInput: React.FC<InputProps> = React.memo((props:InputProps) => {
  if (typeof props.textEntry === 'string' && props.textEntry && props.checked) {
    return (
      <>
      <input
        type="text"
        value={props.properties[props.textEntry]}
        name={props.textEntry}
        id={props.id}
        onChange={props.onChange}
        className={`o-input__text ${props.className ? props.className : ''}`}
        disabled={props.disabled}
      />
        {props.suffix && (
          <span> {props.suffix} </span>
        )}
      </>
    );
  }
  return null;
});

export const NumberInput: React.FC<InputProps> = (props:InputProps) => {
  if (typeof props.numberEntry === 'string' && props.numberEntry && props.checked) {
    return (
      <>
      <input
        type="number"
        value={props.properties[props.numberEntry]}
        name={props.numberEntry}
        id={props.id}
        onChange={props.onChange}
        className={`o-input__text ${props.className ? props.className : ''}`}
        disabled={props.disabled}
        />
        {props.suffix && (
          <span> {props.suffix} </span>
        )}
      </>
    );
  }
  return null;
};

export const ImageSelector: React.FC<InputProps> = (props:InputProps) => {
  if (typeof props.selectProductImage === 'string' && props.selectProductImage && props.checked) {
    return (
      <ProductSelector
        imageUrl={props.properties[props.selectProductImage] || props.defaultImageUrl}
        value={props.selectProductImage}
        resetFn={props.properties[props.selectProductImage] !== '' ? props.resetFn : null}
        className={isProductImage(props.properties[props.selectProductImage], props.defaultImageUrl) ? "print:hidden" : ""}
      />
    );
  }

  if (typeof props.selectSwatchImage === 'string' && props.selectSwatchImage && props.checked) {
    return (
      <SwatchSelector
        imageUrl={props.properties[props.selectSwatchImage]}
        value={props.selectSwatchImage}
        item="swatch/zool"
        />
    );
  }

  if (typeof props.selectSwatchName === 'string' && props.selectSwatchName && props.checked) {
    return (
      <SwatchSelector
        name={props.properties[props.selectSwatchName]}
        value={props.selectSwatchName}
        item="swatch/rand"
        itemKey="name"
      />
    );
  }
  return null;
};

export const RadioButton: React.FC<InputProps> = (props:InputProps) => {
  const { t } = useTranslation();
  const { label, checked, value, resetFn } = props;
  return (
    <div className={`o-input__radiobutton ${props.checked ? 'o-input__radiobutton--checked' : 'print:hidden'}`}>
        <SimpleRadioButton
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          value={props.value}
          className="mr-4"
          id={props.id}
        />
        <label
          htmlFor={props.id}
        >{getLabel(label, checked, t(value))}</label>
      { /** product/swatch image selector if input is checked */ }
      <ImageSelector {...props} id={`${props.id}-image`} resetFn={resetFn}/>
      { /** text input if input is checked */ }
      <TextInput {...props}  id={`${props.id}-text`} />
      { /** number input if input is checked */ }
      <NumberInput {...props} id={`${props.id}-number`} />
      {props.children}
    </div>
  );
};

export const SimpleRadioButton: React.FC<SimpleRadioButtonProps> = (props:SimpleRadioButtonProps) => {
  return (
    <input
      type="radio"
      {...props}
    />
  );
};

export const BasicCheckbox: React.FC<BasicCheckboxProps> = (props:BasicCheckboxProps) => {
  return (
    <input
      type="checkbox"
      {...props}
    />
  );
};

export const RadioButtonInput: React.FC<RadioButtonInputProps> = (props:RadioButtonInputProps) => {
  return (
    <div>
      <SimpleRadioButton
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        value={props.value}
        id={props.id}
        className="mr-4"
        disabled={props.disabled}
      />
      <label
        htmlFor={props.id}
      ></label>
      { /** text input if input is checked */ }
      <TextInput {...props} id={`${props.id}-text`} />
      { /** number input if input is checked */ }
      <NumberInput {...props} id={`${props.id}-number`} />
    </div>
  );
};

export const Checkbox: React.FC<CheckboxProps> = (props:CheckboxProps) => {
  const { t } = useTranslation();
  return (
    <div className={`o-checkbox ${props.checked ? '' : 'print:hidden'}`}>
      <label htmlFor={props.id} className="mr-4">
        <BasicCheckbox
          name={props.name}
          checked={props.checked}
          onChange={props.onChange}
          className="mr-4"
          id={props.id}
        />{props.label && t(props.label)}
      </label>
      { /** product image selector if input is checked */ }
      <ImageSelector {...props}  id={`${props.id}-image`} />
      { /** text input if input is checked */ }
      <TextInput {...props}  id={`${props.id}-text`} />
      { /** number input if input is checked */ }
      <NumberInput {...props}  id={`${props.id}-number`} />
    </div>
  );
};

export const BasicTextInput: React.FC<BasicTextProps> = (props:BasicTextProps) => {
  const { onChange, value, label, ...rest } = props
  return (
    <>
      {label && (
        <label
          htmlFor={props.id}
        >
          <Trans i18nKey={label} />
        </label>
      )}
      <input
        type="text"
        value={value}
        onChange={onChange}
        {...rest}
      />
    </>
  );
};
